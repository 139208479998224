import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { getMonth } from 'date-fns';
import React from 'react';
import NumberFormat from 'react-number-format';
import { IPartnersStats } from '../../types/partner.interface';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  count: {
    fontSize: 36,
    fontFamily: 'Proxima Nova Rg',
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: 13,
    lineHeight: '16px',
    fontFamily: 'Proxima Nova Rg',
    color: theme.palette.primary.main,
  },
}));

export const PartnersStatsMobile: React.FC<{ stats: IPartnersStats }> = ({
  stats,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container item direction='row'>
      <Box mr={5} mb={3}>
        <Typography className={classes.count}>{stats.count}</Typography>
        <Typography className={classes.text}>
          {t('component.partnerStatsMobile.partners')} <br />{' '}
          {t('component.partnerStatsMobile.atAll')}
        </Typography>
      </Box>
      <Box mr={5} mb={3}>
        <Typography className={classes.count}>
          {stats.partners.find((ps) => ps.month === getMonth(new Date()) + 1)
            ?.number || 0}
        </Typography>
        <Typography className={classes.text}>
          {t('component.partnerStatsMobile.pluralPartner')} <br />{' '}
          {t('component.partnerStatsMobile.inMonth')}
        </Typography>
      </Box>
      <Box mr={5} mb={3}>
        <Typography className={classes.count}>
          {stats.projects.find((ps) => ps.month === getMonth(new Date()) + 1)
            ?.number || 0}
        </Typography>
        <Typography className={classes.text}>
          {t('component.partnerStatsMobile.pluralContract')} <br />{' '}
          {t('component.partnerStatsMobile.inWork')}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.count}>
          <NumberFormat
            displayType='text'
            value={stats.activeAmount}
            thousandSeparator=' '
          />{' '}
          {t('money.currencySign')}
        </Typography>
        <Typography className={classes.text}>
          {t('component.partnerStatsMobile.contractsTotal')} <br />{' '}
          {t('component.partnerStatsMobile.inWork')}
        </Typography>
      </Box>
    </Grid>
  );
};
