import { InputProps, Popover, styled } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { CalendarIcon } from '../core/icons/calendar';
import AppInput from '../core/outlinedInput';
import { format } from 'date-fns';
import DayPicker, { Modifier } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';

const StyledAppInput = styled(AppInput)({
  width: 182,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
  },
  '& input::-webkit-calendar-picker-indicator': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& svg': {
    cursor: 'pointer',
  },
});

interface DateInputProps {
  onDatePick: (e: any) => void;
  defaultValue: string | number;

  disabledDays?: Modifier[];
}

const DateInput: React.FC<InputProps & DateInputProps> = ({
  onDatePick,
  defaultValue,
  disabledDays,
  ...other
}) => {
  const [showCalendar, setShowCalendar] = useState<
    HTMLElement | SVGElement | null
  >(null);
  const inputRef = useRef<HTMLInputElement>();

  const [inputIter, setInputIter] = useState(0);

  return (
    <>
      <StyledAppInput
        {...other}
        key={inputIter}
        type='date'
        defaultValue={format(new Date(defaultValue), 'yyyy-MM-dd')}
        startAdornment={
          <CalendarIcon
            onClick={(e) => !other.disabled && setShowCalendar(e.currentTarget)}
            style={{
              cursor: other.disabled ? 'default' : 'pointer',
            }}
          />
        }
        inputRef={inputRef}
        endAdornment={null}
        onChange={(e) => {
          const newDate = new Date(e.target.value).getTime();

          const [dateYear] = e.target.value.split('-');
          if (dateYear.length > 4) {
            if (inputRef.current) {
              inputRef.current.value = e.target.value.replace(dateYear, '2023');
            }
            return;
          }

          if (
            !newDate ||
            dateYear.length < 4 ||
            dateYear.substring(0, 1) === '0'
          ) {
            return;
          }

          onDatePick(new Date(e.target.value));
        }}
        onClick={(e) => e.preventDefault()}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setShowCalendar(null)}
        open={Boolean(showCalendar)}
        anchorEl={showCalendar}
      >
        {/* <Calendar onChange={onDatePick} /> */}
        <DayPicker
          locale='en'
          onDayClick={(e) => {
            onDatePick(e);
            setShowCalendar(null);
            setInputIter(inputIter + 1);
          }}
          selectedDays={[new Date(defaultValue || '')]}
          month={defaultValue ? new Date(defaultValue) : undefined}
          localeUtils={MomentLocaleUtils}
          disabledDays={disabledDays ?? []}
        />
      </Popover>
    </>
  );
};

export default DateInput;
