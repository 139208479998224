import React, { useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import FormItemRow from '../../components/forms/formRow';
import AppInputLabel from '../../components/core/label';
import AppInput from '../../components/core/outlinedInput';
import { useFormik, FormikProvider } from 'formik';
import AppButton from '../../components/core/buttons';
import MaskedPhone from '../../components/inputs/maskedPhone';
import * as yup from 'yup';
import ShortInput from '../../components/inputs/short';
import partner from '../../api/partner';
import IPartner from '../../types/partner.interface';
import { phoneToValid } from '../../utils/etc';
import { useHistory } from 'react-router';
import { ManagerSelect } from '../../components/inputs/managerSelect';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';
import { useSnackbar } from 'notistack';
import CloseIcon from '../../components/core/icons/close';
import Reaptcha from 'reaptcha';
import { getCaptchaPublicToken } from '../../api/auth';
import { useTranslation } from 'react-i18next';
import FieldArrowDown from '../../components/core/icons/fieldArrowDown';
import { useQuery } from 'react-query';
import { default as cAPI } from './../../api/city';

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 32,
  lineHeight: 1.1,
  color: theme.palette.common.white,
  marginBottom: 16,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 16,
    // margin: 8,
    marginLeft: theme.spacing(2),
  },
}));

const RegWrap = styled(Paper)(({ theme }) => ({
  display: 'flex',
  padding: '32px 40px',
  width: '100%',
  flexDirection: 'column',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '16px 16px',
    width: 'auto',
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  borderRadius: 3,
  border: '1px solid #e8e8e8',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 28,
  cursor: 'pointer',
});

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    background: '#fff',
  },
});

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    marginRight: 18,
    stroke: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
    },
  },
  policyHelper: {
    position: 'absolute',
    left: 0,
    bottom: '-1.65em',
    transition: 'all 200ms',
    maxHeight: '1em',
  },
}));

const RegPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const user = useAppSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const regFieldsDict = {
    name: t('page.registration.fieldName'),
    city: t('page.registration.fieldCity'),
    full_name: t('page.registration.fieldFullName'),
    position: t('page.registration.fieldPosition'),
    email: t('page.registration.fieldEmail'),
    personal_phone: t('page.registration.fieldPersonalPhone'),
    work_phone: t('page.registration.fieldWorkPhone'),
    policy: t('page.registration.fieldPolicy'),
    captcha: t('page.registration.fieldCaptcha'),
    countries: t('page.registration.fieldCountries'),
  };

  const [captcha, setCaptcha] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  useMemo(() => {
    getCaptchaPublicToken().then((t) => {
      setCaptchaToken(t.token);
    });
  }, [captchaToken]);

  const handleCaptchaChange = (token: string) => {
    setCaptcha(token);
    formik.setFieldValue('captcha', token);
  };

  const formik = useFormik<
    Omit<IPartner, 'id' | 'createdAt' | 'isNew' | 'logoId'> & {
      policy: boolean;
      captcha: string;
    }
  >({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: '',
      city: '',
      fullName: '',
      position: '',
      email: '',
      personalPhone: '',
      workPhone: '',
      files: [],
      countries: [],
      policy: false,
      captcha: '',
    },
    validationSchema: yup.object({
      captcha:
        user.profile && user.profile.role === EUserRole.admin
          ? yup.string()
          : yup.string().required(t('validation.captchaRequired')),
      countries:
        user.profile && user.profile.role === EUserRole.admin
          ? yup
              .array()
              .required(t('validation.required'))
              .min(1, t('validation.required'))
          : yup.array().notRequired(),
      name: yup.string().required(t('validation.required')),
      email: yup
        .string()
        .email(t('validation.email'))
        .required(t('validation.required')),
      fullName: yup.string().required(t('validation.required')),
      city: yup.string().required(t('validation.required')),
      personalPhone: yup.string(),
      workPhone: yup.string(),
      policy: yup
        .bool()
        .oneOf(
          user.profile && user.profile.role === EUserRole.admin
            ? [true, false]
            : [true],
          t('validation.required')
        ),
    }),
    onSubmit: async (values, helpers) => {
      await helpers.validateForm(values);
      const work_phone = phoneToValid(values.workPhone);
      const personal_phone = phoneToValid(values.personalPhone);
      try {
        await partner.register({
          ...values,
          personalPhone: personal_phone,
          workPhone: work_phone,
          manager: values.manager?.id,
          countries: values.countries?.map((c) => c.id),
        });
        history.push('../../partners/');
        if (user.profile) {
          enqueueSnackbar(t('page.registration.partnerSuccessfullyCreated'), {
            variant: 'info',
          });
          return;
        }

        enqueueSnackbar(t('toastNotifications.register'), {
          variant: 'info',
        });
      } catch (err) {
        //@ts-ignore
        let a = (err as any).response.data.errors
          .map((er: any) => er.reason)
          .join(',');
        enqueueSnackbar(a, { variant: 'error' });
      }
    },
  });

  const citySearchQuery = useQuery(['cities'], () => cAPI.list(0), {
    useErrorBoundary: false,
    suspense: false,
  });

  return (
    <Grid container alignItems='center' justify='center'>
      <Grid item md={5} sm={11} xs={12}>
        <Box
          minHeight='100vh'
          justifyContent='center'
          width='100%'
          flexDirection='column'
          display='flex'
          position='realtive'
        >
          <Heading variant='h1'>{t('page.registration.headline')}</Heading>
          <FormikProvider value={formik}>
            <RegWrap elevation={0} square color='white'>
              {user.profile && user.profile.role === EUserRole.admin && (
                <StyledClose onClick={() => history.goBack()} />
              )}
              <Box mt={5.5} />
              <form onSubmit={formik.handleSubmit}>
                <FormItemRow>
                  <AppInputLabel>
                    {t('page.registration.partnerName')}
                  </AppInputLabel>
                  <AppInput
                    onChange={formik.handleChange}
                    name='name'
                    error={!!formik.errors.name}
                    helperText={formik.errors.name}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>
                    {t('page.registration.fieldCity')}
                  </AppInputLabel>
                  <AppInput
                    onChange={formik.handleChange}
                    name='city'
                    error={!!formik.errors.city}
                    helperText={formik.errors.city}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>{t('words.fullNameAbbr')}</AppInputLabel>
                  <AppInput
                    onChange={formik.handleChange}
                    name='fullName'
                    error={!!formik.errors.fullName}
                    helperText={formik.errors.fullName}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>{t('words.position')}</AppInputLabel>
                  <AppInput
                    onChange={formik.handleChange}
                    name='position'
                    error={!!formik.errors.position}
                    helperText={formik.errors.position}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>{t('words.email')}</AppInputLabel>
                  <AppInput
                    onChange={formik.handleChange}
                    name='email'
                    error={!!formik.errors.email}
                    helperText={formik.errors.email}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>
                    {t('page.registration.mobilePhone')}
                  </AppInputLabel>
                  <ShortInput
                    error={!!formik.errors.personalPhone}
                    helperText={formik.errors.personalPhone}
                    onChange={formik.handleChange}
                    name='personalPhone'
                    inputComponent={MaskedPhone}
                  />
                </FormItemRow>
                <FormItemRow>
                  <AppInputLabel>
                    {t('page.registration.workPhone')}
                  </AppInputLabel>
                  <ShortInput
                    error={!!formik.errors.workPhone}
                    helperText={formik.errors.workPhone}
                    onChange={formik.handleChange}
                    name='workPhone'
                    inputComponent={MaskedPhone}
                  />
                </FormItemRow>

                {user.profile && user.profile.role === EUserRole.admin ? (
                  <FormItemRow>
                    <AppInputLabel>{t('words.countries')}</AppInputLabel>
                    <StyledSelect
                      multiple
                      value={formik.values.countries}
                      disableUnderline={true}
                      style={{ width: '100%' }}
                      IconComponent={(props) => <FieldArrowDown {...props} />}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gridGap: 1,
                          }}
                        >
                          {selected.map((value: any) => (
                            <span
                              style={{
                                display: 'inline-block',
                                background: '#1C00A0',
                                color: '#fff',
                                borderRadius: '33px',
                                padding: '4px 15px',
                                fontSize: '14px',
                              }}
                            >
                              {value?.name}
                            </span>
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          square: true,
                          style: {
                            maxHeight: 300,
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {!citySearchQuery.isError &&
                        citySearchQuery.data?.items &&
                        citySearchQuery.data?.items.map((i: any) => (
                          <CustomMenuItem
                            value={i.id}
                            key={i.id}
                            onClick={() => {
                              if (
                                formik.values.countries.some(
                                  (v) => v.id === i.id
                                )
                              ) {
                                formik.setFieldValue(
                                  'countries',
                                  formik.values.countries.filter(
                                    (c) => c.id !== i.id
                                  )
                                );
                                return;
                              }

                              formik.setFieldValue('countries', [
                                ...formik.values.countries,
                                i,
                              ]);
                            }}
                          >
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr min-content',
                                width: '100%',
                              }}
                            >
                              <span>{i.name}</span>
                              <Checkbox
                                className={classes.checkbox}
                                color='primary'
                                checked={formik.values.countries.some(
                                  (v) => v.id === i.id
                                )}
                              />
                            </div>
                          </CustomMenuItem>
                        ))}
                    </StyledSelect>
                  </FormItemRow>
                ) : null}
                {user.profile && user.profile.role === EUserRole.admin && (
                  <FormItemRow>
                    <AppInputLabel>
                      {t('page.registration.manager')}
                    </AppInputLabel>
                    <ManagerSelect
                      defaultValue={formik.values.manager}
                      onChange={(value) =>
                        formik.setFieldValue('manager', value)
                      }
                    />
                  </FormItemRow>
                )}
                {user.profile &&
                user.profile.role === EUserRole.admin ? null : (
                  <FormItemRow>
                    <AppInputLabel />
                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                      position='relative'
                      mt={2}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        color='primary'
                        name='policy'
                        onChange={formik.handleChange}
                        disableRipple
                        defaultChecked={formik.initialValues.policy}
                      />
                      <Box position='relative'>
                        <a
                          className={classes.link}
                          target='_blank'
                          href={t('agreementLink')}
                          rel='noreferrer'
                        >
                          {t('page.registration.acceptAgreement')}
                        </a>
                        {formik.errors.policy && (
                          <FormHelperText
                            className={classes.policyHelper}
                            error={true}
                          >
                            {formik.errors.policy}
                          </FormHelperText>
                        )}
                      </Box>
                    </Box>
                  </FormItemRow>
                )}
                <Box mt={6}>
                  {captchaToken === '' ||
                    (user.profile && user.profile.role === EUserRole.admin) || (
                      <Reaptcha
                        sitekey={captchaToken}
                        onError={() => handleCaptchaChange('')}
                        onRender={() => handleCaptchaChange('')}
                        onExpire={() => handleCaptchaChange('')}
                        onLoad={() => handleCaptchaChange('')}
                        onVerify={(token) => handleCaptchaChange(token)}
                      />
                    )}
                  <AppButton
                    disabled={!formik.isValid}
                    style={{ height: 56, marginTop: 10 }}
                    type='submit'
                    size='large'
                    color='primary'
                    variant='contained'
                    fullWidth
                  >
                    {t('button.register')}
                  </AppButton>
                </Box>
                <Box mt={1} display='flex' flexDirection='row' flexWrap='wrap'>
                  {!formik.isValid && (
                    <Typography variant='caption'>
                      {t('page.registration.notFilled')}{' '}
                    </Typography>
                  )}
                  {Object.keys(regFieldsDict).map((fieldKey, idx) => {
                    //@ts-ignore
                    if (formik.errors[fieldKey])
                      return (
                        <Typography key={`${idx}-regError`} variant='caption'>
                          {
                            //@ts-ignore
                            regFieldsDict[fieldKey]
                          }
                          ,{' '}
                        </Typography>
                      );
                    else return null;
                  })}
                </Box>
              </form>
            </RegWrap>
          </FormikProvider>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegPage;
