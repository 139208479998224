import IProject, {
  EProjectStatus,
  IProjectsSearchParams,
} from '../types/project.interface';
import http from './client';
import IUser from '../types/user.interface';

async function create(
  project: Omit<
    IProject,
    'id' | 'created_at' | 'files' | 'partner' | 'manager' | 'city'
  > & {
    files?: Array<string>;
    partner?: string;
    manager?: string;
    city?: string;
  }
): Promise<IProject> {
  const response = await http.post('/project/create', project);
  return response.data.data;
}

async function get(id: string): Promise<IProject> {
  const response = await http.post('/project/get', { id });
  return response.data.data;
}

async function update(
  project: Omit<
    IProject,
    'created_at' | 'files' | 'partner' | 'manager' | 'city'
  > & {
    files?: Array<string>;
    partner?: string;
    manager?: string;
    city?: string;
  }
) {
  const response = await http.post('/project/edit', project);
  return response.data.data;
}

async function list(
  page: number = 1,
  searchParams?: IProjectsSearchParams
): Promise<{ hasNextPage: boolean; items: Array<IProject> }> {
  const response = await http.post('/project/list', {
    page: page,
    ...searchParams,
  });
  return response.data.data;
}

async function history(project: IProject): Promise<
  Array<{
    id: string;
    author?: IUser;
    reason: string;
    status: {
      from: EProjectStatus;
      to: EProjectStatus;
    };
    createdAt: string;
  }>
> {
  const response = await http.post('/project/history', {
    project: project.id,
  });
  return response.data.data;
}

const project = {
  create,
  get,
  update,
  list,
  history,
};

export default project;
