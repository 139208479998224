import config from '../app.config';

function setToken(token: string) {
  return localStorage.setItem(config.tokenAlias!, token);
}
function deleteToken() {
  return localStorage.removeItem(config.tokenAlias!);
}

function getToken() {
  return localStorage.getItem(config.tokenAlias!);
}

export { setToken, deleteToken, getToken };
