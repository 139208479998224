import {
  Box,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React from 'react';
import QuoteIcon from '../core/icons/quoteIcon';
import { NewsList } from '../lists/newsList';
import { useQuery } from 'react-query';
import news from '../../api/news';
import { ENewsType, INews, TNews } from '../../types/news.interface';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  headingRoot: {
    backgroundColor: '#EDF0F9',
  },
  titleBox: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .heading-wrap': {
      margin: theme.spacing(4),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(2),
      },
    },
    '& .heading-date': {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      '& h6': {
        color: '#7891F8',
      },
    },
  },
  contentBlock: {
    marginRight: 12,
    '& img': {
      maxWidth: '100%',
    },
    '& h1': {
      fontSize: 40,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
    },
    '& h2': {
      fontSize: 28,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
    },
    '& h3': {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
    },
    '& h4': {
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
    },
    '& h5': {
      fontSize: 13,
      lineHeight: 1.3,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
    },
    '& p': {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  description: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  moreNewsTitleWrap: {
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  moreNewsWrap: {
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
}));

const PrimaryHeading = styled(Typography)({
  fontSize: 40,
  fontWeight: 600,
  font: 'Proxima Nova Lt',
});

export const NewsView: React.FC<{ item: TNews }> = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const newsQuery = useQuery(
    ['news', ENewsType.news],
    () =>
      news.list<{ hasNextPage: boolean; items: Array<INews> }>(
        ENewsType.news,
        1
      ),
    {
      suspense: false,
    }
  );
  return (
    <Grid container item direction='column'>
      <Grid item container className={classes.headingRoot}>
        <Grid item md={item.picture ? 5 : 8} sm={12}>
          <Box className={classes.titleBox}>
            <Box className='heading-wrap'>
              <PrimaryHeading variant='h1'>{item.title}</PrimaryHeading>
            </Box>
            <Box className='heading-date'>
              <Typography variant='subtitle1'>
                {format(new Date(+item.publishAt * 1000), 'dd MMM', {
                  locale: ru,
                })}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={item.picture ? 7 : 4} sm={12}>
          {item.picture && (
            <Box
              style={{
                width: '100%',
                height: '60vh',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                //@ts-ignore
                backgroundImage: `url('/api/v1/file/resolve/${item.picture.id}/none')`,
                // backgroundImage: `url('/file/resolve/${item.picture.id}')`
              }}
            ></Box>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        wrap='nowrap'
        style={{ background: '#fff' }}
      >
        <Hidden smDown>
          <Grid item md={5}>
            <Box component='blockquote' width='160px' mt='35%' ml={4}>
              <QuoteIcon style={{ width: 58, height: 48 }} />
              <Typography style={{ color: '#7891F8' }} variant='subtitle1'>
                {item.description}
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <Grid item md={7} sm={12} container direction='column'>
          <Grid item>
            <Box className={classes.description}>
              <Typography variant='body1'>{item.description}</Typography>
            </Box>
          </Grid>
          <Box mr={1.5} mb={3}>
            <Divider />
          </Box>
          <Grid item>
            <div
              className={classes.contentBlock}
              dangerouslySetInnerHTML={{ __html: item.body }}
            />
            <Box pb={4} />
          </Grid>
        </Grid>
      </Grid>
      {newsQuery.data?.items && newsQuery.data.items.length > 0 && (
        <Box mt={2}>
          <Grid item container direction='row' style={{ background: '#fff' }}>
            <Grid item md={5}>
              <Box className={classes.moreNewsTitleWrap}>
                <Typography variant='h2'>{t('page.news.otherNews')}</Typography>
              </Box>
            </Grid>
            <Grid item md={7} sm={12}>
              <Box className={classes.moreNewsWrap}>
                <NewsList items={newsQuery.data.items} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};
