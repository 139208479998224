import { Box, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import http from '../../api/client';
import IFile from '../../types/file.interface';
import AppButton from '../core/buttons';
import { useTranslation } from 'react-i18next';

interface LoadingFileProps {
  file: File;
  onLoadSuccess: (file: IFile) => void;
}

export const LoadingFile: React.FC<LoadingFileProps> = ({
  file,
  onLoadSuccess,
}) => {
  const [progress, setProgress] = useState(0);
  const cancelToken = useRef(axios.CancelToken.source());
  const { t } = useTranslation();
  const load = async () => {
    const fm = new FormData();
    fm.append('file', file);
    const response = await http.post('/file/upload', fm, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (pe) =>
        setProgress(Math.round((pe.loaded / pe.total) * 100)),
      cancelToken: cancelToken.current.token,
    });
    onLoadSuccess(response.data.data);
  };
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Typography variant='caption'>
        {file.name} {progress}%
      </Typography>
      <AppButton
        onClick={() => {
          cancelToken.current.cancel();
        }}
      >
        {t('button.cancel')}
      </AppButton>
    </Box>
  );
};

export default LoadingFile;
