import { Box, Grid, makeStyles, Popover, styled } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { CalendarIcon } from '../core/icons/calendar';
import AppInput from '../core/outlinedInput';
import DayPicker from 'react-day-picker';
import 'moment/locale/ru';
import MomentLocaleUtils from 'react-day-picker/moment';

const useStyles = makeStyles((theme) => ({
  smallArrow: {
    fontSize: 12,
    margin: '0 5px',
    // height: 20,
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
}));

const PeriodPickerWrap = styled(Box)(({ theme }) => ({
  height: '1.1876em',
  padding: '8px 16px',
  border: 1,
  borderRadius: 4,
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

const StyledAppInput = styled(AppInput)({
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 4px',
  width: 95,
  minWidth: 0,
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px',
  },
  '& input::-webkit-calendar-picker-indicator': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& svg': {
    cursor: 'pointer',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

interface PeriodPickerProps {
  startDate?: string | number;
  endDate?: string | number;
  onChange: ({
    from,
    to,
  }: {
    from?: string | number;
    to?: string | number;
  }) => void;
}

export const PeriodPicker: React.FC<PeriodPickerProps> = ({
  startDate,
  endDate,
  onChange,
}) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<SVGElement | null>(null);
  return (
    <>
      <PeriodPickerWrap>
        <CalendarIcon onClick={(e) => setAnchor(e.currentTarget)} />
        <StyledAppInput
          type='date'
          onClick={(e) => e.preventDefault()}
          value={
            startDate
              ? format(new Date(startDate), 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd')
          }
          endAdornment={null}
        />
        <ArrowForward className={classes.smallArrow} fontSize='small' />
        <StyledAppInput
          type='date'
          onClick={(e) => e.preventDefault()}
          value={
            endDate
              ? format(new Date(endDate), 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd')
          }
          endAdornment={null}
        />
      </PeriodPickerWrap>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchor(null)}
        open={Boolean(anchor)}
        anchorEl={anchor}
      >
        <Grid container>
          <Grid item>
            <DayPicker
              locale='en'
              onDayClick={(d) =>
                onChange({
                  to: endDate,
                  from: d.getTime(),
                })
              }
              selectedDays={[new Date(startDate || '')]}
              month={startDate ? new Date(startDate) : undefined}
              localeUtils={MomentLocaleUtils}
            />
          </Grid>
          <Grid item>
            <DayPicker
              locale='en'
              selectedDays={[new Date(endDate || '')]}
              month={endDate ? new Date(endDate) : undefined}
              onDayClick={(d) =>
                onChange({
                  from: startDate,
                  to: d.getTime(),
                })
              }
              localeUtils={MomentLocaleUtils}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PeriodPicker;
