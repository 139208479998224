import { Box, Drawer, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AppButton from '../core/buttons';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FormItemRow from '../forms/formRow';
import { useTranslation } from 'react-i18next';
import docsRelease from '../../api/docsRelease';
import { useHistory } from 'react-router';
import IDocProduct from '../../types/docRelease.interface';
import LoadingFile from '../loadingFile';
import FileCard from '../fileCard';
import { FileDropZone } from '../fileDropZone';
import IFile from '../../types/file.interface';

interface MaterialProductDrawerProps {
  open: boolean;
  updateTarget?: IDocProduct;
  onClose: () => void;
}

export const MaterialProductDrawer: React.FC<MaterialProductDrawerProps> = ({
  open,
  updateTarget,
  onClose,
}) => {
  const [title, setTitle] = useState<string>('');

  const qc = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const [picture, setPicture] = useState<IFile>();
  const [files, setFiles] = useState<Array<File>>([]);

  useMemo(() => {
    if (!updateTarget) return;

    setPicture(updateTarget.picture);
    setTitle(updateTarget.title);
  }, [updateTarget]);

  const productCreate = useMutation('docs-release', docsRelease.create, {
    onSuccess: (product) => {
      qc.invalidateQueries(['docs-releases']);
      history.push(`/documentation/${product!.id}`);
      onClose();
    },
    onError: (err) => {
      //@ts-ignore
      enqueueSnackbar((err as any).response.data.errors[0].reason, {
        variant: 'error',
      });
    },
  });

  const handleCreateRelease = () => {
    productCreate.mutate({
      title,
      picture,
    }, {
      onSuccess: () => {
        setTitle('')
        setPicture(undefined)
      }
    });
  };

  const handleLoadSuccess = (fileResponse: IFile, f: File) => {
    const nf = files.filter((file) => file.name !== f.name);
    setFiles(nf);
    setPicture(fileResponse);
  };

  const handleFileDelete = () => {
    setPicture(undefined);
  };

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      setFiles((files) => [...files, list[i]]);
    }
  };

  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 487 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Box mx={3} mt={3}>
        <Box mb={2}>
          <Typography variant='h3'>
            {t('component.addProductDrawer.createHeadline')}
          </Typography>
        </Box>

        <Box>
          {files.map((f, i) => (
            <LoadingFile
              onLoadSuccess={(fileResponse) =>
                handleLoadSuccess(fileResponse, f)
              }
              key={f.name + i}
              file={f}
            />
          ))}
          {picture ? (
            <FileCard
              key={picture.id}
              file={picture}
              onDelete={handleFileDelete}
            />
          ) : (
            <Box height={284}>
              <FileDropZone
                onChange={handleNewFiles}
                allowedFileExtensions={[]}
                allowedMimeTypes={[]}
              />
            </Box>
          )}
        </Box>

        <Box display='flex' flexDirection='column' mt={3}>
          <FormItemRow>
            <AppInputLabel>
              {t('component.addProductDrawer.title')}
            </AppInputLabel>

            <AppInput value={title} onChange={(v) => setTitle(v.target.value)} />
          </FormItemRow>

          <Box mt={1}>
            <FormItemRow>
              <AppInputLabel />

              <AppButton
                disabled={!title}
                variant='contained'
                color='primary'
                onClick={handleCreateRelease}
              >
                {t('button.add')}
              </AppButton>
            </FormItemRow>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
