import { isAfter, isBefore, isSameDay } from 'date-fns';
import React from 'react';
import { NewDateRangeCalendarCommonProps, TNewRangePickerView } from '.';
import { MonthPicker } from './month';
import { QuarterPicker } from './quarter';
import { YearPicker } from './year';

export const NewDateRangePickerBuilder: React.FC<
  NewDateRangeCalendarCommonProps & {
    view: TNewRangePickerView;
    initialMonth: { from?: Date; to?: Date };
  }
> = ({ view, from, to, onDateSet, initialMonth, ...commonProps }) => {
  const handleDayClick = (d: Date) => {
    if (!from) {
      onDateSet({
        from: d,
        to: to,
      });
    } else {
      if (isSameDay(d, from)) {
        onDateSet({
          from: undefined,
          to: undefined,
        });
      } else if (isAfter(d, from)) {
        onDateSet({
          from: from,
          to: d,
        });
      } else if (isBefore(d, from)) {
        onDateSet({
          from: d,
          to: to,
        });
      }
      if (to && isAfter(d, to)) {
        onDateSet({
          from: from,
          to: d,
        });
      } else if (to && from && isAfter(d, from) && isBefore(d, to)) {
        onDateSet({
          from: from,
          to: d,
        });
      }
    }
  };

  switch (view) {
    case 'month':
      return (
        <MonthPicker
          initialMonth={initialMonth}
          from={from}
          to={to}
          onDateSet={onDateSet}
          onDayClick={handleDayClick}
        />
      );
    case 'quarter':
      return (
        <QuarterPicker
          initialMonth={initialMonth}
          from={from}
          to={to}
          onDateSet={onDateSet}
          onDayClick={handleDayClick}
        />
      );
    case 'year':
      return (
        <YearPicker
          initialMonth={initialMonth}
          from={from}
          to={to}
          onDateSet={onDateSet}
          onDayClick={handleDayClick}
        />
      );
    default:
      return null;
  }
};
