import logo from '../../assets/bssLogo.svg';
import background from '../../assets/fon.png';

export default {
  logo,
  favicon: '/bss.ico',
  background,
  backgroundColor: 'rgba(10, 0, 70, 1)',

  palette: {
    background: {
      default: 'rgba(10,0,70,1)',
    },
    default: {
      main: '#fff',
    },
    primary: {
      main: '#1C00A0',
      light: '#7891F8',
      contrastText: '#fff',
    },
    error: {
      main: '#E7636B',
      dark: '#CB3D3D',
    },
    success: {
      main: '#00B852',
    },
    secondary: {
      light: '#EE7D84',
      main: '#E7636B',
      dark: '#CB3D3D',
      contrastText: '#fff',
    },
    info: {
      main: '#7C79A1',
      light: '#DDD9F1',
    },
    text: {
      secondary: '#fff',
      primary: '#000',
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: {
    values: {
      md: 1000,
      sm: 740,
      xs: 320,
      lg: 1440,
      xl: 2160,
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
      textDecoration: 'none',
      color: 'inherit',
    },
    h2: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '36px',
      fontFamily: 'Proxima Nova Lt',
      textDecoration: 'none',
      color: 'inherit',
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
      textDecoration: 'none',
      color: 'inherit',
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
      textDecoration: 'none',
      color: 'inherit',
    },
    h5: {
      fontSize: 13,
      lineHeight: 1.3,
      fontWeight: 600,
      fontFamily: 'Proxima Nova Lt',
      textDecoration: 'none',
      color: 'inherit',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
      textDecoration: 'none',
      color: 'inherit',
    },
    body2: {
      fontSize: 13,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
      textDecoration: 'none',
      color: 'inherit',
    },
    fontWeightRegular: 400,
    fontWeightBold: 600,
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
      letterSpacing: 2,
      textDecoration: 'none',
      color: 'inherit',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.3,
      fontFamily: 'Proxima Nova Rg',
      textDecoration: 'none',
      color: 'inherit',
    },
    subtitle1: {
      fontSize: 13,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
      textDecoration: 'none',
      color: 'inherit',
    },
    poster: {
      fontSize: 10,
      fontWeight: 400,
      fontFamily: 'Proxima Nova Rg',
      lineHeight: 1.6,
      color: '#1C00A0, 50 %',
    },
  },
};
