import { Box, Divider, Grid, Typography, styled } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { FloatingEditLink } from '../newsMainGrid';
import { INews, TNews } from '../../types/news.interface';
import { monthFormatter } from '../../utils/dateTimeFormatter';
import { NewsEditPen } from '../core/buttons';

const NewsTitle = styled(Typography)(({ theme }) => ({
  maxWidth: '75%',
  '&:hover': {
    color: '#E7636B',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: '24px',
  },
}));
const NewsLead = styled(Typography)(({ theme }) => ({
  maxWidth: '75%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    fontSize: 16,
    lineHeight: '24px',
  },
}));

export const NewsListItem: React.FC<{
  item: INews;
  withPicture?: boolean;
  isEditing?: boolean;
}> = ({ item, withPicture, isEditing }) => {
  return (
    <Grid
      item
      container
      direction='column'
      style={{ color: '#000', textDecoration: 'none', position: 'relative' }}
      component={Link}
      to={`/news/${item.id}`}
    >
      <Grid item container direction='row'>
        <Grid item md>
          <NewsTitle variant='h3'>{item.title}</NewsTitle>
          <Box mt={1} />
          <NewsLead variant='body1'>{item.description}</NewsLead>
          <Box mt={1} />
          <Typography variant='subtitle2' style={{ color: '#7891F8' }}>
            {monthFormatter.format(new Date(+item.publishAt * 1000))}
          </Typography>
        </Grid>
        {withPicture && item.picture ? (
          <Grid item md={3} container justify='flex-end'>
            <img
              height={111}
              width={'auto'}
              //@ts-ignore
              src={`/api/v1/file/resolve/${item.picture.id}/none`}
              // src="https://picsum.photos/200/300"
              alt={item.title}
            />
          </Grid>
        ) : null}
        {isEditing && (
          <FloatingEditLink
            style={{ top: 0, right: 8 }}
            to={`/news/update/${item.id}`}
          >
            <NewsEditPen />
          </FloatingEditLink>
        )}
      </Grid>
      <Box mt={3} mb={2}>
        <Divider />
      </Box>
    </Grid>
  );
};

export const NewsList: React.FC<{
  items: Array<TNews>;
  isEditing?: boolean;
}> = ({ items, isEditing }) => {
  return (
    <Grid item container direction='column' style={{ background: '#fff' }}>
      {items &&
        items.map((item, index) => (
          <NewsListItem
            isEditing={isEditing}
            key={item.id}
            item={item as INews}
            withPicture={index === 0}
          />
        ))}
    </Grid>
  );
};
