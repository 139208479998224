import { Box, styled, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import IUser, { EUserRole } from '../../../types/user.interface';
import { phoneToValid } from '../../../utils/etc';
import AppButton from '../../core/buttons';
import { default as Al } from '../../core/label';
import { default as AInput } from '../../core/outlinedInput';
import MaskedPhone from '../../inputs/maskedPhone';
import { default as SInput } from '../../inputs/short';
import FormItemRow from '../formRow';

const AppInput = styled(AInput)({
  backgroundColor: '#fff',
});
const ShortInput = styled(SInput)({
  backgroundColor: '#fff',
});
const AppInputLabel = styled(Al)({
  maxWidth: 60,
  width: 'auto',
});

interface UserEmployeeFormProps {
  onCancel: () => void;
  user?: IUser;
  onCreate: (user: Omit<IUser, 'id'>) => void;
  onEdit: (user: IUser) => void;
}

export const UserEmployeeForm: React.FC<UserEmployeeFormProps> = ({
  onCancel,
  onCreate,
  onEdit,
  user,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<
    Omit<
      IUser,
      | 'id'
      | 'isAgreementAccepted'
      | 'role'
      | 'followedStatus'
      | 'cities'
      | 'permissions'
    >
  >({
    initialValues: user
      ? user
      : {
          fullName: '',
          email: '',
          phone: '',
          position: '',
          isSubscribedToPartnersRegistrations: false,
        },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('validation.email'))
        .required(t('validation.required')),
      fullName: yup.string().required(t('validation.required')),
      phone: yup.string().required(t('validation.required')),
      position: yup.string().required(t('validation.required')),
    }),
    onSubmit: async (values, heplers) => {
      await heplers.validateForm(values);
      if (user) {
        onEdit({
          id: user.id,
          ...values,
          phone: phoneToValid(values.phone),
          isAgreementAccepted: true,
          role: EUserRole.employee,
          followedStatus: [],
          cities: [],
          permissions: [],
        });
        onCancel();
      } else {
        onCreate({
          ...values,
          phone: phoneToValid(values.phone),
          isAgreementAccepted: true,
          role: EUserRole.employee,
          followedStatus: [],
          cities: [],
          permissions: [],
        });
        onCancel();
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box style={{ backgroundColor: '#EDF0F9' }} py={3}>
        <Box mx={3}>
          <Box mb={3}>
            <Typography variant='h4'>
              {t('component.managerUserEmployeeModal.headline')}
            </Typography>
          </Box>
          <FormItemRow>
            <AppInputLabel>{t('words.fullNameAbbr')}</AppInputLabel>
            <AppInput
              defaultValue={formik.initialValues.fullName}
              name='fullName'
              onChange={formik.handleChange}
              error={!!formik.errors.fullName}
              helperText={formik.errors.fullName}
            />
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>{t('words.email')}</AppInputLabel>
            <AppInput
              defaultValue={formik.initialValues.email}
              name='email'
              onChange={formik.handleChange}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>{t('words.position')}</AppInputLabel>
            <AppInput
              defaultValue={formik.initialValues.position}
              name='position'
              onChange={formik.handleChange}
              error={!!formik.errors.position}
              helperText={formik.errors.position}
            />
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>{t('words.phone')}</AppInputLabel>
            <ShortInput
              defaultValue={formik.initialValues.phone}
              name='phone'
              onChange={formik.handleChange}
              error={!!formik.errors.phone}
              helperText={formik.errors.phone}
              inputComponent={MaskedPhone}
            />
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel />
            <Box>
              <AppButton
                disabled={!formik.isValid}
                type='submit'
                variant='outlined'
                style={{ backgroundColor: '#fff', marginRight: 48 }}
                color='primary'
              >
                {user ? t('button.save') : t('button.add')}
              </AppButton>
              <AppButton onClick={onCancel} color='primary'>
                {t('button.cancel')}
              </AppButton>
            </Box>
          </FormItemRow>
        </Box>
      </Box>
    </form>
  );
};
