import React, { Suspense } from 'react';
import {
  ThemeProvider,
  styled,
  Container,
  makeStyles,
} from '@material-ui/core';
import theme from './theme/index';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAppDispatch } from './hooks/useStore';
import { tryLogin } from './store/user/actions';
import AuthRoutes from './routes/auth';
import MainRoutes from './routes/main';
import PrivateRoute from './hoc/privateRoute';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import MainLoader from './components/mainLoader';
import ErrorBoundary from './hoc/errorBoundary';
import ErrorHandler from './pages/errorHandler';
import Agreement from './pages/login/agreement';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const useStyles = makeStyles({
  customInfoSnack: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)',
    color: '#000',
  },
});

const AppContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  flex: '1 0 100vh',
  height: '100vh',
  backgroundSize: 'contain',
  backgroundPositionY: -50,
  backgroundPositionX: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    backgroundPositionY: 'top',
    backgroundSize: 'cover',
  },
}));

export const qc = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      suspense: true,
    },
  },
});

function App() {
  const classes = useStyles();
  useAppDispatch()(tryLogin());

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transitionDuration={{
          appear: 100,
          enter: 500,
          exit: 100,
        }}
        classes={{
          variantInfo: classes.customInfoSnack,
        }}
        iconVariant={{
          success: '',
          error: '',
          warning: '',
          info: '',
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
            <Suspense fallback={<MainLoader />}>
              <BrowserRouter>
                <QueryClientProvider client={qc}>
                  <AppContainer maxWidth='xl' disableGutters>
                    <Switch>
                      <Route
                        path='/404'
                        render={() => <ErrorHandler error='404' />}
                      />
                      <Route path='/agreement' component={Agreement} exact />
                      <Route path='/auth'>
                        <AuthRoutes />
                      </Route>
                      <PrivateRoute path='/'>
                        <MainRoutes />
                      </PrivateRoute>
                    </Switch>
                  </AppContainer>
                </QueryClientProvider>
              </BrowserRouter>
            </Suspense>
          </ErrorBoundary>
        </I18nextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
