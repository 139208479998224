import { InputLabel, styled } from '@material-ui/core';

const AppInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.5,
  flex: 1,
  maxWidth: 120,
  marginRight: 16,
  color: theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 4,
    display: 'inline-flex',
    minWidth: 200,
    flexWrap: 'nowrap',
  },
}));

export default AppInputLabel;
