import http from './client';

async function news(id: string): Promise<void> {
  await http.get(`/delete/news/${id}`);
}

async function partner(id: string): Promise<void> {
  await http.get(`/delete/partner/${id}`);
}

async function project(id: string): Promise<void> {
  await http.get(`/delete/project/${id}`);
}

async function user(id: string): Promise<void> {
  await http.get(`/delete/user/${id}`);
}

async function managerContact(id: string): Promise<void> {
  await http.get(`/delete/manager_contact/${id}`);
}
async function material(id: string): Promise<void> {
  await http.get(`/delete/material/${id}`);
}

async function doc(id: string): Promise<void> {
  await http.get(`/delete/docs/${id}`);
}

async function materialGroup(id: string): Promise<void> {
  await http.get(`/delete/material_group/${id}`);
}
async function materialCategory(id: string): Promise<void> {
  await http.get(`/delete/material_category/${id}`);
}

const deleteApi = {
  news,
  partner,
  managerContact,
  project,
  user,
  material,
  materialGroup,
  materialCategory,
  doc,
};

export default deleteApi;
