import IUser from '../types/user.interface';
import http from './client';
import { IEmployeesSearchParams } from '../types/employees.interface';

async function create(user: Omit<IUser, 'id' | 'role'> & { partner?: string }) {
  return http.post('/user/create', user);
}

async function update(user: Omit<IUser, 'role'>) {
  return http.post('/user/edit', user);
}

async function deleteSelf(id: string) {
  return http.get('/user/delete/' + id);
}

async function list(
  page: number = 1,
  searchParams?: IEmployeesSearchParams
): Promise<{ hasNextPage: boolean; items: Array<IUser> }> {
  const res = await http.post('/user/list', { page: page, ...searchParams });
  return res.data.data;
}
async function get(id: string): Promise<IUser> {
  const response = await http.post('/user/get', { id });
  return response.data.data;
}
const employee = {
  create,
  update,
  list,
  get,
  deleteSelf,
};

export default employee;
