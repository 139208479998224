import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UnionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 10 10'>
      <path d='M6.00004 1H8.29293L3.14648 6.14645L3.85359 6.85355L9.00004 1.70711V4H10V0H6.00004V1Z' />
      <path d='M0 1H4V2H1V9H8V6H9V10H0V1Z' />
    </SvgIcon>
  );
};
