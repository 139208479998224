import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UserIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 32 32'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3649 4C11.9778 4 8.63325 7.92721 9.33181 12.2583L9.88422 15.6832C10.3784 18.7474 13.0234 21 16.1273 21H16.5276C19.5363 21 22.1225 18.8664 22.6942 15.9125L23.359 12.4776C24.2098 8.08173 20.8424 4 16.3649 4ZM21.2981 12.6004L21.3954 12.0976C22.0074 8.93581 19.5853 6 16.3649 6C13.7849 6 11.7062 7.8883 11.3085 10.2798L14.8876 8.74585L15.3438 9.46357C16.3283 11.0124 17.075 11.8624 17.9408 12.3041C18.7003 12.6916 19.6893 12.8312 21.2981 12.6004ZM20.8991 14.6621L20.7306 15.5325C20.3409 17.5458 18.5783 19 16.5276 19H16.1273C14.0051 19 12.1966 17.4599 11.8587 15.3648L11.3775 12.381L11.3938 12.4191L14.1244 11.2489C14.9803 12.5002 15.8667 13.4912 17.0318 14.0856C18.1393 14.6506 19.389 14.8052 20.8991 14.6621Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26 28.4907C29.6578 25.5585 32 21.0528 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 21.0528 2.34215 25.5585 6.00001 28.4907L6 28.5H6.01156C8.74898 30.6903 12.2215 32 16 32C19.7785 32 23.251 30.6903 25.9884 28.5H26L26 28.4907ZM23.9014 27.5588C21.6526 29.099 18.9315 30 16 30C13.0685 30 10.3474 29.099 8.09859 27.5588C8.53133 25.5253 10.3375 24 12.5 24H19.5C21.6625 24 23.4687 25.5253 23.9014 27.5588ZM25.5835 26.2057C24.6562 23.748 22.2822 22 19.5 22H12.5C9.71777 22 7.34381 23.748 6.41648 26.2057C3.69793 23.6519 2 20.0243 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 20.0243 28.3021 23.6519 25.5835 26.2057Z'
      />
    </SvgIcon>
  );
};
