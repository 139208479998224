import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { isSameDay } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { FloatingEditLink, NewsTitle } from '../newsMainGrid';
import { IEvent, TNews } from '../../types/news.interface';
import { monthFormatter } from '../../utils/dateTimeFormatter';
import { NewsEditPen } from '../core/buttons';

const useStyles = makeStyles({
  dateBox: {
    background: '#DDD9F1',
    borderRadius: 4,
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
    padding: '0 8px',
    '& h6, span': {
      color: '#000',
      fontSize: 13,
      letterSpacing: 0,
    },
  },
});

export const EventListItem: React.FC<{
  item: IEvent;
  withPicture?: boolean;
  isEditing?: boolean;
}> = ({ item, withPicture, isEditing }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction='column'
      style={{ color: '#000', textDecoration: 'none', position: 'relative' }}
      component={Link}
      to={`/news/${item.id}`}
    >
      <Grid item container direction='row'>
        <Grid item md container direction='column' alignItems='flex-start'>
          {isSameDay(
            new Date(+item.eventStartAt * 1000),
            new Date(+item.eventEndAt * 1000)
          ) ? (
            <Box className={classes.dateBox}>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventStartAt * 1000))}
              </Typography>
            </Box>
          ) : (
            <Box className={classes.dateBox}>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventStartAt * 1000))}
                <span>{' - '}</span>
              </Typography>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventEndAt * 1000))}
              </Typography>
            </Box>
          )}
          <NewsTitle variant='h3'>{item.title}</NewsTitle>
          <Box mt={1} />
        </Grid>
        {withPicture && item.picture ? (
          <Grid item md={3} container justify='flex-end'>
            <img
              height={111}
              width={'auto'}
              //@ts-ignore
              src={`/api/v1/file/resolve/${item.picture.id}/none`}
              // src="https://picsum.photos/200/300"
              alt={item.title}
            />
          </Grid>
        ) : null}
      </Grid>
      <Box mt={3} mb={2}>
        <Divider />
      </Box>
      {isEditing && (
        <FloatingEditLink
          style={{ top: 0, right: 8 }}
          to={`/news/update/${item.id}`}
        >
          <NewsEditPen />
        </FloatingEditLink>
      )}
    </Grid>
  );
};

const EventsSmallListItem: React.FC<{ item: IEvent; isEditing?: boolean }> = ({
  item,
  isEditing,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction='column'
      style={{ color: '#000', textDecoration: 'none', position: 'relative' }}
      component={Link}
      to={`/news/${item.id}`}
    >
      <Grid item container direction='row'>
        <Grid item container md alignItems='flex-start' direction='column'>
          {isSameDay(
            new Date(+item.eventStartAt * 1000),
            new Date(+item.eventEndAt * 1000)
          ) ? (
            <Box className={classes.dateBox}>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventStartAt * 1000))}
              </Typography>
            </Box>
          ) : (
            <Box className={classes.dateBox}>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventStartAt * 1000))}
                <span>{' - '}</span>
              </Typography>
              <Typography variant='subtitle2'>
                {monthFormatter.format(new Date(+item.eventEndAt * 1000))}
              </Typography>
            </Box>
          )}
          <NewsTitle variant='body1'>{item.title}</NewsTitle>
          <Box mt={1} />
        </Grid>
      </Grid>
      <Box mt={3} mb={2}>
        <Divider />
      </Box>
      {isEditing && (
        <FloatingEditLink
          style={{ top: 0, right: 8 }}
          to={`/news/update/${item.id}`}
        >
          <NewsEditPen />
        </FloatingEditLink>
      )}
    </Grid>
  );
};

export const EventsList: React.FC<{
  items: Array<TNews>;
  small?: boolean;
  isEditing?: boolean;
}> = ({ items, small, isEditing }) => {
  return (
    <Grid item container direction='column' style={{ background: '#fff' }}>
      {items &&
        items.map((item, index) =>
          small ? (
            <EventsSmallListItem
              isEditing={isEditing}
              key={item.id}
              item={item as IEvent}
            />
          ) : (
            <EventListItem
              isEditing={isEditing}
              key={item.id}
              item={item as IEvent}
              withPicture={index === 0}
            />
          )
        )}
    </Grid>
  );
};

export default EventsList;
