import { createSlice } from '@reduxjs/toolkit';
import IPartner from '../../types/partner.interface';
import IUser from '../../types/user.interface';
import { login, logout, tryLogin, updateProfile } from './actions';
import i18n from '../../i18n';

type TAuthStatus = 'unknown' | 'unauthorized' | 'authorized' | 'pending';
interface IUserState {
  status: TAuthStatus;
  profile?: IUser;
  partner?: IPartner;
  loginError?: string;
  restoreError?: string;
  updateError?: string;
}

const initialState: IUserState = {
  status: 'unknown',
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(login.rejected, (state) => {
        state.status = 'unauthorized';
        state.loginError = i18n.t('validation.authWrongCredentials');
      })
      .addCase(tryLogin.fulfilled, (state, action) => {
        state.status = 'authorized';
        state.profile = action.payload.user;
        state.partner = action.payload.partner;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        state.status = 'unauthorized';
      })
      .addCase(tryLogin.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = 'unauthorized';
        state.profile = undefined;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateError = i18n.t('validation.authUpdateRejected');
      });
  },
});

export default userSlice;
