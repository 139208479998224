import { Box } from '@material-ui/core';
import React from 'react';
import TrashBinIcon from '../core/icons/trashBin';

export const ImageCard: React.FC<{
  id: string;
  onDelete: (id: string) => void;
}> = ({ id, onDelete }) => {
  return (
    <Box display='flex' flexDirection='row' flexWrap='nowrap' pt={1}>
      <img
        height='auto'
        width={91}
        src={`/api/v1/file/resolve/${id}/none`}
        // src={'https://picsum.photos/300'}
        alt={'alt'}
      />
      <Box ml={1.8} mt={0.5}>
        <TrashBinIcon
          style={{ fontSize: 16, cursor: 'pointer' }}
          color='secondary'
          onClick={() => onDelete(id)}
        />
      </Box>
    </Box>
  );
};
