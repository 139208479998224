import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Grid,
  makeStyles,
  Select,
  styled,
  Switch,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import material from '../../api/material';
import IFile from '../../types/file.interface';
import {
  IMaterialCategory,
  IMaterialGroup,
  materialTypeDict,
  TMaterialCategoryType,
} from '../../types/material.interface';
import AppButton from '../core/buttons';
import { AppMenuItem, AppPopover } from '../core/contextMenu';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FileCard from '../fileCard';
import { FileDropZone } from '../fileDropZone';
import FormItemRow from '../forms/formRow';
import LoadingFile from '../loadingFile';

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const FatDot = styled('div')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'black',
});

const SelectLike = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  paddingRight: 6,
  height: 40,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #E8E8E8',
  boxSizing: 'border-box',
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
    cursor: 'poniter',
    position: 'absolute',
  },
  '&:hover': {
    border: '1px solid #000',
  },
}));

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 13,
    minWidth: 65,
    lineHeight: '20px',
    flex: 0,
  },
  mi: {
    '& .selected': {
      fontWeight: 600,
    },
  },
  firstRow: {
    marginBottom: 16,
  },
  secondRow: {
    marginBottom: 0,
  },
  btn: {
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#fff',
  },
  exBtn: {
    padding: 0,
    paddingLeft: 16,
    paddingTop: 14,
    '& > svg': {
      fontSize: 8,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  saveBtn: {
    padding: '10px 24px',
    marginTop: theme.spacing(3),
  },
}));

interface MaterialFileDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CustomRadio = styled('label')(({ theme }) => ({
  marginRight: 15,
  '& input': {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  '& .typo': {
    borderColor: 'transparent',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 4,
  },
  '& input:checked ~ .typo': {
    borderColor: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
}));

export const MaterialFileDrawer: React.FC<MaterialFileDrawerProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [categoryAnchor, setCategoryAnchor] = useState<HTMLElement | null>(
    null
  );
  const [groupAnchor, setGroupAnchor] = useState<HTMLElement | null>(null);
  const [type, setType] = useState<TMaterialCategoryType>('ads');
  const [category, setCategory] = useState<IMaterialCategory>();
  const [group, setGroup] = useState<IMaterialGroup>();
  const [url, setUrl] = useState('');

  const [newCategory, setNewCategory] = useState('');
  const [showCategoryForm, setShowCategoryForm] = useState(false);

  const [newGroup, setNewGroup] = useState('');
  const [showGroupForm, setShowGroupForm] = useState(false);
  const categoryQuery = useQuery(
    ['materialCategory', type],
    () => material.category.list({ type }),
    {
      suspense: false,
      enabled: open,
    }
  );
  const groupQuery = useQuery(
    ['materialGroup', category],
    () => material.group.list({ category: category!.id }),
    {
      enabled: !!category,
      suspense: false,
    }
  );
  const [files, setFiles] = useState<Array<File>>([]);
  const [loadedFile, setLoaded] = useState<IFile>();
  const qc = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [publicId, setPublicId] = useState<string | undefined>('');
  const [isActual, setIsActual] = useState<boolean>(true);
  const [payloadType, setPayloadType] = useState<'file' | 'url'>('file');

  const categoryCreate = useMutation(
    'materialCategory',
    material.category.create,
    {
      onSuccess: () => {
        qc.invalidateQueries('materials');
        qc.invalidateQueries('materialCategory');

        setShowCategoryForm(false);
        setNewCategory('');
      },
    }
  );
  const groupCreate = useMutation('materialGroup', material.group.create, {
    onSuccess: () => {
      qc.invalidateQueries('materials');
      qc.invalidateQueries('materialGroup');

      setShowGroupForm(false);
      setNewGroup('');
    },
  });

  const materialCreate = useMutation('material', material.item.create, {
    onSuccess: () => {
      enqueueSnackbar(t('toastNotifications.materialCreated'), {
        variant: 'info',
      });
      qc.invalidateQueries('materials');
      setLoaded(undefined);
      onClose();
    },
  });

  const handleCreateCategory = () => {
    if (newCategory) {
      categoryCreate.mutate({
        title: newCategory,
        type: type,
      });
    }
  };
  const handleNewGroup = () => {
    if (newGroup && category) {
      groupCreate.mutate({
        title: newGroup,
        category: category.id,
      });
    }
  };
  const handleAddMaterial = () => {
    materialCreate.mutate({
      file: payloadType === 'file' ? loadedFile!.id : undefined,
      group: group!.id,
      publicId: publicId ?? '',
      url: payloadType === 'url' ? url : undefined,
      name,
      isActual,
    });
  };

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      setFiles((files) => [...files, list[i]]);
    }
  };

  const handleLoadSuccess = (fileResponse: IFile, f: File) => {
    const nf = files.filter((file) => file.name !== f.name);
    setFiles(nf);
    setLoaded(fileResponse);
  };
  const handleFileDelete = () => {
    setLoaded(undefined);
  };

  const isValid = useMemo(() => {
    if (payloadType === 'file' && !loadedFile) {
      return false;
    }

    if (payloadType === 'url' && !url) {
      return false;
    }

    return group && name;
  }, [group, name, payloadType, url, loadedFile]);

  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 487 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Box mx={3} mt={3}>
        <Box mb={2}>
          <Typography variant='h3'>
            {t('component.addFileDrawer.createHeadline')}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='row' mb={1}>
          <CustomRadio htmlFor='events'>
            <input
              defaultChecked={true}
              onChange={(e) => {
                setPayloadType('file');
              }}
              type='radio'
              name='type'
              id='events'
            />
            <Typography variant='h5' className='typo'>
              {t('component.addFileDrawer.typeFile')}
            </Typography>
          </CustomRadio>
          <CustomRadio htmlFor='news'>
            <input
              defaultChecked={false}
              onChange={(e) => {
                setPayloadType('url');
              }}
              type='radio'
              name='type'
              id='news'
            />
            <Typography variant='h5' className='typo'>
              {t('component.addFileDrawer.typeUrl')}
            </Typography>
          </CustomRadio>
        </Box>

        {payloadType === 'file' && (
          <Box>
            {files.map((f, i) => (
              <LoadingFile
                onLoadSuccess={(fileResponse) =>
                  handleLoadSuccess(fileResponse, f)
                }
                key={f.name + i}
                file={f}
              />
            ))}
            {loadedFile ? (
              <FileCard
                key={loadedFile.id}
                file={loadedFile}
                onDelete={handleFileDelete}
              />
            ) : (
              <Box height={284}>
                <FileDropZone
                  onChange={handleNewFiles}
                  allowedFileExtensions={[]}
                  allowedMimeTypes={[]}
                />
              </Box>
            )}
          </Box>
        )}

        <Box display='flex' flexDirection='column' mt={3}>
          {payloadType === 'url' && (
            <FormItemRow>
              <AppInputLabel
                style={{ alignSelf: 'flex-start', width: '100px' }}
              >
                {t('component.addFileDrawer.url')}
              </AppInputLabel>
              <AppInput
                defaultValue={url}
                onChange={(e) => setUrl(e.currentTarget.value)}
              />
            </FormItemRow>
          )}

          <FormItemRow>
            <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
              {t('component.addFileDrawer.name')}
            </AppInputLabel>
            <AppInput
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </FormItemRow>

          <FormItemRow>
            <AppInputLabel>
              {t('component.addFileDrawer.section')}
            </AppInputLabel>
            <StyledSelect
              variant='outlined'
              IconComponent={(props) => <FieldArrowDown {...props} />}
              value={type}
              onChange={(e: any) => {
                setType(e.target.value);
                setCategory(undefined);
                setGroup(undefined);
              }}
              //@ts-ignore
              renderValue={(value) => materialTypeDict[value]}
            >
              <AppMenuItem value='sales'>
                {t('page.material.type.sales')}
              </AppMenuItem>
              <AppMenuItem value='tech'>
                {t('page.material.type.tech')}
              </AppMenuItem>
              <AppMenuItem value='ads'>
                {t('page.material.type.ads')}
              </AppMenuItem>
              <AppMenuItem value='partnership'>
                {t('page.material.type.partnership')}
              </AppMenuItem>
              <AppMenuItem value='cases'>
                {t('page.material.type.cases')}
              </AppMenuItem>
            </StyledSelect>
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>
              {t('component.addFileDrawer.category')}
            </AppInputLabel>
            <SelectLike onClick={(e) => setCategoryAnchor(e.currentTarget)}>
              <Typography>{category?.title}</Typography>
              <FieldArrowDown />
            </SelectLike>
            <AppPopover
              anchorEl={categoryAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!categoryAnchor}
              onBackdropClick={() => setCategoryAnchor(null)}
              onClose={() => setCategoryAnchor(null)}
              PaperProps={{
                style: {
                  minWidth: 380,
                  height: 230,
                  maxHeight: 440,
                },
              }}
            >
              <Box>
                <Box mx={2}>
                  <AppButton
                    onClick={() => setShowCategoryForm(true)}
                    color='primary'
                    startIcon={<Add style={{ fontSize: 12 }} />}
                    className={classes.exBtn}
                    disabled={showCategoryForm}
                  >
                    {t('button.add')}
                  </AppButton>
                </Box>
                {!showCategoryForm ? (
                  <Box mx={2}>
                    <Box mt={1.5} mb={3}>
                      <Divider />
                    </Box>
                  </Box>
                ) : (
                  <Collapse
                    in={showCategoryForm}
                    collapsedHeight={0}
                    timeout={300}
                  >
                    <Grid item>
                      <Box m={2} mt={0}>
                        <Box style={{ backgroundColor: '#EDF0F9' }}>
                          <Box m={3} mt={1} py={3}>
                            <FormItemRow className={classes.firstRow}>
                              <AppInputLabel className={classes.label}>
                                {t('words.title')}
                              </AppInputLabel>
                              <AppInput
                                style={{ backgroundColor: '#fff' }}
                                value={newCategory}
                                onChange={(e) =>
                                  setNewCategory(e.currentTarget.value)
                                }
                              />
                            </FormItemRow>
                            <FormItemRow className={classes.secondRow}>
                              <AppInputLabel className={classes.label} />
                              <Box display='flex' flexDirection='row'>
                                <Box mr={2}>
                                  <AppButton
                                    className={classes.btn}
                                    onClick={handleCreateCategory}
                                    variant='outlined'
                                    color='primary'
                                  >
                                    {t('button.add')}
                                  </AppButton>
                                </Box>
                                <AppButton
                                  color='primary'
                                  className={classes.btn}
                                  onClick={() => setShowCategoryForm(false)}
                                >
                                  {t('button.cancel')}
                                </AppButton>
                              </Box>
                            </FormItemRow>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Collapse>
                )}
                {categoryQuery.data?.items &&
                  categoryQuery.data.items.map((item: IMaterialCategory) => (
                    <AppMenuItem
                      className={classes.mi}
                      key={item.id}
                      value={item.id}
                      onClick={() => {
                        setCategoryAnchor(null);
                        setCategory(item);
                      }}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        flexWrap='nowrap'
                        width='100%'
                      >
                        <Box display='flex' flexDirection='column'>
                          <Typography
                            className={
                              category?.id === item.id ? 'selected' : ''
                            }
                            variant='body1'
                          >
                            {item.title}
                          </Typography>
                        </Box>
                        {category?.id === item.id && <FatDot />}
                      </Box>
                    </AppMenuItem>
                  ))}
              </Box>
            </AppPopover>
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>{t('component.addFileDrawer.group')}</AppInputLabel>
            <SelectLike onClick={(e) => setGroupAnchor(e.currentTarget)}>
              <Typography>{group?.title}</Typography>
              <FieldArrowDown />
            </SelectLike>
            <AppPopover
              anchorEl={groupAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!groupAnchor}
              onBackdropClick={() => setGroupAnchor(null)}
              onClose={() => setGroupAnchor(null)}
              PaperProps={{
                style: {
                  maxHeight: 224,
                  minWidth: 280,
                },
              }}
            >
              <Box>
                <Box mx={2}>
                  <AppButton
                    onClick={() => setShowGroupForm(true)}
                    color='primary'
                    startIcon={<Add style={{ fontSize: 12 }} />}
                    className={classes.exBtn}
                    disabled={showGroupForm}
                  >
                    {t('button.add')}
                  </AppButton>
                </Box>
              </Box>
              {!showGroupForm ? (
                <Box mx={2}>
                  <Box mt={1.5} mb={3}>
                    <Divider />
                  </Box>
                </Box>
              ) : (
                <Collapse in={showGroupForm} collapsedHeight={0} timeout={300}>
                  <Grid item>
                    <Box m={2} mt={0}>
                      <Box style={{ backgroundColor: '#EDF0F9' }}>
                        <Box m={3} mt={1} py={3}>
                          <FormItemRow className={classes.firstRow}>
                            <AppInputLabel className={classes.label}>
                              {t('words.title')}
                            </AppInputLabel>
                            <AppInput
                              style={{ backgroundColor: '#fff' }}
                              value={newGroup}
                              onChange={(e) =>
                                setNewGroup(e.currentTarget.value)
                              }
                            />
                          </FormItemRow>
                          <FormItemRow className={classes.secondRow}>
                            <AppInputLabel className={classes.label} />
                            <Box display='flex' flexDirection='row'>
                              <Box mr={2}>
                                <AppButton
                                  disabled={!category}
                                  className={classes.btn}
                                  onClick={handleNewGroup}
                                  variant='outlined'
                                  color='primary'
                                >
                                  {t('button.add')}
                                </AppButton>
                              </Box>
                              <AppButton
                                color='primary'
                                className={classes.btn}
                                onClick={() => setShowGroupForm(false)}
                              >
                                {t('button.cancel')}
                              </AppButton>
                            </Box>
                          </FormItemRow>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Collapse>
              )}
              {category &&
                groupQuery.data?.items &&
                groupQuery.data.items.map((item: IMaterialGroup) => (
                  <AppMenuItem
                    key={item.id}
                    value={item.id}
                    className={classes.mi}
                    onClick={() => {
                      setGroupAnchor(null);
                      setGroup(item);
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      alignItems='center'
                      flexWrap='nowrap'
                      width='100%'
                    >
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='body1'>{item.title}</Typography>
                      </Box>
                      {category?.id === item.id && <FatDot />}
                    </Box>
                  </AppMenuItem>
                ))}
            </AppPopover>
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>
              {t('component.editFileDrawer.actual')}
            </AppInputLabel>
            <Switch
              defaultChecked={isActual}
              onChange={(e) => setIsActual(e.currentTarget.checked)}
              color='primary'
            />
          </FormItemRow>
          <FormItemRow>
            <AppInputLabel>
              {t('component.editFileDrawer.publicId')}
            </AppInputLabel>
            <AppInput
              value={publicId}
              onChange={(e) => setPublicId(e.currentTarget.value)}
              helperText={
                !publicId
                  ? t('component.editFileDrawer.publicIdHintEmpty')
                  : t('component.editFileDrawer.publicIdHintFilled')
              }
            />
          </FormItemRow>

          <Box mt={1}>
            <FormItemRow>
              <AppInputLabel />
              <AppButton
                disabled={!isValid}
                variant='contained'
                color='primary'
                onClick={handleAddMaterial}
              >
                {t('button.add')}
              </AppButton>
            </FormItemRow>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
