import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FieldArrowDown: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width='14'
      height='8'
      viewBox='0 0 14 8'
      style={{ ...props.style, color: 'transparent', fontSize: 14 }}
    >
      <path fillRule='evenodd' d='M1 1L7 7L13 1' stroke='#919090' />
    </SvgIcon>
  );
};

export default FieldArrowDown;
