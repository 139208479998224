import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';
import { AppNav } from '../appNav';
import NavMobile from '../appNav/mobile';
import UserCard from '../userCard';
import useRbac from '../../hooks/useRbac';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
});

const MatchNavLink = styled(NavLink)({
  display: 'none',
  textDecoration: 'none',
  '&.active': {
    display: 'inline-flex',
  },
  '& h3': {
    textDecoration: 'none',
  },
});

const useStyles = makeStyles((theme) => ({
  mainNav: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const AppHeader: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.user);
  const rbac = useRbac();

  return (
    <>
      <Grid container item lg={12} className={classes.mainNav}>
        <Box mt={2.2} ml={4} mr={3.5}>
          <Link to='/'>
            <img
              alt='bss-log'
              style={{ width: 105, height: 'auto' }}
              src={t('theme.logo', { returnObjects: true })}
            />
          </Link>
        </Box>
        <Row>
          <AppNav />
          <UserCard />
        </Row>
      </Grid>
      <Hidden mdUp>
        <Box mx={3} my={2}>
          <Grid
            item
            container
            direction='row'
            sm={12}
            wrap='nowrap'
            justify='space-between'
            alignItems='center'
          >
            <Grid item>
              {profile?.role !== EUserRole.customer && (
                <>
                  <MatchNavLink to='/' exact>
                    <Typography variant='h3' color='textSecondary'>
                      {t('header.projects')}
                    </Typography>
                  </MatchNavLink>
                  <MatchNavLink to='/partner'>
                    <Typography variant='h3' color='textSecondary'>
                      {t('words.partner')}
                    </Typography>
                  </MatchNavLink>
                  <MatchNavLink to='/partners'>
                    <Typography variant='h3' color='textSecondary'>
                      {t('header.partners')}
                    </Typography>
                  </MatchNavLink>
                </>
              )}
              <MatchNavLink to='/materials'>
                <Typography variant='h3' color='textSecondary'>
                  {t('header.materials')}
                </Typography>
              </MatchNavLink>
              {/*<MatchNavLink to='/documentation'>*/}
              {/*  <Typography variant='h3' color='textSecondary'>*/}
              {/*    {t('header.docs')}*/}
              {/*  </Typography>*/}
              {/*</MatchNavLink>*/}

              {profile?.role !== EUserRole.customer && (
                <>
                  {rbac.can('country.list') &&<MatchNavLink to='/countries'>
                    <Typography variant='h3' color='textSecondary'>
                      {t('header.countries')}
                    </Typography>
                  </MatchNavLink>}
                  
                  {rbac.can('myPartner.view') && <MatchNavLink to='/company-profile'>
                    <Typography variant='h3' color='textSecondary'>
                      {t('header.companyProfile')}
                    </Typography>
                  </MatchNavLink>}

                  {rbac.can('employee.list') && <MatchNavLink to='/employees'>
                    <Typography variant='h3' color='textSecondary'>
                      {t('header.employees')}
                    </Typography>
                  </MatchNavLink>}
                </>
              )}

              <MatchNavLink to='/news'>
                <Typography variant='h3' color='textSecondary'>
                  {t('header.news')}
                </Typography>
              </MatchNavLink>
            </Grid>
            <NavMobile />
          </Grid>
        </Box>
      </Hidden>
    </>
  );
};
