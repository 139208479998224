import {
  Box,
  Collapse,
  Drawer,
  Grid,
  styled,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import { AppMenuItem } from '../core/contextMenu';
import CloseIcon from '../core/icons/close';
import PenIcon from '../core/icons/pen';
import TrashBinIcon from '../core/icons/trashBin';
import { useTranslation } from 'react-i18next';
import IUser from '../../types/user.interface';
import { UserEmployeeForm } from '../forms/userEmployee';

interface ManagerContactDrawerProps {
  open: boolean;
  onClose: () => void;
  onCreate: (manager: Omit<IUser, 'id'>) => void;
  onEdit: (manager: IUser) => void;
  onManagerDelete: (id: string) => void;
  hasNext: boolean;
  fetchNext: () => void;
  items: Array<IUser>;
}

const LocalMenuItem = styled(AppMenuItem)(({ theme }) => ({
  padding: '8px 24px',
  width: 'auto',
  '&:hover': {
    '& .icon-btn': {
      backgroundColor: '#3F26B9',
    },
    '& .icon-btn.pen': {
      color: '#fff',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

const LocalIconButton = styled('button')(({ theme }) => ({
  width: 28,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: 'none',
  background: 'transparent',
  marginLeft: 12,
  color: theme.palette.primary.main,
  '& svg': {
    fontSize: 16,
    marginLeft: 0,
  },
}));

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 28,
  cursor: 'pointer',
});

const useStyles = makeStyles((theme) => ({
  rootBox: {
    width: 487,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 487,
    },
  },
}));

export const UserEmployeeDrawer: React.FC<ManagerContactDrawerProps> = ({
  open,
  onClose,
  onCreate,
  onEdit,
  items,
  hasNext,
  fetchNext,
  onManagerDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [manager, setManger] = useState<IUser | undefined>();

  const handleManagerEdit = (manager: IUser) => {
    setManger(manager);
    setShowForm(true);
  };
  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)' },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Box className={classes.rootBox} mx={3} mt={4}>
        <StyledClose onClick={onClose} />

        <Grid item container direction='column' alignItems='flex-start'>
          <Typography variant='h3'>
            {t('component.managerUserEmployeeCreation.headline')}
          </Typography>
          <AppButton
            disabled={showForm}
            style={{ padding: '8px 8px 8px 8px', width: 'auto' }}
            onClick={() => setShowForm(true)}
            startIcon={<Add />}
            color='primary'
          >
            {t('button.add')}
          </AppButton>
          <Collapse
            unmountOnExit
            in={showForm}
            collapsedHeight={0}
            timeout={300}
          >
            <UserEmployeeForm
              user={manager}
              onCreate={(manager) => {
                setManger(undefined);
                setShowForm(false);
                onCreate(manager);
              }}
              onEdit={(manager) => {
                setManger(undefined);
                setShowForm(false);
                onEdit(manager);
              }}
              onCancel={() => {
                setManger(undefined);
                setShowForm(false);
              }}
            />
          </Collapse>
        </Grid>
      </Box>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchNext}
        hasMore={!!hasNext}
        loader={null}
      >
        <Box maxHeight={350}>
          {items.map((contact) => (
            <LocalMenuItem key={contact.id} value={contact.id}>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                flexWrap='nowrap'
                width='100%'
              >
                <Box display='flex' flexDirection='column'>
                  <Typography variant='body1'>{contact.fullName}</Typography>
                  <Typography variant='subtitle1'>
                    {formatPhoneNumber(contact.phone)} · {contact.phone}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  height='100%'
                  justifyContent='flex-start'
                >
                  <LocalIconButton
                    className='icon-btn'
                    onClick={() => onManagerDelete(contact.id)}
                  >
                    <TrashBinIcon color='secondary' />
                  </LocalIconButton>
                  <LocalIconButton
                    className='icon-btn pen'
                    onClick={() => handleManagerEdit(contact)}
                  >
                    <PenIcon color='inherit' />
                  </LocalIconButton>
                </Box>
              </Box>
            </LocalMenuItem>
          ))}
        </Box>
      </InfiniteScroll>
    </Drawer>
  );
};
