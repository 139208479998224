import IFile from './file.interface';
import IPartner from './partner.interface';
import i18n from '../i18n';
import IUser from './user.interface';
import ICity from './city.interface';
export enum EProjectStatus {
  active = 'active',
  pending = 'pending',
  inProcess = 'inProcess',
  closed = 'closed',
  expired = 'expired',
}

export const statusDict = Object.keys(EProjectStatus).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: i18n.t(`projectStatus.${curr}`),
  }),
  {}
) as { [key in EProjectStatus]: string };

export interface IProjectsSearchParams {
  search?: string;
  periodFrom?: string;
  periodTo?: string;
  partner?: string;
  city?: string;
  sort?: {
    by: string;
    direction: 'asc' | 'desc';
  };
  status?: EProjectStatus;
}

export default interface IProject {
  id: string;
  partner?: IPartner;
  city?: ICity;
  name: string;
  customer: string;
  subject: string;
  requestedAmount: number;
  actualAmount: number;
  manager?: IUser;
  comment: string;
  department: string;
  status: EProjectStatus;
  dealAt?: string;
  expireAt: string;
  createdAt: string;
  files: Array<IFile>;
}
