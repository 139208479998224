import http from './client';

async function news(id: string): Promise<void> {
  await http.get(`/restore/news/${id}`);
}

async function partner(id: string): Promise<void> {
  await http.get(`/restore/partner/${id}`);
}

async function project(id: string): Promise<void> {
  await http.get(`/restore/project/${id}`);
}

async function user(id: string): Promise<void> {
  await http.get(`/restore/user/${id}`);
}

async function managerContact(id: string): Promise<void> {
  await http.get(`/restore/manager_contact/${id}`);
}
async function material(id: string): Promise<void> {
  await http.get(`/restore/material/${id}`);
}

async function materialGroup(id: string): Promise<void> {
  await http.get(`/restore/material_group/${id}`);
}
async function materialCategory(id: string): Promise<void> {
  await http.get(`/restore/material_category/${id}`);
}

const restoreApi = {
  news,
  partner,
  managerContact,
  project,
  user,
  material,
  materialGroup,
  materialCategory,
};

export default restoreApi;
