//@ts-nocheck
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  RootRef,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import deleteApi from '../../api/delete';
import material from '../../api/material';
import sort from '../../api/sort';
import AppButton, { AppWhiteButton } from '../../components/core/buttons';
import PenIcon from '../../components/core/icons/pen';
import TwoLinesIcon from '../../components/core/icons/twoLines';
import StyledNavLink from '../../components/core/styledNavLink';
import FileBlankLinkCard from '../../components/fileBlankLinkCard';
import FileCard from '../../components/fileCard';
import MaterialCategoryDrawer from '../../components/materialCategoryDrawer';
import { MaterialEditFileDrawer } from '../../components/materialEditFileDrawer';
import { MaterialFileDrawer } from '../../components/materialFileDrawer';
import MaterialGroupDrawer from '../../components/materialGroupDrawer';
import {
  IMaterial,
  TMaterialCategoryType,
} from '../../types/material.interface';
import TableBinIcon from '../../components/core/icons/table';
import TableCellBinIcon from '../../components/core/icons/tableCell';
import { StyledSearchInput } from '../../components/employeesSearchbar';
import SearchIcon from '../../components/core/icons/search';
import { debounce } from 'lodash';

const DraggableFileContainer = styled('div')(({ theme, viewType }) => {

  if (viewType === 'table') {
    return ({
      display: 'block',
      flexDirection: 'row',
      padding: '8px 0px',
      paddingRight: 8,
      alignItems: 'center',
      backgroundColor: '#fff',
      '& .two-lines': {
        fontSize: 4,
        marginRight: 4,
      },
      '&.dragging': {
        boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
        '& .two-lines': {
          opacity: 0,
        },
      },
      '&.margin': {
        marginTop: 1,
        width: '100%',
      },
    })
  }

  return ({
    display: 'inline-flex',
    flexDirection: 'row',
    padding: '8px 0px',
    paddingRight: 8,
    alignItems: 'center',
    backgroundColor: '#fff',
    '& .two-lines': {
      fontSize: 4,
      marginRight: 4,
    },
    '&.dragging': {
      boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
      '& .two-lines': {
        opacity: 0,
      },
    },
    '&.margin': {
      marginTop: 24,
      marginRight: 30,
    },
  })
});

const CategoriesHeading = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#fff',
  '&.dragging': {
    boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
    '& svg': {
      opacity: 0,
    },
    '& .active': {
      borderBottom: `2px solid transparent`,
      paddingBottom: 0,
    },
  },
  '& svg': {
    // paddingTop: 9,
    marginRight: 4,
    height: 16,
  },
  '& span': {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 13,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova Lt',
    fontWeight: 600,
    position: 'relative',
    marginRight: theme.spacing(4),
    '&::after': {
      content: "''",
      position: 'absolute',
      left: 0,
      bottom: -8,
      width: '100%',
      height: 2,
      backgroundColor: 'transparent',
    },
    '&.active': {
      '&::after': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    // height: '100%',
    // maxHeight: '100%',
  },
  navList: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  rootPadding: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export const MaterialAdminPage: React.FC<{ type: TMaterialCategoryType }> = ({
  type,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [favGroup, setFavGroup] = useState();
  const [showGroupDrawer, setShowGroupDrawer] = useState(false);
  const { t } = useTranslation();

  const [showFileDrawer, setShowFileDrawer] = useState(false);
  const [editFileDrawerFileId, setEditFileDrawerFileId] =
    useState<IMaterial | null>(null);
  const [showCategoryGroupDrawer, setShowCategoryGroupDrawer] = useState(false);
  // const [drawerContentType, setDrawerContentType] = useState<TMaterialDrawerType>('category');
  const sortUpdate = useMutation('sort', sort.update);
  const materialUpdate = useMutation('material', material.item.update);

  const [search, setSearch] = useState<string | null | undefined>(null);
  const [isSearch, setIsSearch] = useState<bool>(false);

  useQuery(['materials', type, search, isSearch], () => material.item.list({ type, search }), {
    refetchOnMount: true,
    suspense: false,
    onSuccess: (data) => {
      let newData = data.categories;
      const categoriesKeys = Object.keys(newData.items);
      if (newData.sort.length === 0 && categoriesKeys.length > 0) {
        newData.sort = categoriesKeys;
      }
      for (let category of categoriesKeys) {
        if (category in newData.items) {
          const groupKeys = Object.keys(newData.items[category].groups.items);
          const groupSort = newData.items[category].groups.sort;
          if (groupSort.length < groupKeys.length) {
            newData.items[category].groups.sort = groupKeys;
          }
          for (let group of groupKeys) {
            const materialsSort =
              newData.items[category].groups.items[group].materials.sort;
            const materialsKeys =
              newData.items[category].groups.items[group].materials.items &&
              Object.keys(
                newData.items[category].groups.items[group].materials.items
              );
            if (materialsKeys && materialsSort.length < materialsKeys.length) {
              newData.items[category].groups.items[group].materials.sort =
                materialsKeys;
            }
          }
        }
      }
      setCategories(newData);
      const currentCat = Object.values(newData.items)[0];
      setCurrentCategory(currentCat);
      const favGroup =
        currentCat &&
        Object.values(currentCat.groups.items).find(
          (group) => group.isReserved
        );
      if (favGroup) {
        setFavGroup(favGroup);
      } else {
        setFavGroup();
      }
    },
  });

  const materialDelete = useMutation('materials', deleteApi.material);

  const updateFavGroup = (category) => {
    const favGroup = Object.values(category.groups.items).find(
      (group) => group.isReserved
    );
    if (favGroup) {
      setFavGroup(favGroup);
    } else {
      setFavGroup();
    }
  };
  const updateCategory = (newCategory: any) => {
    if (!categories) return;
    let newCategories = { ...categories };
    newCategories.items[newCategory.id] = newCategory;
    setCategories(newCategories);
  };
  const handleGroupDrop = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!categories) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newGroupsSort = [...currentCategory.groups.sort];
    newGroupsSort.splice(source.index, 1);
    newGroupsSort.splice(destination.index, 0, draggableId);
    const newCurrentCategory = {
      ...currentCategory,
      groups: {
        ...currentCategory.groups,
        sort: newGroupsSort,
      },
    };
    setCurrentCategory(newCurrentCategory);
    updateCategory(newCurrentCategory);
    sortUpdate.mutate({
      entity: currentCategory.groups.entity,
      ids: newGroupsSort,
    });
  };

  const handleMaterialDelete = (materialId: string, groupId: string) => {
    const materialParentGroup = currentCategory.groups.items[groupId];

    const newSort = [...materialParentGroup.materials.sort].filter(
      (id) => id !== materialId
    );
    const newCurrentCategory = {
      ...currentCategory,
      groups: {
        ...currentCategory.groups,
        items: {
          ...currentCategory.groups.items,
          [materialParentGroup.id]: {
            ...materialParentGroup,
            materials: {
              ...materialParentGroup.materials,
              sort: newSort,
            },
          },
        },
      },
    };
    setCurrentCategory(newCurrentCategory);
    updateCategory(newCurrentCategory);
    materialDelete.mutate(materialId, {
      onSuccess: () => {
        sortUpdate.mutate({
          ids: newSort,
          entity: materialParentGroup.materials.entity,
        });
      },
    });
  };

  const handleDropCategory = ({
    destination,
    source,
    draggableId,
  }: DropResult) => {
    if (!categories) return;
    if (!destination || destination.droppableId !== 'categories') {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newCategoriesSort = [...categories.sort];
    newCategoriesSort.splice(source.index, 1);
    newCategoriesSort.splice(destination.index, 0, draggableId);
    setCategories({
      ...categories,
      sort: newCategoriesSort,
    });
    sortUpdate.mutate({
      entity: categories.entity,
      ids: newCategoriesSort,
    });
  };

  const handleDropFileBetweenGroups = (result: DropResult) => {
    const { destination, source } = result;
    if (!currentCategory) return;
    if (!destination) {
      return;
    }
    const sourceGroup = currentCategory.groups.items[source.droppableId];
    const destGroup = currentCategory.groups.items[destination.droppableId];
    let newSourceSort = [...sourceGroup.materials.sort];
    newSourceSort.splice(source.index, 1);
    newSourceSort.splice(source.index, 0);

    let newDestGroupSort = [...destGroup.materials.sort];
    newDestGroupSort.splice(
      destination.index,
      0,
      sourceGroup.materials.sort[source.index]
    );
    let newDestMaterials = {
      ...destGroup.materials.items,
      [sourceGroup.materials.sort[source.index]]:
        sourceGroup.materials.items[sourceGroup.materials.sort[source.index]],
    };
    let newSourceMaterials = { ...sourceGroup.materials.items };
    delete newSourceMaterials[sourceGroup.materials.sort[source.index]];
    let newCategory = {
      ...currentCategory,
      groups: {
        ...currentCategory.groups,
        items: {
          ...currentCategory.groups,
          [destination.droppableId]: {
            ...destGroup,
            materials: {
              ...destGroup.materials,
              items: newDestMaterials,
              sort: newDestGroupSort,
            },
          },
          [source.droppableId]: {
            ...sourceGroup,
            materials: {
              ...sourceGroup.materials,
              sort: newSourceSort,
              items: newSourceMaterials,
            },
          },
        },
      },
    };
    setCurrentCategory(newCategory);
    sortUpdate.mutate({
      entity: sourceGroup.materials.entity,
      ids: newSourceSort,
    });
    sortUpdate.mutate({
      entity: destGroup.materials.entity,
      ids: newDestGroupSort,
    });
    materialUpdate.mutate({
      id: sourceGroup.materials.sort[source.index],
      group: destGroup.id,
    });
    updateCategory(newCategory);
    updateFavGroup(newCategory);
  };

  const handleDropFileInsideGroup = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    const group = currentCategory.groups.items[source.droppableId];
    let newGroupSort = [...group.materials.sort];
    newGroupSort.splice(source.index, 1);
    newGroupSort.splice(destination.index, 0, draggableId);
    const newCategory = {
      ...currentCategory,
      groups: {
        ...currentCategory.groups,
        items: {
          ...currentCategory.groups.items,
          [group.id]: {
            ...group,
            materials: {
              ...group.materials,
              sort: newGroupSort,
            },
          },
        },
      },
    };
    if (favGroup && group.id === favGroup.id) {
      setFavGroup({
        ...group,
        materials: {
          ...group.materials,
          sort: newGroupSort,
        },
      });
    }
    setCurrentCategory(newCategory);
    sortUpdate.mutate({ entity: group.materials.entity, ids: newGroupSort });
    updateCategory(newCategory);
  };

  const handleDropGroup = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === 'categories') {
      handleDropCategory(result);
      return;
    }
    if (destination.droppableId === source.droppableId) {
      handleDropFileInsideGroup(result);
      return;
    }
    if (destination.droppableId !== source.droppableId) {
      handleDropFileBetweenGroups(result);
      return;
    }
  };

  const handleChangeCategory = (cat) => {
    setCurrentCategory(cat);
    const favGroup = Object.values(cat.groups.items).find(
      (group) => group.isReserved
    );
    if (favGroup) {
      setFavGroup(favGroup);
    } else {
      setFavGroup();
    }
  };

  const [viewType, setViewType] = useState(localStorage.getItem('material-view-type'));
  const handleViewType = (type) => {
    localStorage.setItem('material-view-type', type);
    setViewType(type);
  }

  const serachInput = useRef();
  const onClickSerach = () => {
    const input = serachInput?.current && serachInput.current.querySelector('input[type="text"]');
    setSearch((input && input.value) || "");
  }
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 200), []);

  return (
    <Grid container item direction='column' className={classes.root}>
      <Box className={classes.rootPadding}>
        <Grid
          item
          container
          className={classes.navList}
          justifyContent='space-between'
        >
          <Box display='flex' flexDirection='row' alignItems='flex-end'>
            <StyledNavLink to='/materials/sales' exact>
              <Typography variant='h5'>
                {t('page.material.type.sales')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/tech' exact>
              <Typography variant='h5'>
                {t('page.material.type.tech')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/ads' exact>
              <Typography variant='h5'>
                {t('page.material.type.ads')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/partnership' exact>
              <Typography variant='h5'>
                {t('page.material.type.partnership')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/cases' exact>
              <Typography variant='h5'>
                {t('page.material.type.cases')}
              </Typography>
            </StyledNavLink>
            <Box mb={1}>
              <StyledSearchInput
                ref={serachInput}
                className='search search-materials'
                placeholder={t('words.search')}
                endAdornment={<SearchIcon onClick={e => {
                  onClickSerach(e);
                  setIsSearch(p => !p);
                }} />}
                onChange={debouncedChangeHandler}
              />
            </Box>
          </Box>
          <Box mb={1}>
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <div>
                <IconButton style={{ paddingRight: "5px" }} title="Плиточный вид" onClick={() => handleViewType('cell')}>
                  <TableCellBinIcon style={{ fontSize: 16, color: ((viewType === 'cell' || !viewType) ? '#E7636B' : '#FFFFFF') }} />
                </IconButton>
                <IconButton style={{ paddingLeft: "5px" }} title="Табличный вид" onClick={() => handleViewType('table')}>
                  <TableBinIcon style={{ fontSize: 16, color: (viewType === 'table' ? '#E7636B' : '#FFFFFF') }} />
                </IconButton>
              </div>
              <AppWhiteButton
                style={{ textTransform: 'none' }}
                onClick={() => setShowFileDrawer(true)}
              >
                {t('page.material.addFile')}
              </AppWhiteButton>
            </div>
          </Box>
        </Grid>
      </Box>
      <Paper
        square
        style={{
          flex: '1 1 100vh',
          minHeight: '100vh',
        }}
      >
        <Box p={4}>
          <DragDropContext onDragEnd={handleDropGroup}>
            <Droppable droppableId='categories' direction='horizontal'>
              {(providedCats) => (
                <Grid
                  innerRef={providedCats.innerRef}
                  {...providedCats.droppableProps}
                  wrap='nowrap'
                  container
                  item
                  direction='row'
                >
                  <>
                    {categories &&
                      categories.sort.map((c, index) => {
                        const cat = categories.items[c];
                        if (!cat) return null;
                        return (
                          <Draggable
                            key={cat.id}
                            draggableId={cat.id}
                            index={index}
                          >
                            {(dragProps, snapshot) => (
                              <CategoriesHeading
                                {...dragProps.dragHandleProps}
                                {...dragProps.draggableProps}
                                innerRef={dragProps.innerRef}
                                className={
                                  snapshot.isDragging ? 'dragging' : ''
                                }
                                onClick={() => handleChangeCategory(cat)}
                                key={cat.id}
                              >
                                <TwoLinesIcon style={{ fontSize: 4 }} />
                                <span
                                  className={
                                    currentCategory?.id === cat.id
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  {cat.title}
                                </span>
                              </CategoriesHeading>
                            )}
                          </Draggable>
                        );
                      })}
                    {providedCats.placeholder}
                    <AppButton
                      onClick={() => setShowCategoryGroupDrawer(true)}
                      size='small'
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                      color='primary'
                      variant='text'
                      startIcon={<PenIcon style={{ fontSize: 16 }} />}
                    >
                      {t('page.material.editCategories')}
                    </AppButton>
                  </>
                </Grid>
              )}
            </Droppable>

            {viewType === 'table' && <Grid container spacing={4} direction='row' style={{ marginTop: 1, marginBottom: 1, fontSize: 12 }}>
              <Grid item xs={4}><b>{t('page.material.table.name')}</b></Grid>
              <Grid item xs={2}><b>{t('page.material.table.size')}</b></Grid>
              <Grid item xs={2}><b>{t('page.material.table.createdAt')}</b></Grid>
              <Grid item xs={2}><b>{t('page.material.table.author')}</b></Grid>
              <Grid item xs={1}><b>{t('page.material.table.isActual')}</b></Grid>
              <Grid item xs={1}></Grid>
            </Grid>}

            {favGroup && (
              <Droppable
                droppableId={favGroup.id}
                key={'favGroup'}
                direction='horizontal'
              >
                {(groupProvided) => (
                  <RootRef
                    rootRef={groupProvided.innerRef}
                    {...groupProvided.droppableProps}
                  >
                    <Grid item container direction='row'>
                      {favGroup.materials.sort?.map((s: any, index: number) => {
                        //@ts-ignore
                        const itemKeys = Object.keys(favGroup?.materials?.items || {});
                        const material = (itemKeys.indexOf(s) >= 0) && favGroup.materials.items[s];
                        if (!material) return null;
                        return (
                          <Draggable
                            key={material.id}
                            draggableId={material.id}
                            index={index}
                          >
                            {(props, snapshot) => (
                              <DraggableFileContainer
                                className={
                                  snapshot.isDragging
                                    ? 'dragging margin'
                                    : 'margin'
                                }
                                viewType={viewType}
                                ref={props.innerRef}
                                {...props.draggableProps}
                                {...props.dragHandleProps}
                              >

                                {viewType !== 'table' && <TwoLinesIcon className='two-lines' />}

                                {material.type === 'file' && (
                                  <FileCard
                                    onEdit={() => {
                                      setEditFileDrawerFileId(material);
                                    }}
                                    fileName={material.name}
                                    onLink={
                                      material.publicId &&
                                      (() => {
                                        window
                                          .open(
                                            `/api/public/material/${material.publicId}`,
                                            '_blank'
                                          )
                                          .focus();
                                      })
                                    }
                                    file={material.file}
                                    onDelete={() =>
                                      handleMaterialDelete(
                                        material.id,
                                        favGroup.id
                                      )
                                    }
                                    viewType={viewType}
                                    isActual={material?.isActual}
                                    author={material?.author}
                                  />
                                )}
                                {material.type === 'url' && (
                                  <FileBlankLinkCard
                                    material={material}
                                    fileName={material.name}
                                    onEdit={() => {
                                      setEditFileDrawerFileId(material);
                                    }}
                                    onLink={
                                      material.publicId &&
                                      (() => {
                                        window
                                          .open(
                                            `/api/public/material/${material.publicId}`,
                                            '_blank'
                                          )
                                          .focus();
                                      })
                                    }
                                    onDelete={() =>
                                      handleMaterialDelete(
                                        material.id,
                                        favGroup.id
                                      )
                                    }
                                    viewType={viewType}
                                    isActual={material?.isActual}
                                    author={material?.author}
                                  />
                                )}
                              </DraggableFileContainer>
                            )}
                          </Draggable>
                        );
                      })}
                      {groupProvided.placeholder}
                    </Grid>
                  </RootRef>
                )}
              </Droppable>
            )}
            <Grid item container direction='row' alignItems='center'>
              <Grid item lg>
                <Divider />
              </Grid>
              <AppButton
                onClick={() => setShowGroupDrawer(true)}
                size='small'
                style={{ paddingLeft: 20, paddingRight: 20 }}
                color='primary'
                variant='text'
                startIcon={<PenIcon style={{ fontSize: 16 }} />}
              >
                {t('page.material.editGroups')}
              </AppButton>
            </Grid>
            <Grid item container direction='row'>
              {currentCategory &&
                currentCategory.groups.sort.map((g, index) => {
                  const group = currentCategory.groups.items[g];
                  if (!group) return null;
                  if (group.isReserved) return null;
                  if (group.materials.sort.length === 0) return null;
                  return (
                    <Droppable droppableId={group.id} key={group.id}>
                      {(groupProvided) => (
                        <RootRef
                          rootRef={groupProvided.innerRef}
                          {...groupProvided.droppableProps}
                        >
                          <Box
                            display={viewType === 'table' ? 'block' : 'flex'}
                            flexDirection='column'
                            mr={viewType === 'table' ? 0 : 3}
                            style={viewType === 'table' ? { width: "100%" } : {}}
                          >
                            <Box mb={viewType === 'table' ? 0 : 3} mt={viewType === 'table' ? 3 : 0}>
                              <Typography variant='h4'>
                                {group.title}
                              </Typography>
                            </Box>

                            {group.materials.sort.map(
                              (s: any, index: number) => {
                                const material = group.materials.items[s];
                                if (!material) return null;
                                return (
                                  <Draggable
                                    key={material.id}
                                    draggableId={material.id}
                                    index={index}
                                  >
                                    {(props, snapshot) => (
                                      <DraggableFileContainer
                                        className={
                                          snapshot.isDragging ? 'dragging' : ''
                                        }
                                        viewType={viewType}
                                        ref={props.innerRef}
                                        {...props.draggableProps}
                                        {...props.dragHandleProps}
                                      >
                                        {viewType !== 'table' && <TwoLinesIcon className='two-lines' />}
                                        {material.type === 'file' && (
                                          <FileCard
                                            onEdit={() => {
                                              setEditFileDrawerFileId(material);
                                            }}
                                            fileName={material.name}
                                            onLink={
                                              material.publicId &&
                                              (() => {
                                                window
                                                  .open(
                                                    `/api/public/material/${material.publicId}`,
                                                    '_blank'
                                                  )
                                                  .focus();
                                              })
                                            }
                                            file={material.file}
                                            onDelete={() =>
                                              handleMaterialDelete(
                                                material.id,
                                                favGroup.id
                                              )
                                            }
                                            viewType={viewType}
                                            isActual={material?.isActual}
                                            author={material?.author}
                                          />
                                        )}
                                        {material.type === 'url' && (
                                          <FileBlankLinkCard
                                            material={material}
                                            fileName={material.name}
                                            onEdit={() => {
                                              setEditFileDrawerFileId(material);
                                            }}
                                            onLink={
                                              material.publicId &&
                                              (() => {
                                                window
                                                  .open(
                                                    `/api/public/material/${material.publicId}`,
                                                    '_blank'
                                                  )
                                                  .focus();
                                              })
                                            }
                                            onDelete={() =>
                                              handleMaterialDelete(
                                                material.id,
                                                favGroup.id
                                              )
                                            }
                                            viewType={viewType}
                                            isActual={material?.isActual}
                                            author={material?.author}
                                          />
                                        )}
                                      </DraggableFileContainer>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                            {groupProvided.placeholder}
                          </Box>
                        </RootRef>
                      )}
                    </Droppable>
                  );
                })}
            </Grid>
          </DragDropContext>

          <MaterialFileDrawer
            open={showFileDrawer}
            onClose={() => setShowFileDrawer(false)}
          />

          {editFileDrawerFileId && (
            <MaterialEditFileDrawer
              open={editFileDrawerFileId !== null}
              onClose={() => setEditFileDrawerFileId(null)}
              initialMaterial={editFileDrawerFileId}
            />
          )}

          <MaterialCategoryDrawer
            type={type}
            open={showCategoryGroupDrawer}
            handleCategoryDrop={handleDropCategory}
            onClose={() => setShowCategoryGroupDrawer(false)}
            categories={categories}
          />
          {currentCategory && (
            <MaterialGroupDrawer
              type={type}
              currentCategory={currentCategory}
              open={showGroupDrawer}
              groups={currentCategory.groups}
              onClose={() => setShowGroupDrawer(false)}
              categoryId={currentCategory.id}
              handleGroupDrop={handleGroupDrop}
            />
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default MaterialAdminPage;
