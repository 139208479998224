import NumberFormat from 'react-number-format';
import i18n from './../i18n';
export function formatPhoneNumber(phone: string) {
  if (phone === '') {
    return '-';
  }

  return '+' + phone
  // return (
  //   <NumberFormat
  //     value={phone}
  //     displayType='text'
  //     mask='_'
  //     format={i18n.t('phoneDisplayMask')}
  //   />
  // );
}

export function phoneToValid(phone: string) {
  return phone.replace(/[^0-9]+/gi, '');
}
