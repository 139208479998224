import {
  Box,
  debounce,
  Grid,
  Hidden,
  IconButton,
  Input,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import NewsCreatePage from '../pages/news/create';
import NewsMainPage from '../pages/news/all';
import NewsViewPage from '../pages/news/view';
import NewsPage from '../pages/news/news';
import EventsPage from '../pages/news/events';
import AppButton, { AppWhiteLinkButton } from '../components/core/buttons';
import PenIcon from '../components/core/icons/pen';
import { useAppSelector } from '../hooks/useStore';
import { EUserRole } from '../types/user.interface';
import SearchIcon from '../components/core/icons/search';
import { useTranslation } from 'react-i18next';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  padding: '8px 16px',
  textDecoration: 'none',
  '&.active': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#fff',
    '& svg': {
      color: '#fff',
      fontSize: '16px!important',
    },
  },
  navContainer: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  editBtnRoot: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const StyledSearchInput = styled(Input)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  color: '#fff',
  cursor: 'pointer',
  width: 171,
  fontSize: 13,
  lineHeight: 1.3,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  marginRight: 24,
  transition: 'all 200ms',
  '&.Mui-focused': {
    borderBottomColor: '#fff',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
});

export const NewsRoutes: React.FC = () => {
  const classes = useStyles();
  const route = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [search, setSearch] = useState('');
  const { profile } = useAppSelector((state) => state.user);
  const allPage = useRouteMatch('/news/all');
  const eventsPage = useRouteMatch('/news/events');
  const newsPage = useRouteMatch('/news/');
  const { t } = useTranslation();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  return (
    <>
      {!(
        route.pathname.includes('news/create') ||
        route.pathname.includes('news/update')
      ) && (
        <Grid
          item
          container
          direction='row'
          wrap='nowrap'
          justify='space-between'
        >
          <Box
            className={classes.navContainer}
            flexDirection='row'
            display='flex'
          >
            <StyledNavLink to='/news' exact>
              <Typography variant='h5'>{t('page.news.allNews')}</Typography>
            </StyledNavLink>
            <StyledNavLink to='/news/all'>
              <Typography variant='h5'>{t('page.news.onlyNews')}</Typography>
            </StyledNavLink>
            <StyledNavLink to='/news/events'>
              <Typography variant='h5'>{t('page.news.onlyEvents')}</Typography>
            </StyledNavLink>
          </Box>
          {profile &&
          profile.role === EUserRole.admin &&
          (allPage || (newsPage && newsPage.isExact) || eventsPage) ? (
            isEditing ? (
              <Box mr={4} mt={2}>
                <StyledSearchInput
                  style={{
                    width: searchFocus ? 289 : 171,
                  }}
                  onChange={debouncedChangeHandler}
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => setSearchFocus(false)}
                  placeholder={t('words.search')}
                  endAdornment={<SearchIcon />}
                />
                <AppWhiteLinkButton to='/news/create'>
                  {t('page.news.addNews')}
                </AppWhiteLinkButton>
              </Box>
            ) : (
              <Box className={classes.editBtnRoot}>
                <Hidden xsDown>
                  <AppButton
                    className={classes.btn}
                    onClick={() => setIsEditing(true)}
                    variant='text'
                    startIcon={<PenIcon />}
                  >
                    {t('button.edit')}
                  </AppButton>
                </Hidden>
                <Hidden xsUp>
                  <IconButton
                    className={classes.btn}
                    onClick={() => setIsEditing(true)}
                  >
                    <PenIcon />
                  </IconButton>
                </Hidden>
              </Box>
            )
          ) : null}
        </Grid>
      )}
      <Switch>
        <Route
          path='/news'
          render={() => <NewsMainPage search={search} isEditing={isEditing} />}
          exact
        />
        <Route path='/news/update/:id' component={NewsCreatePage} />
        <Route
          path='/news/all'
          render={() => <NewsPage isEditing={isEditing} />}
          exact
        />
        <Route
          path='/news/events'
          render={() => <EventsPage isEditing={isEditing} />}
          exact
        />
        <Route path='/news/create' component={NewsCreatePage} exact />
        <Route path='/news/:id' component={NewsViewPage} />
        <Route path='*'>
          <Redirect to='404' />
        </Route>
      </Switch>
    </>
  );
};

export default NewsRoutes;
