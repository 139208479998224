import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import news from '../../api/news';
import EventsList from '../../components/lists/eventsList';
import MainLoader from '../../components/mainLoader';
import { ENewsType, IEvent } from '../../types/news.interface';
import { useTranslation } from 'react-i18next';

export const EventsPage: React.FC<{ isEditing?: boolean }> = ({
  isEditing,
}) => {
  const { t } = useTranslation();
  const eventsQuery = useQuery(['news', ENewsType.events], () =>
    news.list<Array<IEvent>>(ENewsType.events)
  );
  if (eventsQuery.isLoading) {
    return <MainLoader />;
  }
  return (
    <Paper
      square
      style={{
        flex: '1 1 100vh',
        minHeight: '100vh',
      }}
    >
      <Grid item container md={9}>
        <Box ml={4} mt={5} width='100%'>
          {eventsQuery.data && eventsQuery.data.length > 0 ? (
            <EventsList isEditing={isEditing} items={eventsQuery.data} />
          ) : (
            <Typography variant='h2'>{t('page.news.haveNoEvents')}</Typography>
          )}
        </Box>
      </Grid>
    </Paper>
  );
};

export default EventsPage;
