import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { getMonth } from 'date-fns';
import React, { useState } from 'react';
import { IPartnersStats } from '../../types/partner.interface';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  statsColumn: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-star',
    alignItems: 'flex-end',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    '&:hover': {
      '& span': {
        display: 'block',
      },
      '& .column': {
        background: '#7891F8',
      },
    },
    '& .column': {
      background: theme.palette.info.main,
    },
    '& span': {
      display: 'none',
      position: 'absolute',
      bottom: -20,
      color: theme.palette.primary.main,
    },
  },
  statsNumber: {
    fontSize: 36,
    marginRight: 4,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  statsNumberSmall: {
    fontSize: 30,
    marginRight: 4,
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
  statsTest: {
    fontSize: 13,
    lineHeight: 1.2,
  },
}));

export const PartnersStats: React.FC<{ stats: IPartnersStats }> = ({
  stats,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentProjectStats, setCurrentProjectStats] = useState<
    number | undefined
  >(
    stats.projects.find((pr) => pr.month - 1 === getMonth(new Date()))
      ?.number || stats.projects[0].number
  );
  const [currentPartnerStats, setCurrentPartnerStats] = useState<
    number | undefined
  >(
    stats.partners.find((pr) => pr.month - 1 === getMonth(new Date()))
      ?.number || stats.partners[0].number
  );

  const projectsRatio =
    100 / Math.max(...stats.projects.map((sp) => sp.number));
  const partnersRatio =
    100 / Math.max(...stats.partners.map((sp) => sp.number));

  const monthDict: { [key: number]: string } = t('calendar.monthsShort', {
    returnObjects: true,
  });

  return (
    <Grid item container direction='row' wrap='nowrap' justify='flex-start'>
      <Grid item md={2}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          height='100%'
        >
          <Typography color='primary' className={classes.statsNumber}>
            {stats.count}
          </Typography>
          <Typography color='primary' className={classes.statsTest}>
            {t('component.projectStats.pluralPartner')}
            <br />
            {t('component.projectStats.inWork')}
          </Typography>
        </Box>
      </Grid>
      <Grid item container direction='row' md={3}>
        <Box
          display='flex'
          flexDirection='row'
          height='100%'
          mr={2}
          onMouseOut={() =>
            setCurrentPartnerStats(
              stats.partners.find((pr) => pr.month - 1 === getMonth(new Date()))
                ?.number || stats.partners[0].number
            )
          }
        >
          {stats.partners.map((p, index) => (
            <div
              key={`projectstats-${index}`}
              onMouseOver={() =>
                setCurrentPartnerStats(stats.partners[index].number)
              }
              className={classes.statsColumn}
            >
              <div
                className='column'
                style={{
                  width: 8,
                  minHeight: 1,
                  height: `${p.number * partnersRatio}%`,
                }}
              />
              <span>{monthDict[p.month]}</span>
            </div>
          ))}
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          height='100%'
        >
          <Typography color='primary' className={classes.statsNumber}>
            {currentPartnerStats}
          </Typography>
          <Typography color='primary' className={classes.statsTest}>
            {t('component.projectStats.pluralPartner')}
            <br />
            {t('component.projectStats.inMonth')}
          </Typography>
        </Box>
      </Grid>
      <Grid item container direction='row' md={3}>
        <Box
          display='flex'
          flexDirection='row'
          height='100%'
          mr={2}
          onMouseOut={() =>
            setCurrentProjectStats(
              stats.projects.find((pr) => pr.month - 1 === getMonth(new Date()))
                ?.number || stats.projects[0].number
            )
          }
        >
          {stats.projects.map((p, index) => (
            <div
              key={`projectstats-${index}`}
              onMouseOver={() =>
                setCurrentProjectStats(stats.projects[index].number)
              }
              className={classes.statsColumn}
            >
              <div
                className='column'
                style={{
                  width: 8,
                  minHeight: 1,
                  height: `${p.number * projectsRatio}%`,
                }}
              />
              <span>{monthDict[p.month]}</span>
            </div>
          ))}
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          height='100%'
        >
          <Typography color='primary' className={classes.statsNumber}>
            {currentProjectStats}
          </Typography>
          <Typography color='primary' className={classes.statsTest}>
            {t('component.projectStats.pluralContract')}
            <br />
            {t('component.projectStats.inWork')}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          height='100%'
        >
          <Typography
            color='primary'
            className={
              stats.activeAmount.toString().length > 9
                ? classes.statsNumberSmall
                : classes.statsNumber
            }
          >
            <NumberFormat
              thousandSeparator=' '
              value={stats.activeAmount}
              displayType='text'
            />{' '}
            <span>{t('money.currencySign')}</span>
          </Typography>
          <Typography color='primary' className={classes.statsTest}>
            {t('component.projectStats.contractsTotal')}
            <br />
            {t('component.projectStats.inWork')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
