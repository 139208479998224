import { InputProps, styled } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import ShortInput from './short';
import i18n from './../../i18n';

const NumberFormatCustom: React.FC<InputProps> = ({
  inputRef,
  onChange,
  name,
  ...other
}) => {
  return (
    //@ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        //@ts-ignore
        onChange({
          //@ts-ignore
          target: {
            //@ts-ignore
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=' '
      isNumericString
    />
  );
};

const ShortMoneyInput = styled(ShortInput)({
  width: 127,
  '&:after': {
    position: 'absolute',
    left: '100%',
    marginLeft: 8,
    content: `"${i18n.t('money.currencySign')}"`,
    fontFamily: 'Proxima Nova Rg',
    fontSize: 16,
    lineHeight: 1.5,
  },
});

const MoneyInput: React.FC<InputProps> = ({ ...props }) => {
  return (
    <ShortMoneyInput
      {...props}
      //@ts-ignore
      inputComponent={NumberFormatCustom}
    />
  );
};

export default MoneyInput;
