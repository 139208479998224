import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Popper,
  Select,
  styled,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import * as yup from 'yup';
import partner from '../../api/partner';
import { useAppSelector } from '../../hooks/useStore';
import { roleDict } from '../../types/employees.interface';
import IPartner from '../../types/partner.interface';
import IUser, { EUserRole } from '../../types/user.interface';
import { phoneToValid } from '../../utils/etc';
import AppButton from '../core/buttons';
import { AppContextMenu, AppMenuItem } from '../core/contextMenu';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import AppInput from '../core/outlinedInput';
import MaskedPhone from '../inputs/maskedPhone';

const useStyles = makeStyles((theme) => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    cursor: 'pointer',
    transition: 'transform 200ms',
  },
  name: {
    width: '100%',
    marginBottom: 10,
  },
  position: {
    width: '100%',
    marginBottom: 10,
  },
  email: {
    width: '100%',
    marginBottom: 10,
  },
  phone: {
    width: '100%',
    marginBottom: 10,
  },
  role: {
    width: '100%',
    marginBottom: 10,
  },
  partner: {
    width: '100%',
    marginBottom: 10,
  },
  controls: {
    marginRight: 16,
  },
  rootPadding: {
    overflowY: 'scroll',
    overflow: 'scroll',
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(3.5),
    padding: theme.spacing(2),
  },
}));

export const EmployeeCreateForm: React.FC<{
  onSubmit: (user: Omit<IUser, 'id'>) => void;
}> = ({ onSubmit }) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  const popperRef = useRef<HTMLElement>(null);
  const [partnerSearch, setPartnerSearch] = useState('');
  const [searchAnchor, setSearchAnchor] = useState(false);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['partners', 'EmployeeCreateForm'],
    ({ pageParam }) => partner.list(pageParam, {}),
    {
      suspense: false,
      enabled: user.profile?.role === EUserRole.admin || user.profile?.role === EUserRole.partner,
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
    }
  );

  const handlePartnerSearchClick = (partner: IPartner) => {
    setPartnerSearch(partner.name);
    setSearchAnchor(false);
    formik.setFieldValue('partner', partner.id);
  };

  useEffect(() => {
    if (typeof data?.pages == "object") {
      const items: IPartner[] = data.pages.map((page) => page.items).flat();
      if (items.length === 1) {
        handlePartnerSearchClick(items[0]);
      }
    }
  }, [data]);

  const classes = useStyles();
  const StyledSelect = styled(Select)({
    flex: 1,
    alignItems: 'center',
    '& svg': {
      right: 16,
      top: 'calc(50% - 6px)',
    },
    '& .MuiSelect-root': {
      padding: '8px 16px',
      paddingRight: 32,
    },
    '& fieldset': {
      borderColor: '#E8E8E8',
    },
  });

  const formik = useFormik<any>({
    initialValues: {
      fullName: '',
      email: '',
      position: '',
      phone: '',
      role: 'employee' as EUserRole,
      //@ts-ignore
      partner: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      email: yup.string().required(t('validation.required')),
      fullName: yup.string().required(t('validation.required')),
      phone: yup.string().required(t('validation.required')),
      position: yup.string().required(t('validation.required')),
      partner: yup.lazy(() => {
        if (haveToSpecifyPartner) {
          return yup.string().required(t('validation.required'));
        }

        return yup.mixed().notRequired();
      }),
    }),
    onSubmit: (values, helpers) => {
      onSubmit({
        ...values,
        phone: phoneToValid(values.phone),
      });
    },
  });

  const haveToSpecifyPartner = useMemo(() => {
    return [EUserRole.partner, EUserRole.employee].includes(formik.values.role);
  }, [formik.values.role]);

  return (
    <Paper square style={{ width: '100%', overflowY: 'scroll' }}>
      <Box className={classes.rootPadding}>
        <Grid container item direction='row'>
          <Grid item className={classes.name}>
            <AppInput
              defaultValue={formik.initialValues.fullName}
              name='fullName'
              error={!!formik.errors.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t('words.fullNameAbbr')}
            />
          </Grid>
          <Grid item className={classes.position}>
            <AppInput
              defaultValue={formik.initialValues.position}
              name='position'
              error={!!formik.errors.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t('words.position')}
            />
          </Grid>
          <Grid item className={classes.email}>
            <AppInput
              defaultValue={formik.initialValues.email}
              name='email'
              error={!!formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t('words.email')}
            />
          </Grid>
          <Grid item className={classes.phone}>
            <AppInput
              error={!!formik.errors.phone}
              onChange={formik.handleChange}
              name='phone'
              inputComponent={MaskedPhone}
              defaultValue={formik.initialValues.phone}
              onBlur={formik.handleBlur}
              placeholder={t('words.phone')}
            />
          </Grid>

          <Grid item className={classes.role}>
            <StyledSelect
              variant='outlined'
              name='role'
              IconComponent={(props) => <FieldArrowDown {...props} />}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.role}
              fullWidth
              //@ts-ignore
              renderValue={(value) => roleDict[value]}
            >
              {user.profile && user.profile.role === EUserRole.admin ? (
                <AppMenuItem value='admin'>{t('role.admin')}</AppMenuItem>
              ) : null}

              {user.profile && user.profile.role === EUserRole.admin ? (
                <AppMenuItem value='customer'>{t('role.customer')}</AppMenuItem>
              ) : null}

              {(user.profile && user.profile.role === EUserRole.admin && false) ? (
                <AppMenuItem value='partner_admin'>
                  {t('role.partner_admin')}
                </AppMenuItem>
              ) : null}

              {user.profile && user.profile.role === EUserRole.admin ? (
                <AppMenuItem value='documentation_admin'>
                  {t('role.documentation_admin')}
                </AppMenuItem>
              ) : null}

              {(user.profile && user.profile.role === EUserRole.admin && false) ? (
                <AppMenuItem value='end_customer'>
                  {t('role.end_customer')}
                </AppMenuItem>
              ) : null}

              {user.profile && user.profile.role === EUserRole.admin ? (
                <AppMenuItem value='internal_team'>
                  {t('role.internal_team')}
                </AppMenuItem>
              ) : null}

              {user.profile && user.profile.role !== EUserRole.employee ? (
                <AppMenuItem value='partner'>{t('role.partner')}</AppMenuItem>
              ) : null}

              <AppMenuItem value='employee'>{t('role.employee')}</AppMenuItem>
            </StyledSelect>
          </Grid>

          {haveToSpecifyPartner ? (
            <>
              <Grid item style={{ marginRight: 15 }}>
                <AppInput
                  error={!!formik.errors.partner}
                  innerRef={popperRef}
                  //@ts-ignore
                  value={partnerSearch}
                  inputProps={{
                    style: { cursor: 'pointer' },
                  }}
                  contentEditable={false}
                  endAdornment={
                    <FieldArrowDown
                      style={{
                        transform: searchAnchor
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                      onClick={() => setSearchAnchor((s) => !s)}
                      className={classes.smallArrow}
                    />
                  }
                  onFocus={(e) => setSearchAnchor(true)}
                />
                <Popper
                  placement='bottom-start'
                  anchorEl={popperRef?.current}
                  open={searchAnchor}
                  style={{ zIndex: 99 }}
                >
                  <Paper square>
                    <AppContextMenu>
                      <InfiniteScroll
                        loader={null}
                        style={{ maxHeight: 232 }}
                        dataLength={
                          data?.pages
                            ? data.pages.map((page) => page.items).flat().length
                            : 0
                        }
                        hasMore={!!hasNextPage}
                        next={() => {
                          setPage((p) => p + 1);
                          fetchNextPage({
                            pageParam: page,
                          });
                        }}
                      >
                        {!isLoading &&
                          data?.pages &&
                          data.pages
                            .map((page) => page.items)
                            .flat()
                            .map((i) => (
                              <AppMenuItem
                                style={{ minWidth: 200 }}
                                value={i.id}
                                key={i.id}
                                onClick={() => handlePartnerSearchClick(i)}
                              >
                                {i.name}
                              </AppMenuItem>
                            ))}
                      </InfiniteScroll>
                    </AppContextMenu>
                  </Paper>
                </Popper>
              </Grid>
            </>
          ) : null}

          <Grid item className={classes.controls}>
            <AppButton
              variant='contained'
              color='primary'
              onClick={formik.submitForm}
            >
              {t('button.save')}
            </AppButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EmployeeCreateForm;
