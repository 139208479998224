//@ts-nocheck
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import material from '../../api/material';
import { AppWhiteButton } from '../../components/core/buttons';
import StyledNavLink from '../../components/core/styledNavLink';
import FileBlankLinkCard from '../../components/fileBlankLinkCard';
import FileCard from '../../components/fileCard';
import { MaterialFileDrawer } from '../../components/materialFileDrawer';
import useRbac from '../../hooks/useRbac';
import { TMaterialCategoryType } from '../../types/material.interface';
import TableCellBinIcon from '../../components/core/icons/tableCell';
import TableBinIcon from '../../components/core/icons/table';
import { StyledSearchInput } from '../../components/employeesSearchbar';
import SearchIcon from '../../components/core/icons/search';
import { debounce } from 'lodash';

const DraggableFileContainer = styled('div')(({ theme, viewType }) => {

  if (viewType === 'table') {
    return ({
      display: 'block',
      width: '100%',
      flexDirection: 'row',
      padding: '8px 0px',
      paddingRight: 8,
      alignItems: 'center',
      backgroundColor: '#fff',
      '& .two-lines': {
        fontSize: 4,
        marginRight: 4,
      },
      '&.dragging': {
        boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
        '& .two-lines': {
          opacity: 0,
        },
      },
      '&.margin': {
        marginTop: 1,
        width: '100%',
      },
    })
  }

  return ({
    display: 'inline-flex',
    flexDirection: 'row',
    padding: '8px 0px',
    paddingRight: 0,
    alignItems: 'center',
    backgroundColor: '#fff',
    '& .two-lines': {
      fontSize: 4,
      marginRight: 4,
    },
    '&.dragging': {
      boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
      '& .two-lines': {
        opacity: 0,
      },
    },
    '&.margin': {
      marginTop: 24,
      marginRight: 58,
    },
  })
});

const CategoriesHeading = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#fff',
  '&.dragging': {
    boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
    '& svg': {
      opacity: 0,
    },
    '& .active': {
      borderBottom: `2px solid transparent`,
      paddingBottom: 0,
    },
  },
  '& svg': {
    // paddingTop: 9,
    marginRight: 4,
    height: 16,
  },
  '& span': {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 13,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova Lt',
    fontWeight: 600,
    position: 'relative',
    marginRight: theme.spacing(4),
    '&::after': {
      content: "''",
      position: 'absolute',
      left: 0,
      bottom: -8,
      width: '100%',
      height: 2,
      backgroundColor: 'transparent',
    },
    '&.active': {
      '&::after': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    // height: '100%',
    // maxHeight: '100%',
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  rootPadding: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export const MaterialsMainPage: React.FC<{ type: TMaterialCategoryType }> = ({
  type,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [favGroup, setFavGroup] = useState();
  const { t } = useTranslation();
  const rbac = useRbac();

  const [showFileDrawer, setShowFileDrawer] = useState(false);
  
  const [search, setSearch] = useState<string | null | undefined>(null);
  const [isSearch, setIsSearch] = useState<bool>(false);

  useQuery(['materials', type, search, isSearch], () => material.item.list({ type, search }), {
    refetchOnMount: true,
    suspense: false,
    onSuccess: (data) => {
      let newData = data.categories;
      const categoriesKeys = Object.keys(newData.items);
      if (newData.sort.length === 0 && categoriesKeys.length > 0) {
        newData.sort = categoriesKeys;
        // categoriesItems.map((category) => {
        //   const groupKeys = category.
        //   if(group.sort)
        // })
      }
      for (let category of categoriesKeys) {
        if (category in newData.items) {
          const groupKeys = Object.keys(newData.items[category].groups.items);
          const groupSort = newData.items[category].groups.sort;
          if (groupSort.length < groupKeys.length) {
            newData.items[category].groups.sort = groupKeys;
          }
          for (let group of groupKeys) {
            const materialsSort =
              newData.items[category].groups.items[group].materials.sort;
            const materialsKeys =
              newData.items[category].groups.items[group].materials.items &&
              Object.keys(
                newData.items[category].groups.items[group].materials.items
              );
            if (materialsKeys && materialsSort.length < materialsKeys.length) {
              newData.items[category].groups.items[group].materials.sort =
                materialsKeys;
            }
          }
        }
      }
      setCategories(newData);
      setCategories(newData);
      const currentCat = Object.values(newData.items)[0];
      setCurrentCategory(currentCat);
      const favGroup =
        currentCat &&
        Object.values(currentCat.groups.items).find(
          (group) => group.isReserved
        );
      if (favGroup) {
        setFavGroup(favGroup);
      } else {
        setFavGroup();
      }
    },
  });

  const [viewType, setViewType] = useState(localStorage.getItem('material-view-type'));
  const handleViewType = (type) => {
    localStorage.setItem('material-view-type', type);
    setViewType(type);
  }

  const serachInput = useRef();
  const onClickSerach = () => {
    const input = serachInput?.current && serachInput.current.querySelector('input[type="text"]');
    setSearch((input && input.value) || "");
  }
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 200), []);

  return (
    <Grid container item direction='column' className={classes.root}>
      <Box className={classes.rootPadding}>
        <Grid item container direction='row' justify='space-between'>
          <Box display='flex' flexDirection='row' alignItems='flex-end'>
            <StyledNavLink to='/materials/sales' exact>
              <Typography variant='h5'>
                {t('page.material.type.sales')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/tech' exact>
              <Typography variant='h5'>
                {t('page.material.type.tech')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/ads' exact>
              <Typography variant='h5'>
                {t('page.material.type.ads')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/partnership' exact>
              <Typography variant='h5'>
                {t('page.material.type.partnership')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/materials/cases' exact>
              <Typography variant='h5'>
                {t('page.material.type.cases')}
              </Typography>
            </StyledNavLink>
            <Box mb={1}>
              <StyledSearchInput
                ref={serachInput}
                className='search search-materials'
                placeholder={t('words.search')}
                endAdornment={<SearchIcon onClick={e => {
                  onClickSerach(e);
                  setIsSearch(p => !p);
                }} />}
                onChange={debouncedChangeHandler}
              />
            </Box>
          </Box>
          <Box mb={1}>
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <div>
                <IconButton style={{ paddingRight: "5px" }} title="Плиточный вид" onClick={() => handleViewType('cell')}>
                  <TableCellBinIcon style={{ fontSize: 16, color: ((viewType === 'cell' || !viewType) ? '#E7636B' : '#FFFFFF') }} />
                </IconButton>
                <IconButton style={{ paddingLeft: "5px" }} title="Табличный вид" onClick={() => handleViewType('table')}>
                  <TableBinIcon style={{ fontSize: 16, color: (viewType === 'table' ? '#E7636B' : '#FFFFFF') }} />
                </IconButton>
              </div>
              {rbac.can('material.create') && (
                <AppWhiteButton
                  style={{ textTransform: 'none' }}
                  onClick={() => setShowFileDrawer(true)}
                >
                  {t('page.material.addFile')}
                </AppWhiteButton>
              )}
            </div>
          </Box>
        </Grid>
      </Box>
      <Paper
        square
        style={{
          flex: '1 1 100vh',
          minHeight: '100vh',
        }}
      >
        <Box p={4}>
          <Grid wrap='nowrap' container item direction='row'>
            {categories &&
              categories.sort.map((c, index) => {
                const cat = categories.items[c];
                if (!cat) return null;
                return (
                  <CategoriesHeading
                    onClick={() => setCurrentCategory(cat)}
                    key={cat.id}
                  >
                    <span
                      className={currentCategory?.id === cat.id ? 'active' : ''}
                    >
                      {cat.title}
                    </span>
                  </CategoriesHeading>
                );
              })}
          </Grid>

          {viewType === 'table' && <Grid container spacing={4} direction='row' style={{ marginTop: 1, marginBottom: 1, fontSize: 12 }}>
            <Grid item xs={4}><b>{t('page.material.table.name')}</b></Grid>
            <Grid item xs={2}><b>{t('page.material.table.size')}</b></Grid>
            <Grid item xs={2}><b>{t('page.material.table.createdAt')}</b></Grid>
            <Grid item xs={2}><b>{t('page.material.table.author')}</b></Grid>
            <Grid item xs={1}><b>{t('page.material.table.isActual')}</b></Grid>
            <Grid item xs={1}></Grid>
          </Grid>}

          {favGroup && (
            <Grid item container direction={viewType === 'table' ? 'column' : 'row'}>
              {favGroup.materials.sort?.map((s: any, index: number) => {
                //@ts-ignore
                const itemKeys = Object.keys(favGroup?.materials?.items || {});
                const material = (itemKeys.indexOf(s) >= 0) && favGroup?.materials.items[s];
                if (!material) return null;
                return (
                  <DraggableFileContainer className='margin' viewType={viewType}>
                    {material.type === 'file' && (
                      <FileCard
                        file={material.file}
                        fileName={material.name}
                        onLink={
                          material.publicId &&
                          (() => {
                            window
                              .open(
                                `/api/public/material/${material.publicId}`,
                                '_blank'
                              )
                              .focus();
                          })
                        }
                        viewType={viewType}
                        isActual={material?.isActual}
                        author={material?.author}
                      />
                    )}
                    {material.type === 'url' && (
                      <FileBlankLinkCard
                        fileName={material.name}
                        onLink={
                          material.publicId &&
                          (() => {
                            window
                              .open(
                                `/api/public/material/${material.publicId}`,
                                '_blank'
                              )
                              .focus();
                          })
                        }
                        material={material}
                        viewType={viewType}
                        isActual={material?.isActual}
                        author={material?.author}
                      />
                    )}
                  </DraggableFileContainer>
                );
              })}
            </Grid>
          )}
          <Grid item container direction='row' alignItems='center'>
            <Grid item lg>
              <Box mt={3.5} mb={4}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
          <Grid item container direction='row'>
            {currentCategory &&
              currentCategory.groups.sort.map((g, index) => {
                const group = currentCategory.groups.items[g];
                if (!group) return group;
                if (group.materials.sort.length === 0) return null;

                // todo uncomment later
                if (
                  !group.whoCanRead?.includes(rbac.getUser()?.id) && !rbac.can('material.create')
                ) {
                  return null;
                }

                return (
                  <Box
                    display={viewType === 'table' ? 'block' : 'flex'}
                    flexDirection='column'
                    mr={viewType === 'table' ? 0 : 3}
                    style={viewType === 'table' ? { width: "100%" } : {}}
                  >
                    <Box mb={viewType === 'table' ? 0 : 3} mt={viewType === 'table' ? 3 : 0}>
                      <Typography variant='h4'>{group.title}</Typography>
                    </Box>

                    {group.materials.sort.map((s: any, index: number) => {
                      const material = group.materials.items[s];
                      if (!material) return null;
                      return (
                        <DraggableFileContainer viewType={viewType}>
                          {material.type === 'file' && (
                            <FileCard
                              file={material.file}
                              fileName={material.name}
                              onLink={
                                material.publicId &&
                                (() => {
                                  window
                                    .open(
                                      `/api/public/material/${material.publicId}`,
                                      '_blank'
                                    )
                                    .focus();
                                })
                              }
                              viewType={viewType}
                              isActual={material?.isActual}
                              author={material?.author}
                            />
                          )}
                          {material.type === 'url' && (
                            <FileBlankLinkCard
                              fileName={material.name}
                              onLink={
                                material.publicId &&
                                (() => {
                                  window
                                    .open(
                                      `/api/public/material/${material.publicId}`,
                                      '_blank'
                                    )
                                    .focus();
                                })
                              }
                              material={material}
                              viewType={viewType}
                              isActual={material?.isActual}
                              author={material?.author}
                            />
                          )}
                        </DraggableFileContainer>
                      );
                    })}
                  </Box>
                );
              })}
          </Grid>

          <MaterialFileDrawer
            open={showFileDrawer}
            onClose={() => setShowFileDrawer(false)}
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default MaterialsMainPage;
