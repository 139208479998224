import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CloseIcon: React.FC<SvgIconProps & { strokeColor?: string }> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path d='M4 4L20 20' stroke={props.strokeColor ?? 'black'} />
      <path d='M20 4L4 20' stroke={props.strokeColor ?? 'black'} />
    </SvgIcon>
  );
};

export default CloseIcon;
