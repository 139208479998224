import {
  Box,
  Collapse,
  Drawer,
  Grid,
  makeStyles,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import deleteApi from '../../api/delete';
import material from '../../api/material';
import sort from '../../api/sort';
import {
  IMaterialCategory,
  materialTypeDict,
  TMaterialCategoryType,
} from '../../types/material.interface';
import AppButton from '../core/buttons';
import { AppMenuItem, AppPopover } from '../core/contextMenu';
import CloseIcon from '../core/icons/close';
import { FatDot } from '../core/icons/fatDot';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FormItemRow from '../forms/formRow';
import { GroupItem } from './groupItem';

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 28,
});
const SelectLike = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  paddingRight: 6,
  height: 40,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #E8E8E8',
  boxSizing: 'border-box',
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
    cursor: 'poniter',
    position: 'absolute',
  },
  '&:hover': {
    border: '1px solid #000',
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const useStyles = makeStyles((theme) => ({
  exBtn: {
    padding: 0,
    '& > svg': {
      fontSize: 8,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  saveBtn: {
    padding: '10px 24px',
    marginTop: theme.spacing(3),
  },
  mi: {
    '& .selected': {
      fontWeight: 600,
    },
  },
  firstRow: {
    marginBottom: 16,
  },
  secondRow: {
    marginBottom: 0,
  },
  btn: {
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#fff',
  },
}));

interface GroupFormProps {
  onSave: ({
    value,
    categoryId,
  }: {
    value: string;
    categoryId: string;
  }) => void;
  onCancel: () => void;
  currentCategory: IMaterialCategory;
  currentType: TMaterialCategoryType;
}

const GroupForm: React.FC<GroupFormProps> = ({
  onCancel,
  onSave,
  currentCategory,
  currentType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [categoryAnchor, setCategoryAnchor] = useState<HTMLElement | null>();
  const [type, setType] = useState<TMaterialCategoryType>(currentType);
  const [category, setCategory] = useState<IMaterialCategory | undefined>(
    currentCategory
  );
  const [showCategoryForm] = useState(false);
  const categoryQuery = useQuery(
    ['materialCategory', type],
    () => material.category.list({ type }),
    {
      suspense: false,
      enabled: showCategoryForm,
    }
  );
  return (
    <>
      <Box style={{ backgroundColor: '#EDF0F9' }} py={5} px={3} mt={2}>
        <Box mb={3}>
          <Typography variant='h4'>
            {t('component.materialGroupDrawer.createGroup')}
          </Typography>
        </Box>
        <FormItemRow>
          <AppInputLabel>
            {t('component.materialGroupDrawer.name')}
          </AppInputLabel>
          <AppInput
            style={{ backgroundColor: '#fff' }}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
        </FormItemRow>
        <FormItemRow>
          <AppInputLabel />
          <Box display='flex' flexDirection='row'>
            <Box mr={3}>
              <AppButton
                onClick={() =>
                  category && onSave({ value, categoryId: category.id })
                }
                style={{ backgroundColor: '#fff' }}
                variant='outlined'
                color='primary'
                disabled={!category}
              >
                {t('button.add')}
              </AppButton>
            </Box>
            <AppButton color='primary' onClick={onCancel}>
              {t('button.cancel')}
            </AppButton>
          </Box>
        </FormItemRow>
      </Box>
      <Box mb={3} />
      <FormItemRow>
        <AppInputLabel>
          {t('component.materialGroupDrawer.section')}
        </AppInputLabel>
        <StyledSelect
          variant='outlined'
          IconComponent={(props) => <FieldArrowDown {...props} />}
          value={type}
          onChange={(e: any) => {
            setType(e.target.value);
            setCategory(undefined);
          }}
          //@ts-ignore
          renderValue={(value) => materialTypeDict[value]}
        >
          <AppMenuItem value='sales'>
            {t('page.material.type.sales')}
          </AppMenuItem>
          <AppMenuItem value='tech'>{t('page.material.type.tech')}</AppMenuItem>
          <AppMenuItem value='ads'>{t('page.material.type.ads')}</AppMenuItem>
          <AppMenuItem value='partnership'>
            {t('page.material.type.partnership')}
          </AppMenuItem>
        </StyledSelect>
      </FormItemRow>
      <FormItemRow>
        <AppInputLabel>
          {t('component.materialGroupDrawer.category')}
        </AppInputLabel>
        <SelectLike onClick={(e) => setCategoryAnchor(e.currentTarget)}>
          <Typography>{category?.title}</Typography>
          <FieldArrowDown />
        </SelectLike>
        <AppPopover
          anchorEl={categoryAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={!!categoryAnchor}
          onBackdropClick={() => setCategoryAnchor(null)}
          onClose={() => setCategoryAnchor(null)}
          PaperProps={{
            style: {
              minWidth: 380,
              // height: 230,
              maxHeight: 440,
            },
          }}
        >
          <Box>
            {categoryQuery.data?.items &&
              categoryQuery.data.items.map((item: IMaterialCategory) => (
                <AppMenuItem
                  className={classes.mi}
                  key={item.id}
                  value={item.id}
                  onClick={() => {
                    setCategoryAnchor(null);
                    setCategory(item);
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    flexWrap='nowrap'
                    width='100%'
                  >
                    <Box display='flex' flexDirection='column'>
                      <Typography
                        className={category?.id === item.id ? 'selected' : ''}
                        variant='body1'
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    {category?.id === item.id && <FatDot />}
                  </Box>
                </AppMenuItem>
              ))}
          </Box>
        </AppPopover>
      </FormItemRow>
    </>
  );
};

interface MaterialGroupDrawerProps {
  open: boolean;
  onClose: () => void;
  type: TMaterialCategoryType;
  categoryId: string;
  currentCategory: IMaterialCategory;
  groups: {
    sort: Array<string>;
    items: any;
    entity: string;
  };
  handleGroupDrop: (result: DropResult) => void;
}

export const MaterialGroupDrawer: React.FC<MaterialGroupDrawerProps> = ({
  open,
  onClose,
  groups,
  handleGroupDrop,
  type,
  categoryId,
  currentCategory,
}) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const qc = useQueryClient();
  const { t } = useTranslation();
  const sortUpdate = useMutation('sort', sort.update);
  const groupCreate = useMutation('materialGroup', material.group.create, {
    onSuccess: (data) => {
      qc.invalidateQueries('materialGroup');
      qc.invalidateQueries(['materials', type]);
      sortUpdate.mutate({
        entity: groups.entity,
        ids: [...groups.sort, data.id],
      });
      setShowForm(false);
    },
  });
  const groupUpdate = useMutation('materialGroup', material.group.update, {
    onSuccess: () => {
      //TODO: UPDATE ROOT MATERIALS;
      qc.invalidateQueries('materialGroup');
      qc.invalidateQueries('materials');
      setShowForm(false);
    },
  });
  const categoryDelete = useMutation('materialGroup', deleteApi.materialGroup, {
    onSuccess: (data, variables) => {
      //TODO: UPDATE ROOT MATERIALS;
      qc.invalidateQueries('materials');
      sortUpdate.mutate({
        entity: groups.entity,
        ids: groups.sort.filter((cid) => cid !== variables),
      });
    },
  });
  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 484 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <StyledClose onClick={onClose} />
      <Box mx={3} mt={4}>
        <Typography variant='h3'>
          {t('component.materialGroupDrawer.editGroups')}
        </Typography>

        {!showForm && (
          <Box my={1}>
            <AppButton
              onClick={() => setShowForm(true)}
              className={classes.exBtn}
              color='primary'
              startIcon={<Add style={{ fontSize: 12 }} />}
            >
              {t('button.add')}
            </AppButton>
          </Box>
        )}
        <Collapse in={showForm} timeout={200} unmountOnExit>
          <GroupForm
            currentType={type}
            currentCategory={currentCategory}
            onSave={({ value, categoryId }) =>
              groupCreate.mutate({
                title: value,
                category: categoryId,
              })
            }
            onCancel={() => setShowForm(false)}
          />
        </Collapse>
        <DragDropContext onDragEnd={handleGroupDrop}>
          <Droppable droppableId='categories' direction='vertical'>
            {(provided) => {
              return (
                <Grid
                  item
                  container
                  direction='column'
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {groups.sort.map((cid, index) => {
                    const group = groups.items[cid];
                    if (!group) return null;
                    if (group.isReserved) return null;
                    return (
                      <Draggable
                        key={`drawer-${group.id}`}
                        draggableId={cid}
                        index={index}
                      >
                        {(props, snapshot) => (
                          <div
                            ref={props.innerRef}
                            {...props.dragHandleProps}
                            {...props.draggableProps}
                          >
                            <GroupItem
                              isDragging={snapshot.isDragging}
                              group={group}
                              onEdit={(
                                { title, 
                                  readAccess,
                                  writeAccess,
                                  readAccessPayload,
                                  writeAccessPayload,
                                },
                                id
                              ) =>
                                groupUpdate.mutate({
                                  title,
                                  readAccess,
                                  writeAccess,
                                  readAccessPayload,
                                  writeAccessPayload,
                                  id: id,
                                  category: categoryId,
                                })
                              }
                              onDelete={(id) => categoryDelete.mutate(id)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Grid>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Box>
    </Drawer>
  );
};

export default MaterialGroupDrawer;
