import {
  Paper,
  Typography,
  Box,
  Grid,
  Divider,
  makeStyles,
  Hidden,
} from '@material-ui/core';
import React from 'react';
import { ENewsType, IEvent, INews } from '../../types/news.interface';
import SubsCard from '../../components/subsCard';
import { useQuery } from 'react-query';
import news from '../../api/news';
import { NewsList } from '../../components/lists/newsList';
import EventsList from '../../components/lists/eventsList';
import { Link } from 'react-router-dom';
import { NewsMainGrid } from '../../components/newsMainGrid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Proxima Nova Lt',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

export const NewsMainPage: React.FC<{
  isEditing?: boolean;
  search: string;
}> = ({ isEditing, search }) => {
  const newsQuery = useQuery(
    ['news', ENewsType.news, search],
    () =>
      news.list<{ hasNextPage: boolean; items: Array<INews> }>(
        ENewsType.news,
        1,
        search
      ),
    { suspense: false }
  );
  const eventsQuery = useQuery(
    ['events', ENewsType.events, search],
    () =>
      news.list<{ hasNextPage: boolean; items: Array<IEvent> }>(
        ENewsType.events,
        1,
        search
      ),
    { suspense: false }
  );
  const { t } = useTranslation();

  const classes = useStyles();
  if (
    !newsQuery.isLoading &&
    !eventsQuery.isLoading &&
    !newsQuery.data?.items?.length &&
    !eventsQuery.data?.items?.length
  ) {
    return (
      <Paper
        square
        style={{
          flex: '1 1 100vh',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box p={10} display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='h2'>
            {search
              ? t('page.news.newsNotFound')
              : t('page.news.newsDoesntExists')}
          </Typography>
        </Box>
      </Paper>
    );
  }
  return (
    <Paper
      square
      style={{
        flex: '1 1 100vh',
        minHeight: '100vh',
      }}
    >
      <Box className={classes.container} display='flex'>
        <Grid container item direction='row'>
          <Grid item container direction='column' md={9}>
            {!newsQuery.isLoading &&
              newsQuery.data &&
              newsQuery.data.items?.length > 0 && (
                <NewsMainGrid
                  isEditing={!!isEditing}
                  news1={newsQuery.data.items[0]}
                  news2={newsQuery.data.items[1]}
                  news3={newsQuery.data.items[2]}
                />
              )}
            <Hidden smDown>
              <Grid item>
                <SubsCard />
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item>
                {newsQuery.data && newsQuery.data.items?.length > 3 && (
                  <>
                    <NewsList
                      items={newsQuery.data.items.slice(3)}
                      isEditing={isEditing}
                    />
                    <Link className={classes.link} to='/news/all'>
                      {t('page.news.showAll')}
                    </Link>
                  </>
                )}
              </Grid>
            </Hidden>
          </Grid>
          <Grid item container direction='row' md={3}>
            <Box mx={4}>
              <Divider orientation='vertical' />
            </Box>
            <Grid item container md direction='column'>
              <Typography variant='h4'>{t('page.news.event')}</Typography>
              {eventsQuery.data && (
                <>
                  <EventsList
                    small
                    items={eventsQuery.data.items}
                    isEditing={isEditing}
                  />
                  <Link
                    className={classes.link}
                    to='/news/events'
                    style={{ marginTop: '10px' }}
                  >
                    {t('page.news.showAll')}
                  </Link>
                </>
              )}

              <Box mt={2} mb={2}>
                <Divider />
              </Box>

              <div
                style={{
                  display: 'grid',
                  gridGap: 20,
                  gridTemplateColumns: '50px 50px 50px',
                  justifyItems: 'center',
                  alignContent: 'center',
                  height: 42,
                }}
              >
                <a href={t('socialNetworks.vk')} target='_blank' rel="noreferrer">
                  <img
                    style={{ maxWidth: '100%', height: 40 }}
                    src='/social/vk.svg'
                    alt='vk'
                  />
                </a>

                <a href={t('socialNetworks.telegram')} target='_blank' rel="noreferrer">
                  <img
                    style={{ maxWidth: '100%', height: 40 }}
                    src='/social/telegram.svg'
                    alt='telegram'
                  />
                </a>

                <a href={t('socialNetworks.youtube')} target='_blank' rel="noreferrer">
                  <img
                    style={{ maxWidth: '100%', height: 38 }}
                    src='/social/youtube.png'
                    alt='youtube'
                  />
                </a>
              </div>
            </Grid>
          </Grid>
          <Hidden smUp>
            <SubsCard />
          </Hidden>

          <Hidden smUp>
            <Grid item>
              {newsQuery.data && newsQuery.data.items?.length > 3 && (
                <>
                  <NewsList
                    items={newsQuery.data.items.slice(3)}
                    isEditing={isEditing}
                  />
                  <Link className={classes.link} to='/news/all'>
                    {t('page.news.showAll')}
                  </Link>
                </>
              )}
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Paper>
  );
};

export default NewsMainPage;
