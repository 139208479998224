import { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'moment/locale/ru';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  addMonths,
  addYears,
  startOfQuarter,
  subMonths,
  subYears,
} from 'date-fns';
import { ArrowDown } from '../core/icons/arrowDown';
import AppButton from '../core/buttons';
import { CancelOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const useCalendarStyles = makeStyles((theme) => ({
  captionText: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      // flexBasis: '100%',
      // flexGrow: 1,
    },
  },
  captionTextLeft: {
    textAlign: 'left',
    cursor: 'pointer',
    '& .sm-arrow-left': {
      transform: 'rotate(90deg)',
    },
    [theme.breakpoints.down('sm')]: {
      '& .sm-arrow-left': {
        transform: 'rotate(180deg)',
      },
    },
  },
  captionTextRight: {
    textAlign: 'right',
    cursor: 'pointer',
    '& .sm-arrow-right': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      '& .lg-arrow-right': {
        // transform: 'rotate(90deg)',
        display: 'none',
      },
      '& .sm-arrow-right': {
        display: 'inline-block',
      },
    },
  },
  calendarBox: {
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      flexGrow: 1,
      marginTop: theme.spacing(2),
    },
    '&:first-child': {
      marginRight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
  },
  calendarsRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  calendarRoot: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 400,
    margin: 0,
    marginRight: 0,
    display: 'flex',
    padding: 0,
    fontFamily: 'Proxima Nova Rg',
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)':
      {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    // '& .DayPicker-Day--outside.DayPicker-Day--selected': {
    //   backgroundColor: `transparent!important`,
    //   color: `transparent!important`,
    //   borderRadius: '0 !important',
    // },
    '& .DayPicker-Week': {
      // borderBottom: '4px solid transparent',
      fontSize: 11,
      lineHeight: '15px',
      fontWeight: 400,
    },
    '& .DayPicker-Month': {
      margin: 0,
      fontFamily: 'Proxima Nova Rg',
      fontSize: '13px!important',
      lineHeight: '15px',
      marginRight: 8,
      marginBottom: 20,
    },
    '& .DayPicker-Day': {
      width: 16,
      height: 16,
      padding: 2,
      fontSize: 11,
      lineHeight: '15px',
      fontWeight: 400,

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: `${theme.palette.common.white}!important`,
        borderRadius: '0 !important',
      },
      '&--selected': {
        background: '#EDF0F9!important',
        borderRadius: '0 !important',
      },
      '&--outside': {
        background: 'transparent!important',
        '&:hover': {
          backgroundColor: `transparent!important`,
          color: `transparent!important`,
          borderRadius: '0 !important',
        },
      },
      '&--end:not(&--outside)': {
        borderTopRightRadius: '50% !important',
        borderBottomRightRadius: '50% !important',
        backgroundColor: '#7891F8!important',
      },
      '&--start:not(&--outside)': {
        borderTopLeftRadius: '50% !important',
        borderBottomLeftRadius: '50% !important',
        backgroundColor: '#7891F8!important',
      },
      '&--today:not(&--outside):not(&--start):not(&--end)': {
        backgroundColor: '#E7636B!important',
        color: 'white!important',
        borderRadius: '50%!important',
      },
      '&--today, &--start': {
        borderRadius: '0!important',
        borderTopLeftRadius: '50% !important',
        borderBottomLeftRadius: '50% !important',
      },
    },
    '& .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today': {
      borderRadius: '0 !important',
    },
  },
}));

const headFormat = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  month: 'short',
  year: 'numeric',
});

interface RangeListSelectorProps {
  selected: boolean;
}

const RangeListSelector = withStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 8,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 20,
      fontWeight: 600,
      cursor: 'pointer',
      fontFamily: 'Proxima Nova Lt',
      background: (props: RangeListSelectorProps) =>
        props.selected ? '#7891F8' : '#fff',
      color: (props: RangeListSelectorProps) =>
        props.selected
          ? theme.palette.common.white
          : theme.palette.common.black,
      [theme.breakpoints.down('sm')]: {
        padding: '4px 8px',
        lineHeight: 1.5,
        fontSize: 13,
        fontFamily: 'Proxima Nova Rg',
        fontWeight: 400,
      },
    },
  })
)(Box);

interface DateRangeCalendarProps {
  from?: Date;
  to?: Date;
  onDateSet: ({ from, to }: { from?: Date; to?: Date }) => void;
}

export const DateRangeCalendar: React.FC<DateRangeCalendarProps> = ({
  from,
  to,
  onDateSet,
}) => {
  const [selectionType, setSelectionType] = useState<
    'free' | 'year' | 'month' | 'quarter'
  >('free');
  const { t } = useTranslation();
  const classes = useCalendarStyles();
  const [clicksCount, setClicksCount] = useState(0);
  const [initialMonth, setInitialMonth] = useState<{
    from: Date;
    to: Date;
  }>({
    from: new Date(),
    to: addMonths(new Date(), 1),
  });

  const handleDatePick = (date: Date, type?: 'from' | 'to') => {
    if (selectionType === 'free') {
      if (!from && !to) {
        onDateSet({
          from: date,
          to: to,
        });
      } else if (from && !to) {
        onDateSet({
          from: from,
          to: date,
        });
      } else if (to && !from) {
        onDateSet({
          to: to,
          from: date,
        });
      } else {
        if (clicksCount % 3 === 0) {
          onDateSet({
            to: undefined,
            from: date,
          });
        }
        if (clicksCount % 2 !== 0) {
          onDateSet({
            from: from,
            to: date,
          });
        } else {
          onDateSet({
            to: to,
            from: date,
          });
        }
      }
      setClicksCount((c) => c + 1);
      return;
    }
    if (type) {
      if (type === 'from') {
        onDateSet({
          to: to,
          from: date,
        });
      }
      if (type === 'to') {
        onDateSet({
          from: from,
          to: date,
        });
      }
    }
  };

  const handleInitalMonthClick = (type: 'next' | 'prev') => {
    switch (selectionType) {
      case 'free':
      case 'month':
        setInitialMonth(
          type === 'next'
            ? {
                from: addMonths(initialMonth.from, 1),
                to: addMonths(initialMonth.to, 1),
              }
            : {
                from: subMonths(initialMonth.from, 1),
                to: subMonths(initialMonth.to, 1),
              }
        );
        break;
      case 'year':
        setInitialMonth(
          type === 'next'
            ? {
                from: addYears(initialMonth.from, 1),
                to: addYears(initialMonth.to, 1),
              }
            : {
                from: subYears(initialMonth.from, 1),
                to: subYears(initialMonth.to, 1),
              }
        );
        break;
      case 'quarter':
        setInitialMonth(
          type === 'next'
            ? {
                from: addMonths(initialMonth.from, 3),
                to: addMonths(initialMonth.to, 3),
              }
            : {
                from: subMonths(initialMonth.from, 3),
                to: subMonths(initialMonth.to, 3),
              }
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box p={2} pt={0}>
      <Grid container item direction='column' sm>
        <Grid
          item
          container
          direction='row'
          wrap='nowrap'
          justify='space-between'
        >
          <Hidden smDown>
            <Grid item>
              {/* <Box pt={2}>
              <Typography variant="h4">Проекты</Typography>
            </Box> */}
            </Grid>
          </Hidden>
          <Grid
            item
            container
            direction='row'
            justify='flex-end'
            wrap='nowrap'
            sm={12}
            spacing={1}
          >
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  setSelectionType('month');
                  setInitialMonth((cd) => ({
                    from: cd.from,
                    to: addMonths(cd.from, 1),
                  }));
                }}
                selected={selectionType === 'month'}
              >
                {t('calendar.month')}
              </RangeListSelector>
            </Grid>
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  setSelectionType('quarter');
                  setInitialMonth((cd) => ({
                    from: startOfQuarter(cd.from),
                    to: addMonths(startOfQuarter(cd.from), 2),
                  }));
                }}
                selected={selectionType === 'quarter'}
              >
                {t('calendar.quarter')}
              </RangeListSelector>
            </Grid>
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  setSelectionType('year');
                  setInitialMonth((cd) => ({
                    from: cd.from,
                    to: addYears(cd.from, 1),
                  }));
                }}
                selected={selectionType === 'year'}
              >
                {t('calendar.year')}
              </RangeListSelector>
            </Grid>
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  setSelectionType('free');
                  setInitialMonth((cd) => ({
                    from: cd.from,
                    to: addMonths(cd.from, 1),
                  }));
                }}
                selected={selectionType === 'free'}
              >
                {t('calendar.custom')}
              </RangeListSelector>
            </Grid>
          </Grid>
        </Grid>
        <Box className={classes.calendarsRoot}>
          <Box className={classes.calendarBox}>
            <Typography
              onClick={() => handleInitalMonthClick('prev')}
              className={classes.captionTextLeft}
              variant='subtitle1'
            >
              <ArrowDown
                className='sm-arrow-left'
                style={{ fontSize: 10, marginRight: 8 }}
              />
              {headFormat.format(initialMonth.from)}
            </Typography>
            <DayPicker
              captionElement={(props) => null}
              canChangeMonth={false}
              className={classes.calendarRoot}
              onDayClick={(d) => handleDatePick(d, 'from')}
              month={initialMonth.from}
              modifiers={{ start: from || new Date(), end: to || new Date() }}
              selectedDays={[undefined, { from: from, to: to }]}
              localeUtils={MomentLocaleUtils}
              locale='en'
            />
          </Box>
          <Box className={classes.calendarBox}>
            <Typography
              onClick={() => handleInitalMonthClick('next')}
              className={classes.captionTextRight}
              variant='subtitle1'
            >
              <ArrowDown
                className='sm-arrow-right'
                style={{ fontSize: 10, marginRight: 8 }}
                rotate={'270deg'}
              />
              {headFormat.format(initialMonth.to)}
              <ArrowDown
                className='lg-arrow-right'
                style={{
                  fontSize: 10,
                  transform: 'rotate(270deg)',
                  marginLeft: 8,
                }}
                rotate={'270deg'}
              />
            </Typography>
            <DayPicker
              captionElement={() => null}
              canChangeMonth={false}
              className={classes.calendarRoot}
              onDayClick={(d) => handleDatePick(d, 'to')}
              month={initialMonth.to}
              modifiers={{ start: from || new Date(), end: to || new Date() }}
              selectedDays={[new Date(), { from: from, to: to }]}
              localeUtils={MomentLocaleUtils}
              locale='en'
            />
          </Box>
        </Box>
        <Hidden smDown>
          <Collapse in={!!from} timeout={200}>
            <Grid item container justifyContent='flex-end'>
              <AppButton
                variant='text'
                onClick={() => onDateSet({ from: undefined, to: undefined })}
                color='default'
                startIcon={<CancelOutlined />}
              >
                {t('button.restore')}
              </AppButton>
            </Grid>
          </Collapse>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default DateRangeCalendar;
