import {
  Box,
  Collapse,
  Divider,
  Grid,
  styled,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Add, KeyboardArrowDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import deleteApi from '../../api/delete';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import { AppMenuItem, AppPopover } from '../core/contextMenu';
import PenIcon from '../core/icons/pen';
import AppInput from '../core/outlinedInput';
import { useTranslation } from 'react-i18next';
import employee from '../../api/employee';
import IUser from '../../types/user.interface';
import { UserEmployeeDrawer } from '../userEmployeeDrawer';
import { UserEmployeeForm } from '../forms/userEmployee';

const FatDot = styled('div')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'black',
});

const useStyles = makeStyles((theme) => ({
  rootBox: {
    width: 488,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

interface ManagerSelectProps {
  defaultValue?: IUser;
  partner: string;
  onChange: (value: IUser) => void;
  isAdmin: boolean;
  error?: boolean;
}

export const ManagerUserEmployeeSelect: React.FC<ManagerSelectProps> = ({
  defaultValue,
  onChange,
  partner,
  isAdmin,
  error,
}) => {
  const classes = useStyles();
  const qc = useQueryClient();
  const [showDrawer, setShowDrawer] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ['usersContactEmployee', page],
    ({ pageParam }) =>
      employee.list(pageParam, {
        partner,
        includesMe: true,
      }),
    {
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [partner]);

  const pagesData = data?.pages.map((page) => page.items).flat();
  const createManager = useMutation(
    'usersContactEmployee',
    (manager: Omit<IUser, 'id'>) => employee.create({
      ...manager,
      partner,
    }),
    {
      onSuccess: () => {
        setShowForm(false);
        qc.invalidateQueries('usersContactEmployee');
      },
    }
  );
  const updateManager = useMutation(
    'usersContactEmployee',
    (manager: IUser) => employee.update(manager),
    {
      onSuccess: () => {
        setShowForm(false);
        qc.invalidateQueries('usersContactEmployee');
      },
    }
  );
  const deleteManager = useMutation(
    'usersContactEmployeeDelete',
    (id: string) => deleteApi.user(id),
    {
      onSuccess: () => {
        setShowForm(false);
        qc.invalidateQueries('usersContactEmployee');
      },
    }
  );
  return (
    <>
      <AppInput
        style={{ cursor: 'pointer' }}
        endAdornment={<KeyboardArrowDown />}
        onClick={(e) => setPopoverAnchor(e.currentTarget)}
        readOnly
        value={defaultValue?.fullName ?? ''}
        error={error}
      />
      <AppPopover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
      >
        <Box className={classes.rootBox}>
          <Grid container item direction='column'>
            <Grid item style={{ marginTop: 8 }}>
              {!isAdmin && (
                <AppButton
                  disabled={showForm}
                  style={{ padding: '8px 24px' }}
                  onClick={() => setShowForm(true)}
                  startIcon={<Add />}
                  color='primary'
                >
                  {t('button.add')}
                </AppButton>
              )}
              <AppButton
                disabled={showForm}
                style={{ padding: '8px 24px' }}
                onClick={() => setShowDrawer(true)}
                startIcon={<PenIcon />}
                color='secondary'
              >
                {t('button.edit')}
              </AppButton>
            </Grid>

            {!showForm ? (
              <Box mt={1.5} mb={3}>
                <Divider />
              </Box>
            ) : (
              <Collapse in={showForm} collapsedHeight={0} timeout={300}>
                <Grid item>
                  <Box m={2}>
                    <UserEmployeeForm
                      onCreate={(manager: Omit<IUser, 'id'>) =>
                        createManager.mutate(manager)
                      }
                      onEdit={(manager: IUser) => updateManager.mutate(manager)}
                      onCancel={() => setShowForm(false)}
                    />
                  </Box>
                </Grid>
              </Collapse>
            )}
          </Grid>

          {!(pagesData ?? []).length && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {t('words.noEmployees')}
            </div>
          )}
          <InfiniteScroll
            dataLength={pagesData ? pagesData.length : 0}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={null}
          >
            <Box maxHeight={350}>
              {pagesData &&
                pagesData.map((contact) => (
                  <AppMenuItem
                    key={contact.id}
                    value={contact.id}
                    onClick={() => {
                      setPopoverAnchor(null);
                      onChange(contact);
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      alignItems='center'
                      flexWrap='nowrap'
                      width='100%'
                    >
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='body1'>
                          {contact.fullName}
                        </Typography>
                        <Typography variant='subtitle1'>
                          {formatPhoneNumber(contact.phone)} · {contact.email}
                        </Typography>
                      </Box>
                      {defaultValue?.id === contact.id && <FatDot />}
                    </Box>
                  </AppMenuItem>
                ))}
            </Box>
          </InfiniteScroll>
        </Box>
      </AppPopover>
      <UserEmployeeDrawer
        items={pagesData ? pagesData : []}
        fetchNext={fetchNextPage}
        hasNext={!!hasNextPage}
        open={showDrawer}
        onManagerDelete={(id) => deleteManager.mutate(id)}
        onClose={() => setShowDrawer(false)}
        onCreate={(manager: Omit<IUser, 'id'>) => createManager.mutate(manager)}
        onEdit={(manager: IUser) => updateManager.mutate(manager)}
      />
    </>
  );
};
