import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import partner from '../../api/partner';
import { AppWhiteLinkButton } from '../../components/core/buttons';
import StyledNavLink from '../../components/core/styledNavLink';
import PartnersList from '../../components/lists/partnersList';
import { PartnersStats } from '../../components/partnersStats';
import { PartnersStatsMobile } from '../../components/partnersStats/mobile';
import { IProjectsSearchParams } from '../../types/project.interface';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    // height: '100%',
    // maxHeight: '100%',
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  rootPadding: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  navRoot: {},
  whiteLinkBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
}));

export const PartnersMainPage: React.FC<{
  archive?: boolean;
  review?: boolean;
}> = ({ archive, review }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  const [sortParams, setSortParams] = useState<{
    by: string;
    direction: 'asc' | 'desc';
  }>({ by: 'e.name', direction: 'asc' });
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['partners', archive || false, review || false, sortParams],
    ({ pageParam }) =>
      partner.list(
        pageParam,
        { onlyDeleted: archive, onlyVerifyPending: review },
        sortParams
      ),
    {
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
      suspense: false,
    }
  );
  const statsQuery = useQuery('partnerStats', partner.stats);
  return (
    <Grid container item direction='column' className={classes.root}>
      <Box className={classes.rootPadding}>
        <Grid
          item
          container
          alignItems='flex-end'
          direction='row'
          wrap='nowrap'
          justify='space-between'
          className={classes.navRoot}
        >
          <Box display='flex' flexDirection='row'>
            <StyledNavLink to='/partners' exact>
              <Typography variant='h5'>
                {t('page.partners.navActive')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/partners/archive' exact>
              <Typography variant='h5'>
                {t('page.partners.navArchive')}
              </Typography>
            </StyledNavLink>
            <StyledNavLink to='/partners/applications' exact>
              <Typography variant='h5'>
                {t('page.partners.navRequest')}
              </Typography>
            </StyledNavLink>
          </Box>
          <Hidden xsDown>
            <Box mb={2}>
              <AppWhiteLinkButton to='/auth/partner-signup'>
                {t('page.partners.addPartner')}
              </AppWhiteLinkButton>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box mb={1}>
              <AppWhiteLinkButton
                className={classes.whiteLinkBtn}
                to='/auth/partner-signup'
              >
                {t('page.partners.addPartnerMobile')}
              </AppWhiteLinkButton>
            </Box>
          </Hidden>
        </Grid>
      </Box>

      <Grid item container direction='column' className={classes.listRoot}>
        {!archive && !review && (
          <>
            <Hidden xsDown>
              <Box pl={4} mt={7.5}>
                {statsQuery.data && <PartnersStats stats={statsQuery.data} />}
              </Box>
            </Hidden>
            <Hidden smUp>
              <Box pl={2} pb={1.5} mt={4.5}>
                {statsQuery.data && (
                  <PartnersStatsMobile stats={statsQuery.data} />
                )}
              </Box>
            </Hidden>
          </>
        )}
        {data?.pages.map((page) => page.items).flat().length !== 0 ? (
          <PartnersList
            review={review}
            archive={archive}
            hasMore={!!hasNextPage}
            sortParams={sortParams}
            onUpdateSortParams={(o) => setSortParams(o)}
            fetchNext={() => {
              setPage((p) => p + 1);
              fetchNextPage({
                pageParam: page,
              });
            }}
            items={data ? data.pages.map((page) => page.items).flat() : []}
          />
        ) : (
          <Grid container item justify='center' alignItems='center'>
            <Box pt='15%'>
              <Typography variant='h2'>
                {archive
                  ? t('page.partners.noPartnersInArchive')
                  : t('page.partners.noPartnersAtAll')}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PartnersMainPage;
