import { Grid } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import project from '../../api/project';
import BlockHeading from '../../components/core/blockHeading';
import { ProjectCreateForm } from '../../components/forms/project';
import MainLoader from '../../components/mainLoader';
import { useTranslation } from 'react-i18next';

export const ProjectsCreatePage: React.FC = () => {
  const params = useParams<{ id?: string }>();
  const projectQuery = useQuery(
    ['projects', params.id],
    (ctx) => project.get(params.id!),
    {
      enabled: !!params.id,
    }
  );
  const { t } = useTranslation();

  if (projectQuery.isLoading) {
    return <MainLoader />;
  }
  return (
    <Grid container item xs={12} direction='column' alignItems='center'>
      <Grid container item md={5}>
        {params.id ? (
          <BlockHeading>{t('button.editProject')}</BlockHeading>
        ) : (
          <BlockHeading>{t('page.projects.newProject')}</BlockHeading>
        )}
        {projectQuery.isSuccess ? (
          <ProjectCreateForm current={projectQuery.data} />
        ) : (
          <ProjectCreateForm />
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectsCreatePage;
