import { Box, styled } from '@material-ui/core';

const FormItemRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 24,
  [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    flexDirection: 'column',
    marginBottom: 16,
    alignItems: 'flex-start',
  },
}));

export default FormItemRow;
