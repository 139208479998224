import React, { useState } from 'react';
import { Box, Hidden, makeStyles, styled } from '@material-ui/core';
import AppButton from '../core/buttons';
import { UserIcon } from '../core/icons/user';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { AppPopover } from '../core/contextMenu';
import UserProfileCard from '../userProfileCard';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import UserEditProfileForm from '../forms/userEditProfileForm';
// import { Close } from '@material-ui/icons';
import { logout } from '../../store/user/actions';
import CloseIcon from '../core/icons/close';
import { useTranslation } from 'react-i18next';
import { EUserRole } from '../../types/user.interface';
import useRbac from '../../hooks/useRbac';

const StyledUserIcon = styled(UserIcon)(({ theme }) => ({
  width: 32,
  height: 32,
  color: 'white',
  marginLeft: 24,
  cursor: 'pointer',
  [theme.breakpoints.down(theme.breakpoints.values.md)]: {
    color: theme.palette.primary.main,
    marginLeft: 0,
  },
}));

export const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  color: 'white',
});

const Wrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  [theme.breakpoints.down(theme.breakpoints.values.md)]: {
    justifyContent: 'flex-start',
  },
}));

export const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 16,
});

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: '100%',
      maxWidth: '100%',
      width: '80%',
      top: '10%!important',
      overflow: 'scroll',
    },
  },
}));

export const UserCard: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState<SVGElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const user = useAppSelector((state) => state.user);
  const rbac = useRbac();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrap mt={2.2} mr={4}>
      <Hidden smDown>
        {location.pathname !== '/projects/create' && rbac.can('project.create') && (
          <AppButton variant='contained' color='secondary'>
            <StyledNavLink to='/projects/create'>
              {t('header.button.createProject')}
            </StyledNavLink>
          </AppButton>
        )}
        <StyledUserIcon onClick={(e) => setAnchor(e.currentTarget)} />
      </Hidden>
      <Hidden mdUp>
        <StyledUserIcon onClick={(e) => setAnchor(e.currentTarget)} />
      </Hidden>
      <AppPopover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        paperClassName={classes.paper}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <StyledClose
          fontSize='default'
          onClick={
            isEditing ? () => setIsEditing(false) : () => setAnchor(null)
          }
        />

        {user.profile ? (
          !isEditing ? (
            <UserProfileCard
              onLogout={onLogout}
              partner={user.partner}
              user={user.profile}
              onClose={() => setAnchor(null)}
              onEditClick={() => setIsEditing(true)}
            />
          ) : (
            <UserEditProfileForm
              id={user.profile.id}
              name={user.profile.fullName}
              position={user.profile.position}
              email={user.profile.email}
              phone={user.profile.phone}
              followed={user.profile.followedStatus}
              cities={user.profile.cities}
              isAdmin={user.profile?.role === EUserRole.admin}
              isSubscribedToPartnersRegistering={
                user.profile?.isSubscribedToPartnersRegistrations
              }
            />
          )
        ) : null}
      </AppPopover>
    </Wrap>
  );
};

export default UserCard;
