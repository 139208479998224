import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SearchIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.01985 9.72664C6.85903 11.5304 3.63967 11.4178 1.61091 9.38909C-0.536971 7.2412 -0.536971 3.7588 1.61091 1.61091C3.7588 -0.536971 7.2412 -0.536971 9.38909 1.61091C11.4177 3.63956 11.5304 6.85869 9.72693 9.01951L13.9854 13.2783L13.2783 13.9854L9.01985 9.72664ZM8.65119 8.71248L8.71248 8.65119C10.4393 6.8915 10.4291 4.06515 8.68198 2.31802C6.92462 0.56066 4.07538 0.56066 2.31802 2.31802C0.56066 4.07538 0.56066 6.92462 2.31802 8.68198C4.06515 10.4291 6.8915 10.4393 8.65119 8.71248Z'
      />
    </SvgIcon>
  );
};

export default SearchIcon;
