import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Block, Check } from '@material-ui/icons';
import React, { useState } from 'react';
import PenIcon from '../core/icons/pen';
import TrashBinIcon from '../core/icons/trashBin';
import TwoLinesIcon from '../core/icons/twoLines';
import AppInput from '../core/outlinedInput';

const useStyles = makeStyles((theme) => ({
  categoryRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    '&.dragging': {
      boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
      '& .two-lines': {
        opacity: 0,
      },
    },
    '& .two-lines': {
      fontSize: 4,
      marginRight: 4,
    },
    '& .helper-ico': {
      fontSize: 16,
      '&.check': {
        color: '#00B852',
      },
    },
  },
}));

interface CategoyItemProps {
  isDragging?: boolean;
  category: any;
  onEdit: (value: string, id: string) => void;
  onDelete: (id: string) => void;
}

export const CategoryItem: React.FC<CategoyItemProps> = ({
  category,
  onEdit,
  isDragging,
  onDelete,
}) => {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [title, setTitle] = useState(category.title);

  return (
    <div className={`${classes.categoryRoot} ${isDragging ? 'dragging' : ''}`}>
      {isEditing ? (
        <AppInput
          defaultValue={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          error={!title ? true : false}
        />
      ) : (
        <Box display='flex' flexDirection='row' alignItems='center'>
          <TwoLinesIcon className='two-lines' />
          <Typography variant='body1'>{category.title}</Typography>
        </Box>
      )}
      {!isEditing ? (
        <Box>
          <IconButton color='secondary' onClick={() => onDelete(category.id)}>
            <TrashBinIcon className='helper-ico' />
          </IconButton>
          <IconButton color='primary' onClick={() => setEditing(true)}>
            <PenIcon className='helper-ico' />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton
            color='primary'
            disabled={!title}
            onClick={() => {
              setEditing(false);
              onEdit(title, category.id);
            }}
          >
            <Check className='helper-ico check' />
          </IconButton>
          <IconButton color='secondary' onClick={() => setEditing(false)}>
            <Block className='helper-ico' />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
