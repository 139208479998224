import {
  Box,
  BoxProps,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const DropZoneWrap: React.ComponentType<BoxProps> = styled(Box)(
  ({ theme }) => ({
    minWidth: '100%',
    minHeight: '100%',
    position: 'relative',
    backgroundColor: '#EDF0F9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&.isDragging': {
      '&:after': {
        content: '""',
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
      },
    },
    '&.error': {
      cursor: 'not-allowed',
    },
  })
);

const useStyles = makeStyles((theme) => ({
  hiddenFileInput: {
    position: 'fixed',
    left: -1000,
    opacity: 0,
  },
  mainText: {
    width: '75%',
    textAlign: 'center',
    fontWeight: 600,
    '& > span': {
      color: theme.palette.info.main,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  hintText: {
    marginTop: 4,
    color: theme.palette.grey[600],
    width: '80%',
    textAlign: 'center',
  },
}));

interface FileDropZoneProps {
  onChange: (items: FileList) => void;
  allowedMimeTypes: Array<string>;
  allowedFileExtensions: Array<string>;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({ onChange }) => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
    if (true) {
      setIsValid(false);
      return;
    }
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleClickFakeInput = () => {
    if (ref.current) {
      ref.current.click();
    }
  };
  const handleFileInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      onChange(e.currentTarget.files);
    }
  };
  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      let size = files[i].size / 1024 / 1024;
      if (size > 20) {
        enqueueSnackbar(
          t('component.dropZone.wrongSizeError', {
            name: files[i].name,
            size: size.toFixed(1),
          }),
          { variant: 'error' }
        );
        return;
      }
    }

    setIsDragging(false);
    onChange(files);
  };

  const cs = cx({
    isDragging: isDragging,
    isValid: isValid,
  });

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={handleOnDrop}
    >
      <input
        onChange={handleFileInput}
        className={classes.hiddenFileInput}
        type='file'
        name='dropZoneFile'
        ref={ref}
      />
      <DropZoneWrap className={cs}>
        <Typography className={classes.mainText} variant='body2'>
          {t('component.dropZone.dropFileHere')}
          <span onClick={handleClickFakeInput}>
            {' '}
            {t('component.dropZone.orChooseManual')}
          </span>
        </Typography>
        <Typography className={classes.hintText} variant='body2'>
          {t('component.dropZone.fileRequirement')}
        </Typography>
      </DropZoneWrap>
    </div>
  );
};
