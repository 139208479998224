import { createTheme } from '@material-ui/core/styles';
import themes from './themes';
import config, { ServiceType } from '../app.config';

const themeConfig = config.service === ServiceType.Spitch ? 'spitch' : 'bss';
const theme = createTheme(themes[themeConfig] ?? {});

document.getElementsByTagName('body')[0].style.backgroundImage =
  themes[themeConfig].background;
// document.getElementsByTagName('body')[0].style.backgroundColor =
//   themes[themeConfig].backgroundColor;

const favicon = document.getElementById('favicon');
if (favicon) {
  favicon.setAttribute('href', themes[themeConfig].favicon);
}

export default theme;
