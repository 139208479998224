import {
  Box,
  Divider,
  Grid,
  Grow,
  Hidden,
  makeStyles,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import news from '../../api/news';
import useLocalStorage from '../../hooks/useLocalStorage';
import CloseIcon from '../core/icons/close';
import { useTranslation } from 'react-i18next';

const NewsPaper = styled(Paper)({
  boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)',
});

const HideButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.palette.primary.main,
  fontSize: 12,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  lineHeight: 1.2,
  cursor: 'pointer',
  '&:hover': {
    color: '#E7636B',
  },
}));

const useStyles = makeStyles((theme) => ({
  img: {
    height: 64,
    '@media (max-width: 740px)': {
      display: 'none',
    },
  },
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  closeIconWrap: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3.5),
    },
  },
  title: {
    '&:hover': {
      color: '#E7636B',
    },
  },
}));

export const NewsCard: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery('newsLatest', news.latest, {
    suspense: false,
  });
  const [hidden, setHidden] = useLocalStorage('bss_news_card', {
    value: false,
    id: '',
  });

  useEffect(() => {
    if (data) {
      if (data.id !== hidden.id && hidden.value === true) {
        setHidden({ value: false, id: data.id });
      }
    }
  }, [data, hidden.id, hidden.value, setHidden]);
  if (!data || isLoading) {
    return null;
  }
  return (
    <Grid item>
      <Grow in={!hidden.value} timeout={350} unmountOnExit>
        <Box className={classes.root}>
          <NewsPaper square>
            <Grid
              item
              container
              direction='row'
              wrap='nowrap'
              alignItems='center'
            >
              <Grid item>
                <Box p={1} maxHeight='64px'>
                  {data.picture && (
                    <img
                      className={classes.img}
                      alt='news photo'
                      //@ts-ignore
                      src={`/api/v1/file/resolve/${data.picture.id}/none`}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                container
                alignItems='center'
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  height: '100%',
                }}
                component={Link}
                to={`/news/${data.id}`}
              >
                <Box p={1} maxWidth='500px' height='100%'>
                  <Typography variant='h4' className={classes.title}>
                    {data.title}
                  </Typography>
                </Box>
              </Grid>
              <Hidden only='xs'>
                <Grid item container direction='row' justify='flex-end'>
                  <Box p={1} alignItems='center' display='flex' height='100%'>
                    <Divider orientation='vertical' style={{ height: 64 }} />
                    <Box px={3}>
                      <HideButton
                        onClick={() => setHidden({ value: true, id: data.id })}
                      >
                        {t('component.newsCard.hide')}
                      </HideButton>
                    </Box>
                  </Box>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <Box className={classes.closeIconWrap}>
                    <CloseIcon
                      onClick={() => setHidden({ value: true, id: data.id })}
                    />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </NewsPaper>
        </Box>
      </Grow>
    </Grid>
  );
};

export default NewsCard;
