export enum ENewsType {
  news = 'news',
  events = 'event',
}

export interface INewsCommon {
  id: string;
  type: ENewsType;
  title: string;
  description: string;
  picture: string;
  body: string;
  publishAt: string | number;
  createdAt: string | number;
  updatedAt?: string | number;
  deletedAt?: string | number;
}

export interface IEvent extends INewsCommon {
  type: ENewsType.events;
  eventStartAt: string | number;
  eventEndAt: string | number;
}

export interface INews extends INewsCommon {
  type: ENewsType.news;
}

export type TNews = INews | IEvent;
