import {
  Box,
  Collapse,
  Drawer,
  Grid,
  styled,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useMutation, useQueryClient } from 'react-query';
import deleteApi from '../../api/delete';
import material from '../../api/material';
import sort from '../../api/sort';
import { TMaterialCategoryType } from '../../types/material.interface';
import AppButton from '../core/buttons';
import CloseIcon from '../core/icons/close';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FormItemRow from '../forms/formRow';
import { CategoryItem } from './categoryItem';
import { useTranslation } from 'react-i18next';

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 28,
});

const useStyles = makeStyles((theme) => ({
  exBtn: {
    padding: 0,
    '& > svg': {
      fontSize: 8,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  saveBtn: {
    padding: '10px 24px',
    marginTop: theme.spacing(3),
  },
}));

const CategoryForm: React.FC<{
  onSave: (value: string) => void;
  onCancel: () => void;
}> = ({ onCancel, onSave }) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  return (
    <Box style={{ backgroundColor: '#EDF0F9' }} py={5} px={3} mt={2}>
      <Box mb={3}>
        <Typography variant='h4'>
          {t('component.categoryCreateDrawer.headline')}
        </Typography>
      </Box>
      <FormItemRow>
        <AppInputLabel>
          {t('component.categoryCreateDrawer.categoryName')}
        </AppInputLabel>
        <AppInput
          style={{ backgroundColor: '#fff' }}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      </FormItemRow>
      <FormItemRow>
        <AppInputLabel />
        <Box display='flex' flexDirection='row'>
          <Box mr={3}>
            <AppButton
              onClick={() => onSave(value)}
              style={{ backgroundColor: '#fff' }}
              variant='outlined'
              color='primary'
            >
              {t('button.add')}
            </AppButton>
          </Box>
          <AppButton color='primary' onClick={onCancel}>
            {t('button.cancel')}
          </AppButton>
        </Box>
      </FormItemRow>
    </Box>
  );
};

interface MaterialCategoryDrawerProps {
  open: boolean;
  onClose: () => void;
  type: TMaterialCategoryType;
  categories: {
    sort: Array<string>;
    items: any;
    entity: string;
  };
  handleCategoryDrop: (result: DropResult) => void;
}

export const MaterialCategoryDrawer: React.FC<MaterialCategoryDrawerProps> = ({
  open,
  onClose,
  categories,
  handleCategoryDrop,
  type,
}) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const qc = useQueryClient();
  const { t } = useTranslation();
  const sortUpdate = useMutation('sort', sort.update);
  const categoryCreate = useMutation(
    'materialCategory',
    material.category.create,
    {
      onSuccess: (data) => {
        qc.invalidateQueries('materialCategory');
        qc.invalidateQueries(['materials', type]);
        sortUpdate.mutate({
          entity: categories.entity,
          ids: [...categories.sort, data.id],
        });
        setShowForm(false);
      },
    }
  );
  const categoryUpdate = useMutation(
    'materialCategory',
    material.category.update,
    {
      onSuccess: () => {
        //TODO: UPDATE ROOT MATERIALS;
        qc.invalidateQueries('materialCategory');
        qc.invalidateQueries('materials');
        setShowForm(false);
      },
    }
  );
  const categoryDelete = useMutation(
    'materialCategory',
    deleteApi.materialCategory,
    {
      onSuccess: (data, variables) => {
        //TODO: UPDATE ROOT MATERIALS;
        qc.invalidateQueries('materials');
        sortUpdate.mutate({
          entity: categories.entity,
          ids: categories.sort.filter((cid) => cid !== variables),
        });
      },
    }
  );
  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 484 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <StyledClose onClick={onClose} />
      <Box mx={3} mt={4}>
        <Typography variant='h3'>
          {t('component.categoryCreateDrawer.editCategories')}
        </Typography>

        {!showForm && (
          <Box my={1}>
            <AppButton
              onClick={() => setShowForm(true)}
              className={classes.exBtn}
              color='primary'
              startIcon={<Add style={{ fontSize: 12 }} />}
            >
              {t('button.add')}
            </AppButton>
          </Box>
        )}
        <Collapse in={showForm} timeout={200} unmountOnExit>
          <CategoryForm
            onSave={(value) =>
              categoryCreate.mutate({
                title: value,
                type: type,
              })
            }
            onCancel={() => setShowForm(false)}
          />
        </Collapse>
        <DragDropContext onDragEnd={handleCategoryDrop}>
          <Droppable droppableId='categories' direction='vertical'>
            {(provided) => {
              return (
                <Grid
                  item
                  container
                  direction='column'
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {categories.sort.map((cid, index) => {
                    const category = categories.items[cid];
                    if (!category) return null;
                    return (
                      <Draggable
                        key={`drawer-${category.id}`}
                        draggableId={cid}
                        index={index}
                      >
                        {(props, snapshot) => (
                          <div
                            ref={props.innerRef}
                            {...props.dragHandleProps}
                            {...props.draggableProps}
                          >
                            <CategoryItem
                              isDragging={snapshot.isDragging}
                              category={category}
                              onEdit={(val, id) =>
                                categoryUpdate.mutate({
                                  title: val,
                                  id: id,
                                  type: type,
                                })
                              }
                              onDelete={(id) => categoryDelete.mutate(id)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Grid>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Box>
    </Drawer>
  );
};

export default MaterialCategoryDrawer;
