import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FileIcon: React.FC<
  SvgIconProps & { text?: string; isDeleted?: boolean }
> = ({ fill, text, isDeleted, style, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 29 40'
      fill='none'
      style={{ color: 'transparent', ...style }}
    >
      <g id='g202'>
        <path
          id='rect10'
          style={{ stroke: isDeleted ? '#BABABA' : '#ddd9f1' }}
          d='m 4,0.5 h 21 c 1.939,0 3.5,1.561 3.5,3.5 v 32 c 0,1.939 -1.561,3.5 -3.5,3.5 H 4 C 2.061,39.5 0.5,37.939 0.5,36 V 4 C 0.5,2.061 2.061,0.5 4,0.5 Z'
        />
        <g style={{ fill: 'none' }} id='g31' transform='translate(6,21)'>
          <path
            id='rect20'
            style={{ fill: isDeleted ? '#BABABA' : '#ddd9f1' }}
            d='M 0,0 H 15 V 1 H 0 Z'
          />
        </g>
        <g style={{ fill: 'none' }} id='g43' transform='translate(6,18)'>
          <text
            style={{
              fontFamily: 'Proxima Nova Rg',
              fontSize: 8,
              height: 11,
              fill: isDeleted ? '#BABABA' : '#1C00A0',
            }}
          >
            {text}
          </text>
        </g>
        <g style={{ fill: 'none' }} id='g44' transform='translate(6,27)'>
          <path
            id='rect33'
            style={{ fill: isDeleted ? '#BABABA' : '#ddd9f1' }}
            d='M 0,0 H 9 V 1 H 0 Z'
          />
        </g>
        <g style={{ fill: 'none' }} id='g31-0' transform='translate(6,24)'>
          <path
            id='rect20-2'
            style={{ fill: isDeleted ? '#BABABA' : '#ddd9f1' }}
            d='M 0,0 H 15 V 1 H 0 Z'
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default FileIcon;
