import { Button, styled, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';
import AppButton from '../../core/buttons';
import AppInputLabel from '../../core/label';
import AppInput from '../../core/outlinedInput';
import FormItemRow from '../formRow';
import Reaptcha from 'reaptcha';
import { getCaptchaPublicToken } from '../../../api/auth';
import { useTranslation } from 'react-i18next';

const BasicAuthForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

const AuthHeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 32,
  lineHeight: 1.2,
  textAlign: 'center',
  marginBottom: 16,
  color: theme.palette.primary.main,
}));

const RestorePassButton = styled(Button)({
  justifyContent: 'flex-start',
  paddingLeft: 0,
  textTransform: 'none',
  width: 'auto',
  overflow: 'hidden',
  fontSize: 13,
  lineHeight: '20px',
  fontFamily: 'Proxima Nova Rg',
  marginBottom: 5,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiButton-endIcon': {
    marginLeft: 4,
  },
});

const StyledInput = styled(AppInput)({
  minWidth: 192,
});

interface AuthFormCommonProps {
  onRestoreClick: () => void;
  error?: string;
}

interface LoginFormProps extends AuthFormCommonProps {
  onSubmit: (email: string, password: string, captcha: string) => void;
}
interface RestoreFormProps extends AuthFormCommonProps {
  onSubmit: (email: string) => void;
  error?: string;
  success?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onRestoreClick,
  onSubmit,
  error,
}) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const { t } = useTranslation();

  useMemo(() => {
    getCaptchaPublicToken().then((t) => {
      setCaptchaToken(t.token);
    });
  }, [captchaToken]);

  const handleEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(e.currentTarget.value);
  };
  const handlePasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.target.value);
  };
  const handleCaptchaChange = (token: string) => {
    setCaptcha(token);
  };
  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(email, password, captcha);
  };
  return (
    <BasicAuthForm onSubmit={handleSubmit}>
      <AuthHeaderText variant='h3'>
        {t('page.login.headlinePersonal')}
      </AuthHeaderText>
      <FormItemRow>
        <AppInputLabel variant='standard' htmlFor='email'>
          {t('words.email')}
        </AppInputLabel>
        <StyledInput
          error={!!error}
          helperText={error}
          color='primary'
          defaultValue={email}
          onChange={handleEmailChange}
          type='email'
          name='email'
        />
      </FormItemRow>
      <FormItemRow flexDirection='row' display='flex' alignItems='center'>
        <AppInputLabel variant='standard' htmlFor='password'>
          {t('words.password')}
        </AppInputLabel>
        <StyledInput
          error={!!error}
          helperText={error}
          defaultValue={password}
          onChange={handlePasswordChange}
          type='password'
          color='primary'
          name='password'
        />
      </FormItemRow>
      <RestorePassButton
        onClick={onRestoreClick}
        fullWidth={false}
        endIcon={<ChevronRight width={7} height={7} />}
      >
        {t('page.login.forgotPassword')}
      </RestorePassButton>
      {/* {error && <FormHelperText error={true}>{error}</FormHelperText>} */}
      {captchaToken === '' || (
        <Reaptcha
          sitekey={captchaToken}
          onError={() => handleCaptchaChange('')}
          onRender={() => handleCaptchaChange('')}
          onExpire={() => handleCaptchaChange('')}
          onLoad={() => handleCaptchaChange('')}
          onVerify={(token) => handleCaptchaChange(token)}
        />
      )}
      <AppButton
        disabled={captcha === ''}
        variant='contained'
        style={{ paddingTop: 10, paddingBottom: 10, marginTop: 15 }}
        type='submit'
        color='primary'
      >
        {t('button.login')}
      </AppButton>
    </BasicAuthForm>
  );
};

const RestoreForm: React.FC<RestoreFormProps> = ({
  onRestoreClick,
  error,
  success,
  onSubmit,
}) => {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  return (
    <BasicAuthForm
      onSubmit={(e) => {
        onSubmit(email);
        e.preventDefault();
      }}
    >
      <AuthHeaderText variant='h3'>
        {success
          ? t('page.restorePassword.mailWasSent')
          : t('page.restorePassword.restorePasswordHeadline')}
      </AuthHeaderText>
      <FormItemRow>
        <AppInputLabel variant='standard' htmlFor='email'>
          {t('words.email')}
        </AppInputLabel>
        <StyledInput
          color='primary'
          name='email'
          onChange={(e) => setEmail(e.currentTarget.value)}
          error={!!error}
          helperText={error}
        />
      </FormItemRow>
      <RestorePassButton
        onClick={onRestoreClick}
        fullWidth={false}
        startIcon={<ChevronLeft width={7} height={7} />}
      >
        {t('page.restorePassword.rememberedPasswordBack')}
      </RestorePassButton>
      {!success && (
        <AppButton
          style={{ paddingTop: 10, paddingBottom: 10 }}
          onClick={() => onSubmit(email)}
          variant='contained'
          color='primary'
        >
          {t('page.restorePassword.restoreButton')}
        </AppButton>
      )}
    </BasicAuthForm>
  );
};

export { RestoreForm, LoginForm };
