import { useAppSelector } from './useStore';

export default function () {
  const { profile } = useAppSelector((state) => state.user);

  return {
    can: (permission: string) => {
      if (!profile) return false;
      if (profile?.permissions.includes(permission)) {
        return true;
      }

      const asteriskEverythingPermissionPattern =
        permission.split('.').splice(0, 1).join('.') + '.*';
      if (profile?.permissions.includes(asteriskEverythingPermissionPattern)) {
        return true;
      }

      return false;
    },
    
    getUser: () => {
      if (!profile) return null;
      return profile
    },
  };
}
