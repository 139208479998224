import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Popper,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import partner from '../../../api/partner';
import project from '../../../api/project';
import { useAppSelector } from '../../../hooks/useStore';
import IFile from '../../../types/file.interface';
import IPartner from '../../../types/partner.interface';
import IProject, { EProjectStatus } from '../../../types/project.interface';
import { EUserRole } from '../../../types/user.interface';
import AppButton from '../../core/buttons';
import { AppContextMenu, AppMenuItem } from '../../core/contextMenu';
import CloseIcon from '../../core/icons/close';
import FieldArrowDown from '../../core/icons/fieldArrowDown';
import AppInputLabel from '../../core/label';
import AppInput from '../../core/outlinedInput';
import FileCard from '../../fileCard';
import { FileDropZone } from '../../fileDropZone';
import FileLoadZone from '../../fileDropZone/mobile';
import DateInput from '../../inputs/dateInput';
import MoneyInput from '../../inputs/moneyInput';
import LoadingFile from '../../loadingFile';
import FormItemRow from '../formRow';
import { useTranslation } from 'react-i18next';
import { ManagerUserEmployeeSelect } from '../../inputs/managerUserEmployeeSelect';
import city from '../../../api/city';
import ICity from '../../../types/city.interface';
import * as yup from 'yup';
import config, { ServiceType } from '../../../app.config';
import { format } from 'date-fns';
import { CalendarIcon } from '../../core/icons/calendar';
import moment from 'moment';

interface ProjectCreateFormProps {
  current?: IProject;
}

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});
const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 28,
  cursor: 'pointer',
});

const StyledAppDatePreviewInput = styled(AppInput)({
  width: 182,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
  },
  '& input::-webkit-calendar-picker-indicator': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& svg': {
    cursor: 'pointer',
  },
});
export const StyledTextArea = styled('textarea')({
  flex: 1,
  alignItems: 'center',
  borderColor: "#ccc",
  borderRadius: 5,
  marginTop: 5,
  marginBottom: 5,
  padding: 5,
  width: "100%",
  boxSizing: "border-box",
});

const FormWrap = styled('form')({});

const useStyles = makeStyles(() => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
  pointerText: {
    cursor: 'pointer',
  },
}));

export const ProjectCreateForm: React.FC<ProjectCreateFormProps> = ({
  current,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [cityPage, setCityPage] = useState(2);
  const [loadedFiles, setLoaded] = useState<Array<IFile>>(
    current ? current.files : []
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);
  const popperRef = useRef<HTMLElement>(null);
  const [partnerSearch, setPartnerSearch] = useState(
    current ? current.partner?.name : ''
  );

  const [citySearch, setCitySearch] = useState(
    current ? current.city?.name : ''
  );

  const [searchAnchor, setSearchAnchor] = useState(false);
  const [searchCityAnchor, setSearchCityAnchor] = useState(false);
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState<Array<File>>([]);
  const user = useAppSelector((state) => state.user);
  const qc = useQueryClient();

  const handleEscp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      history.goBack();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleEscp);

    return () => document.removeEventListener('keydown', handleEscp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['partners'],
    ({ pageParam }) =>
      partner.list(pageParam, {
        filtered: true,
        // search: partnerSearch
      }),
    {
      suspense: false,
      enabled: user.profile?.role === EUserRole.admin,
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
    }
  );

  const {
    data: cityData,
    isLoading: cityIsLoading,
    hasNextPage: cityHasNextPage,
    fetchNextPage: cityFetchNextPage,
  } = useInfiniteQuery(['cities'], ({ pageParam }) => city.list(pageParam), {
    suspense: false,
    getNextPageParam: (last, all) => {
      if (last.hasNextPage) {
        let p = page;
        return p++;
      }
    },
  });
  const update = useMutation('projects', project.update, {
    onSuccess: () => {
      qc.invalidateQueries(['projects', current?.id]);
      enqueueSnackbar(t('toastNotifications.projectUpdated'), {
        variant: 'info',
      });
    },
    onError: (err) => {
      //@ts-ignore
      let a = (err as any).response.data.errors
        .map((er: any) => er.reason)
        .join(',');
      enqueueSnackbar(a, { variant: 'error' });
    },
  });
  const create = useMutation('projects', project.create, {
    onSuccess: (data) => {
      history.push('/');
      enqueueSnackbar(t('toastNotifications.projectCreated'), {
        variant: 'info',
      });
    },
    onError: (err) => {
      //@ts-ignore
      let a = (err as any).response.data.errors
        .map((er: any) => er.reason)
        .join(',');
      enqueueSnackbar(a, { variant: 'error' });
    },
  });

  const addDaysToDate = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const expireDiff = addDaysToDate(new Date(), config.adminProjectExpireInDays);
  const formik = useFormik<
    Omit<
      IProject,
      'created_at' | 'expire_at' | 'files' | 'partner' | 'city'
    > & {
      files: Array<string>;
      partner?: string;
      city?: string;
    }
  >({
    initialValues: current
      ? {
          ...current,
          files: current.files.map((f) => f.id),
          partner: current.partner?.id,
          city: current.city?.id,
          dealAt: current.dealAt
            ? new Date(+current.dealAt * 1000).toString()
            : new Date().toString(),
          createdAt: current.createdAt
            ? new Date(+current.createdAt * 1000).toString()
            : new Date().toString(),
          expireAt: current.expireAt
            ? new Date(+current.expireAt * 1000).toString()
            : new Date().toString(),
        }
      : {
          id: '',
          partner: '',
          name: '',
          customer: '',
          subject: '',
          requestedAmount: 0,
          actualAmount: 0,
          comment: '',
          city: '',
          department: '',
          status: EProjectStatus.active,
          files: [],
          createdAt: new Date().toString(),
          dealAt:
            config.service === ServiceType.Spitch
              ? new Date().toString()
              : new Date().toString(),
          expireAt: expireDiff.toString(),
        },
    validateOnBlur: true,
    validationSchema: yup.object({
      city: yup.string().required(t('validation.required')),
      customer: yup.string().required(t('validation.required')),
      requestedAmount: yup.number().required(t('validation.required')),
      actualAmount: yup.number().notRequired().min(1),
      department: yup.string().required(t('validation.required')),
      subject: yup.string().required(t('validation.required')),
      manager: yup.mixed().required(t('validation.required')),
    }),
    onSubmit: (values) => {
      values.files = loadedFiles.map((f) => {
        if (typeof f === 'string') return f;

        return f.id;
      });

      const validateBranch: { [key in ServiceType]: () => string | null } = {
        [ServiceType.Spitch]: () => {
          if (
            moment(values.dealAt ? values.dealAt : moment.now())
              .set({ hour: 23, minutes: 59 })
              .isBefore(moment().set({ hour: 0, minutes: 0 }), 'seconds')
          ) {
            return t('toastNotifications.featureDate');
          }

          if (
            moment(values.dealAt ? values.dealAt : moment.now())
              .set({ hour: 0, minutes: 0 })
              .isAfter(
                moment(expireDiff)
                  .add(1, 'days')
                  .set({ hour: 23, minutes: 59 }),
                'seconds'
              )
          ) {
            return 'Expected Deal Date: choose today or up to 6 month';
          }

          return null;
        },

        [ServiceType.Bss]: () => {
          if (
            moment(values.dealAt ? values.dealAt : moment.now())
              .set({ hour: 23, minutes: 59 })
              .isBefore(moment().set({ hour: 0, minutes: 0 }), 'seconds')
          ) {
            return t('toastNotifications.featureDate');
          }

          if (
            moment(values.createdAt ? values.createdAt : moment.now())
              .set({ hour: 0, minutes: 0 })
              .isAfter(moment().set({ hour: 23, minutes: 59 }), 'seconds')
          ) {
            return 'Дата создания должна быть в прошлом';
          }

          return null;
        },
      };

      const validationError = validateBranch[config.service]();
      if (validationError) {
        enqueueSnackbar(validationError, {
          variant: 'error',
        });
        return;
      }

      values.dealAt = (
        values.dealAt ? new Date(values.dealAt) : new Date()
      ).toISOString();
      values.createdAt = (
        values.createdAt ? new Date(values.createdAt) : new Date()
      ).toISOString();
      values.expireAt = (
        config.service === ServiceType.Spitch
          ? expireDiff
          : addDaysToDate(
              values.dealAt ? new Date(values.dealAt) : new Date(),
              config.adminProjectExpireInDays
            )
      ).toISOString();

      if (!current) {
        create.mutate({
          ...values,
          manager: values.manager?.id,
        });
      } else {
        update.mutate({
          ...values,
          manager: values.manager?.id,
        });
      }
    },
  });

  const handlePartnerSearchClick = (partner: IPartner) => {
    setPartnerSearch(partner.name);
    setSearchAnchor(false);
    formik.setFieldValue('partner', partner.id);
    formik.setFieldValue('manager', undefined);
  };

  const handleCitySearchClick = (city: ICity) => {
    setCitySearch(city.name);
    setSearchCityAnchor(false);
    formik.setFieldValue('city', city.id, false);
  };

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      setFiles((files) => [...files, list[i]]);
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      //@ts-ignore
      formik.handleSubmit(formRef.current);
    }
  };

  const handleLoadSuccess = (fileResponse: IFile, f: File) => {
    const nf = files.filter((file) => file.name !== f.name);
    setFiles(nf);
    setLoaded((lf) => [...lf, fileResponse]);
  };

  const handleFileDelete = (id: string) => {
    setLoaded((files) => files.filter((f) => f.id !== id));
  };

  useMemo(() => {
    if (citySearch || !cityData || !cityData?.pages?.length) return;
    const page = cityData.pages[0];

    if (!page.items.length) return;

    setCitySearch(page.items[0].name ?? '');
    handleCitySearchClick(page.items[0]);
  }, [cityData]);

  return (
    <Grid item xs={12}>
      <Paper elevation={0} square color='white'>
        <Box px={4} py={5} position='relative'>
          <StyledClose onClick={() => history.push('/')} />

          <Box mb={2}>
            <Typography variant='h4'>
              {t('page.projectEdit.infoHeadline')}
            </Typography>
          </Box>

          <FormWrap ref={formRef}>
            {current && user.profile?.role === EUserRole.admin && (
              <>
                <FormItemRow>
                  <AppInputLabel>
                    {t('page.projectEdit.statusField')}
                  </AppInputLabel>
                  <StyledSelect
                    variant='outlined'
                    name='status'
                    onChange={formik.handleChange}
                    defaultValue={formik.initialValues.status}
                    IconComponent={FieldArrowDown}
                    error={!!formik.errors.status}
                    MenuProps={{
                      PaperProps: {
                        square: true,
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Object.keys(EProjectStatus).map((key) => (
                      <AppMenuItem
                        value={
                          EProjectStatus[key as keyof typeof EProjectStatus]
                        }
                      >
                        {t(`projectStatus.${key}`)}
                      </AppMenuItem>
                    ))}
                  </StyledSelect>
                </FormItemRow>

                <FormItemRow>
                  <AppInputLabel>
                    {t('page.projectEdit.statusChangeReasonField')}
                  </AppInputLabel>
                  <AppInput
                    type='text'
                    onChange={formik.handleChange}
                    name='comment'
                    defaultValue={formik.initialValues.comment}
                    error={!!formik.errors.comment}
                  />
                </FormItemRow>
              </>
            )}
            {user.profile && user.profile.role === 'admin' && (
              <FormItemRow>
                <AppInputLabel>{t('words.partner')}</AppInputLabel>
                <AppInput
                  innerRef={popperRef}
                  //@ts-ignore
                  value={partnerSearch}
                  inputProps={{
                    style: { cursor: 'pointer' },
                  }}
                  style={{ width: 227 }}
                  contentEditable={false}
                  error={!!formik.errors.partner}
                  // style={{width: 190}}
                  endAdornment={
                    <FieldArrowDown
                      style={{
                        transform: !!searchAnchor
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                      onClick={() => setSearchAnchor((s) => !s)}
                      className={classes.smallArrow}
                    />
                  }
                  onFocus={(e) => setSearchAnchor(true)}
                />
                <Popper
                  placement='bottom-start'
                  anchorEl={popperRef?.current}
                  open={searchAnchor}
                >
                  <Paper square>
                    <AppContextMenu>
                      <InfiniteScroll
                        loader={null}
                        style={{ maxHeight: 232 }}
                        dataLength={
                          data?.pages
                            ? data.pages.map((page) => page.items).flat().length
                            : 0
                        }
                        hasMore={!!hasNextPage}
                        next={() => {
                          setPage((p) => p + 1);
                          fetchNextPage({
                            pageParam: page,
                          });
                        }}
                      >
                        {!isLoading &&
                          data?.pages &&
                          data.pages
                            .map((page) => page.items)
                            .flat()
                            .map((i) => (
                              <AppMenuItem
                                style={{ minWidth: 200 }}
                                value={i.id}
                                key={i.id}
                                onClick={() => handlePartnerSearchClick(i)}
                              >
                                {i.name}
                              </AppMenuItem>
                            ))}
                      </InfiniteScroll>
                    </AppContextMenu>
                  </Paper>
                </Popper>
              </FormItemRow>
            )}
            <FormItemRow>
              <AppInputLabel>{t('words.city')}</AppInputLabel>
              <AppInput
                innerRef={popperRef}
                //@ts-ignore
                value={citySearch}
                inputProps={{
                  style: { cursor: 'pointer' },
                }}
                style={{ width: 227 }}
                contentEditable={false}
                // style={{width: 190}}
                endAdornment={
                  <FieldArrowDown
                    style={{
                      transform: !!searchCityAnchor
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }}
                    onClick={() => setSearchCityAnchor((s) => !s)}
                    className={classes.smallArrow}
                  />
                }
                onFocus={(e) => setSearchCityAnchor(true)}
                error={!!formik.errors.city}
              />
              <Popper
                placement='bottom-start'
                anchorEl={popperRef?.current}
                open={searchCityAnchor}
              >
                <Paper square>
                  <AppContextMenu>
                    <InfiniteScroll
                      loader={null}
                      style={{ maxHeight: 232 }}
                      dataLength={
                        cityData?.pages
                          ? cityData.pages.map((page) => page.items).flat()
                              .length
                          : 0
                      }
                      hasMore={!!cityHasNextPage}
                      next={() => {
                        setCityPage((p) => p + 1);
                        cityFetchNextPage({
                          pageParam: cityPage,
                        });
                      }}
                    >
                      {!cityIsLoading &&
                        cityData?.pages &&
                        cityData.pages
                          .map((page) => page.items)
                          .flat()
                          .map((i) => (
                            <AppMenuItem
                              style={{ minWidth: 200 }}
                              value={i.id}
                              key={i.id}
                              onClick={() => handleCitySearchClick(i)}
                            >
                              {i.name}
                            </AppMenuItem>
                          ))}
                    </InfiniteScroll>
                  </AppContextMenu>
                </Paper>
              </Popper>
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>{t('page.projectEdit.department')}</AppInputLabel>
              <AppInput
                type='text'
                onChange={formik.handleChange}
                name='department'
                defaultValue={formik.initialValues.department}
                error={!!formik.errors.department}
              />
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>
                {t('page.projectEdit.customerField')}
              </AppInputLabel>
              <AppInput
                type='text'
                onChange={formik.handleChange}
                name='customer'
                defaultValue={formik.initialValues.customer}
                error={!!formik.errors.customer}
              />
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>
                {t('page.projectEdit.subjectField')}
              </AppInputLabel>
              <AppInput
                type='text'
                onChange={formik.handleChange}
                name='subject'
                defaultValue={formik.initialValues.subject}
                error={!!formik.errors.subject}
              />
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>
                {t('page.projectEdit.requestedAmountField')}
              </AppInputLabel>
              <MoneyInput
                type='text'
                onChange={formik.handleChange}
                name='requestedAmount'
                defaultValue={formik.initialValues.requestedAmount}
                error={!!formik.errors.requestedAmount}
              />
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>
                {t('page.projectEdit.actualAmountField')}
              </AppInputLabel>
              <MoneyInput
                type='text'
                onChange={formik.handleChange}
                name='actualAmount'
                defaultValue={formik.initialValues.actualAmount}
                error={!!formik.errors.actualAmount}
              />
            </FormItemRow>
            <FormItemRow>
              <AppInputLabel>{t('page.projectEdit.dealAtField')}</AppInputLabel>
              <DateInput
                onDatePick={(e) => {
                  // if (
                  //   e < new Date() &&
                  //   user.profile?.role !== EUserRole.admin
                  // ) {
                  //   enqueueSnackbar(
                  //     t('toastNotifications.featureDate'),
                  //     { variant: 'error' }
                  //   );
                  //
                  //   return;
                  // }
                  formik.setFieldValue('dealAt', (e as Date).toString());
                }}
                name='dealAt'
                defaultValue={formik.values.dealAt!}
                lang={i18n.language}
                disabledDays={
                  config.service === ServiceType.Spitch
                    ? [
                        {
                          from: new Date(1970, 1),
                          to: new Date(
                            new Date().setDate(new Date().getDate() - 1)
                          ),
                        },

                        {
                          from: addDaysToDate(expireDiff, 1),
                          to: new Date(3000, 1),
                        },
                      ]
                    : [
                        {
                          from: new Date(1970, 1),
                          to: new Date(
                            new Date().setDate(new Date().getDate() - 1)
                          ),
                        },
                      ]
                }
              />
            </FormItemRow>

            {/*{user.profile?.role === EUserRole.admin ? (*/}
            {/*  <>*/}
            <FormItemRow>
              <AppInputLabel>
                {t('page.projectEdit.dateCreatedField')}
              </AppInputLabel>
              <DateInput
                onDatePick={(e) => {
                  formik.setFieldValue('createdAt', (e as Date).toString());
                }}
                name='createdAt'
                defaultValue={formik.values.createdAt}
                lang={i18n.language}
                type='datetime'
                disabled={
                  config.service === ServiceType.Spitch ||
                  user.profile?.role !== EUserRole.admin
                }
                disabledDays={[
                  {
                    from: addDaysToDate(new Date(), 1),
                    to: new Date(3000, 1),
                  },
                ]}
              />
            </FormItemRow>

            <FormItemRow>
              <AppInputLabel>{t('page.projects.expireAt')}</AppInputLabel>

              <StyledAppDatePreviewInput
                type='date'
                value={format(
                  config.service === ServiceType.Spitch
                    ? new Date(formik.values.expireAt)
                    : addDaysToDate(
                        formik.values.dealAt
                          ? new Date(formik.values.dealAt)
                          : new Date(),
                        config.adminProjectExpireInDays
                      ),
                  'yyyy-MM-dd'
                )}
                startAdornment={
                  <CalendarIcon
                    style={{
                      cursor: 'default',
                    }}
                  />
                }
                endAdornment={null}
                disabled
              />

              {/*<DateInput*/}
              {/*  onDatePick={() => {}}*/}
              {/*  name='expireAt'*/}
              {/*  key={formik.values.expireAt}*/}
              {/*  defaultValue={*/}
              {/*    config.service === ServiceType.Spitch*/}
              {/*      ? formik.values.expireAt*/}
              {/*      : addDaysToDate(*/}
              {/*          new Date(formik.values.expireAt),*/}
              {/*          config.adminProjectExpireInDays*/}
              {/*        ).toString()*/}
              {/*  }*/}
              {/*  lang={i18n.language}*/}
              {/*  type='datetime'*/}
              {/*  disabled*/}
              {/*/>*/}
            </FormItemRow>
            {/*  </>*/}
            {/*) : null}*/}
            <Box mb={2}>
              <Typography variant='h4'>
                {t('page.projectEdit.contractField')}
              </Typography>
            </Box>
            <FormItemRow>
              <AppInputLabel>{t('page.projectEdit.choose')}</AppInputLabel>
              <ManagerUserEmployeeSelect
                partner={formik.values.partner ?? ''}
                defaultValue={formik.values.manager}
                onChange={(value) => formik.setFieldValue('manager', value)}
                isAdmin={user.profile?.role === EUserRole.admin}
                error={!!formik.errors.manager}
              />
            </FormItemRow>
          </FormWrap>

          <Box mb={2}>
            <Typography variant='h4'>
              {t('page.projectEdit.documentationDropZone')}
            </Typography>
          </Box>
          <Grid container item direction='row' justify='space-between'>
            <Grid item xs={7}>
              {files.map((f, i) => (
                <LoadingFile
                  onLoadSuccess={(fileResponse) =>
                    handleLoadSuccess(fileResponse, f)
                  }
                  key={f.name + i}
                  file={f}
                />
              ))}
              {loadedFiles.map((lf) => (
                <FileCard key={lf.id} file={lf} onDelete={handleFileDelete} />
              ))}
            </Grid>
            <Hidden smDown>
              <Grid item>
                <Box width='232px' height='320px'>
                  <FileDropZone
                    onChange={handleNewFiles}
                    allowedFileExtensions={[]}
                    allowedMimeTypes={[]}
                  />
                </Box>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <FileLoadZone onChange={handleNewFiles} />
            </Hidden>
          </Grid>
          <Box mb={2} />
          <AppButton
            onClick={handleSubmit}
            style={{ height: 56 }}
            variant='contained'
            color='primary'
            fullWidth
          >
            {current
              ? t('page.projectEdit.saveProject')
              : t('page.projectEdit.addProject')}
          </AppButton>
        </Box>
      </Paper>
    </Grid>
  );
};
