import React from 'react';
import { Route, RouteProps } from 'react-router';
import MainLoader from '../components/mainLoader';
import { useAppSelector } from '../hooks/useStore';
import SigninPage from '../pages/login/signin';
import { EUserRole } from '../types/user.interface';
import { Redirect } from 'react-router-dom';

export const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const user = useAppSelector((state) => state.user);

  if (
    user.profile &&
    user.profile.role !== EUserRole.admin &&
    !user.profile.isAgreementAccepted
  ) {
    return <Redirect to='/agreement' />;
  }

  if (user.status === 'authorized') {
    return <Route {...props} />;
  }
  if (user.status === 'unknown' || user.status === 'pending') {
    return <MainLoader />;
  }
  return <SigninPage />;
};

export default PrivateRoute;
