import { Box, styled, Typography } from '@material-ui/core';
import React from 'react';
import { AppWhiteLinkButton } from '../components/core/buttons';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const ErrorCode = styled(Typography)(({ theme }) => ({
  fontSize: 220,
  lineHeight: '220px',
}));

function makeText(error: string) {
  switch (error) {
    case '403':
      return i18n.t('page.errorHandler.insufficientPermissions');
    case '404':
      return i18n.t('page.errorHandler.notFound');
    case '500':
    default:
      return i18n.t('page.errorHandler.serverError');
  }
}

export const ErrorHandler: React.FC<{ error: string }> = ({
  error = '500',
}) => {
  const { t } = useTranslation();

  return (
    <Box
      width='100vw'
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Box mb={3}>
        <Typography variant='h1' color='textSecondary'>
          {makeText(error)}
        </Typography>
      </Box>
      {['403', '404'].includes(error) && (
        <AppWhiteLinkButton to='/'>
          {t('page.errorHandler.headToIndex')}
        </AppWhiteLinkButton>
      )}
      <Box mb={5} />
      <Typography variant='h3' color='textSecondary'>
        {t('page.errorHandler.errorText')}
      </Typography>
      <ErrorCode className='gradient-text'>{error}</ErrorCode>
    </Box>
  );
};

export default ErrorHandler;
