import i18n from '../i18n';
import IFile from './file.interface';

export type TMaterialCategoryType = 'sales' | 'tech' | 'ads' | 'partnership' | 'cases';

export const materialTypeDict = {
  ads: i18n.t('materialType.ads'),
  tech: i18n.t('materialType.tech'),
  sales: i18n.t('materialType.sales'),
  partnership: i18n.t('materialType.partnership'),
  cases: i18n.t('materialType.cases'),
};
export interface IMaterialGroup {
  id: string;
  title: string;
  sort: number;
  category: IMaterialCategory;
  materials: Array<IMaterial>;
}

export interface IMaterialCategory {
  type: TMaterialCategoryType;
  id: string;
  title: string;
  // sort: number;
  groups: Array<IMaterialGroup>;
}

export interface IMaterial {
  id: string;
  name: string;
  type: 'file' | 'url';
  group: IMaterialGroup;
  sort: number;
  publicId?: string;
  file?: IFile;
  url?: string;
  isActual?: boolean;
  createdAt?: number;
}
