import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PenIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7507 0.439327C12.3365 -0.146459 13.2863 -0.146459 13.872 0.439327L15.2863 1.85354C15.872 2.43933 15.872 3.38907 15.2863 3.97486L5.3033 13.9578L0 15.7256L1.76777 10.4223L11.7507 0.439327ZM13.1649 1.14643C12.9697 0.951172 12.6531 0.951172 12.4578 1.14643L10.69 2.91427L12.8113 5.03559L14.5792 3.26775C14.7744 3.07249 14.7744 2.75591 14.5792 2.56065L13.1649 1.14643ZM12.1042 5.74269L9.98289 3.62137L2.9118 10.6925L5.03312 12.8138L12.1042 5.74269ZM1.58114 14.1444L2.42325 11.6181L4.10747 13.3023L1.58114 14.1444Z'
      />
    </SvgIcon>
  );
};

export default PenIcon;
