import i18n from './../i18n';
import { EUserRole } from './user.interface';

export const roleDict = Object.keys(EUserRole).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: i18n.t(`role.${curr}`),
  }),
  {}
) as Record<EUserRole, string>;

export interface IEmployeesSearchParams {
  search?: string;
  partner?: string;
  role?: EUserRole;
  includesMe?: boolean;
  listEverything?: boolean;
}
