import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowDown: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 10 6'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.646484 1.35359L1.35359 0.646484L5.00004 4.29293L8.64648 0.646484L9.35359 1.35359L5.00004 5.70714L0.646484 1.35359Z'
      />
    </SvgIcon>
  );
};
