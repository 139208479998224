import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FilterIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 18 12'>
      <path d='M0 0H18V2H0V0Z' fill='white' fillOpacity='0.8' />
      <path d='M3 5H15V7H3V5Z' fill='white' fillOpacity='0.8' />
      <path d='M11 10H7V12H11V10Z' fill='white' fillOpacity='0.8' />
    </SvgIcon>
  );
};

export default FilterIcon;
