import { Button, ButtonProps, styled } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import PenIcon from './icons/pen';

export const AppButton: React.ComponentType<ButtonProps> = styled(Button)(
  ({ theme, color, variant }) => ({
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: 'Proxima Nova Lt',
    borderRadius: 4,
    '&.MuiButton': {
      textTransform: 'capitalize',
      '&-sizeLarge': {
        fontSize: 16,
        lineHeight: 1.5,
      },
      '&-sizeSmall': {
        fontSize: 13,
        lineHeight: 1.5,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
  })
);

AppButton.defaultProps = {
  disableRipple: true,
  disableElevation: true,
};

export const AppWhiteLinkButton = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  padding: '6px 24px',
  borderRadius: 4,
  fontFamily: 'Proxima Nova Lt',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '20px',
  whiteSpace: 'nowrap',
}));

export const AppWhiteButton = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  padding: '6px 24px',
  borderRadius: 4,
  fontFamily: 'Proxima Nova Lt',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '20px',
  '&:disabled': {
    background: '#403E65',
    color: '#86859F',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
}));

const NewsEditPenButton = styled(Button)(({ theme }) => ({
  width: 40,
  height: 40,
  minWidth: 40,
  minHeight: 40,
  borderRadius: 20,
  border: '1px solid #E8E8E8',
  background: '#fff',
  '& svg': {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
}));

export const NewsEditPen: React.FC<ButtonProps> = ({ ...etc }) => {
  return (
    <NewsEditPenButton>
      <PenIcon />
    </NewsEditPenButton>
  );
};

export default AppButton;
