import {
  Box,
  Drawer,
  makeStyles,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import { CancelOutlined, KeyboardArrowDown } from '@material-ui/icons';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeesSearchBarProps } from '.';
import { useAppSelector } from '../../hooks/useStore';
import { roleDict } from '../../types/employees.interface';
import { EUserRole } from '../../types/user.interface';
import { AppMenuItem } from '../core/contextMenu';
import CloseIcon from '../core/icons/close';
import FilterIcon from '../core/icons/filter';
import AppInput from '../core/outlinedInput';

const useStyles = makeStyles(() => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
  pointerText: {
    cursor: 'pointer',
  },
  searchParamsRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  width: '100%',
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const SearchBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  height: 27,
  borderRadius: 33,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 12px',
  marginRight: 8,
  marginBottom: 8,
  '&.all': {
    backgroundColor: '#7891F8',
    color: '#fff',
  },
  '& .co': {
    marginLeft: 5,
    fontSize: 14,
  },
}));

interface ProjectsSearchBarPropsMobile extends EmployeesSearchBarProps {
  dropAll: () => void;
}

export const EmployeesSearchbarMobile: React.FC<
  ProjectsSearchBarPropsMobile
> = ({ searchParams, onRolePick, onSearch, dropAll }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { profile } = useAppSelector((state) => state.user);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const debouncedChangeHandler = debounce(changeHandler, 300);
  return (
    <>
      <Box m={2.5}>
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Typography
            style={{ display: 'inline-flex', alignItems: 'center' }}
            variant='h4'
            color='textSecondary'
            onClick={() => setDrawerOpen(true)}
          >
            <FilterIcon style={{ marginRight: 11 }} />{' '}
            {t('component.employeeSearchbar.filtersLable')}
          </Typography>
        </Box>

        <Box className={classes.searchParamsRoot} mt={2}>
          {searchParams.role && (
            <SearchBox onClick={() => onRolePick(undefined)}>
              <span>{roleDict[searchParams.role]}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
          {searchParams.search && (
            <SearchBox onClick={() => onSearch('')}>
              <span>{searchParams.search}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
          {Object.values(searchParams).filter((val) => val !== undefined)
            .length > 0 && (
            <SearchBox onClick={dropAll} className='all'>
              <span>{t('component.employeeSearchbar.clear')}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
        </Box>
      </Box>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onBackdropClick={() => setDrawerOpen(false)}
      >
        <Box p={3}>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='nowrap'
            justifyContent='space-between'
          >
            <Typography variant='h4'>
              {t('component.employeeSearchbar.filtersLable')}
            </Typography>
            <CloseIcon onClick={() => setDrawerOpen(false)} />
          </Box>
          <Box mb={2} />
          <AppInput
            placeholder={t('words.search')}
            onChange={debouncedChangeHandler}
          />
          <Box mb={2} />
          <StyledSelect
            variant='outlined'
            name='role'
            placeholder={t('component.employeeSearchbar.status')}
            onChange={(e) => {
              if (e.target.value === 'all') {
                onRolePick(undefined);
              } else {
                //@ts-ignore
                onRolePick(e.target.value);
              }
            }}
            defaultValue={searchParams.role || 'all'}
            IconComponent={KeyboardArrowDown}
          >
            <AppMenuItem value={'all'}>{t('role.all')}</AppMenuItem>
            <AppMenuItem value={EUserRole.partner}>
              {t('role.partner')}
            </AppMenuItem>
            <AppMenuItem value={EUserRole.employee}>
              {t('role.employee')}
            </AppMenuItem>
            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.admin}>
                {t('role.admin')}
              </AppMenuItem>
            ) : null}
            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.customer}>
                {t('role.customer')}
              </AppMenuItem>
            ) : null}
            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.customer}>
                {t('role.customer')}
              </AppMenuItem>
            ) : null}

            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.partner_admin}>
                {t('role.partner_admin')}
              </AppMenuItem>
            ) : null}

            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.documentation_admin}>
                {t('role.documentation_admin')}
              </AppMenuItem>
            ) : null}

            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.end_customer}>
                {t('role.end_customer')}
              </AppMenuItem>
            ) : null}

            {profile && profile.role === EUserRole.admin ? (
              <AppMenuItem value={EUserRole.internal_team}>
                {t('role.internal_team')}
              </AppMenuItem>
            ) : null}
          </StyledSelect>
        </Box>
      </Drawer>
    </>
  );
};
