import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExitIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 15 15'>
      <path
        d='M1.5 0C0.671573 0 0 0.671573 0 1.5V13.5C0 14.3284 0.671573 15 1.5 15H9V14H1.5C1.22386 14 1 13.7761 1 13.5V1.5C1 1.22386 1.22386 1 1.5 1H9V0H1.5Z'
        fill='white'
      />
      <path
        d='M10.3637 10.2932L11.0708 11.0003L14.6064 7.46473L11.0708 3.9292L10.3637 4.63631L12.692 6.96458L5 7.00016V8.00016L12.6923 7.96458L10.3637 10.2932Z'
        fill='white'
      />
    </SvgIcon>
  );
};

export default ExitIcon;
