import { styled } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  padding: '8px 16px',
  textDecoration: 'none',
  borderBottom: '2px solid #fff',
  marginBottom: -2,
  whiteSpace: 'nowrap',
  '& h5': {
    lineHeight: '20px',
  },
  '&:hover': {
    background: 'rgba(255,255,255,.2)',
    color: '#fff',
  },
  '&.active': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

export default StyledNavLink;
