import axios from 'axios';
import config from '../app.config';
import { getToken } from '../utils/token';

const http = axios.create({
  baseURL: config.apiBasePAth,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  // withCredentials: true,
});

export function setAuthToken(token: string) {
  return http.interceptors.request.use((config) => {
    config.headers['X-Auth-Token'] = token;
    return config;
  });
}

http.interceptors.request.use((config) => {
  config.headers['X-Auth-Token'] = getToken();
  return config;
});

export default http;
