import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import partner from '../api/partner';
import PartnerEditForm from '../components/forms/partner';
import MainLoader from '../components/mainLoader';
import PartnerProfile from '../components/partnerProfile';

export const CompanyProfile: React.FC = () => {
  const partnerQuery = useQuery('partnerMe', partner.me);
  const [isEditing, setEditing] = useState(false);

  if (partnerQuery.isLoading || !partnerQuery.data) return <MainLoader />;
  return (
    <Grid container item direction='column' alignItems='center'>
      {!isEditing ? (
        <PartnerProfile
          stats={partnerQuery.data.stats}
          partner={partnerQuery.data.partner}
          onLogoUploaded={() => partnerQuery.refetch()}
          isEditing={isEditing}
          setEditing={setEditing}
          onUpdateFiles={async (files) => {
            await partner.update({
              ...partnerQuery.data.partner,
              files: files.map((v) => v.id),
              countries: partnerQuery.data.partner.countries.map((v) => v.id),
              manager: partnerQuery.data.partner.manager?.id,
            });
            await partnerQuery.refetch();
          }}
        />
      ) : (
        <PartnerEditForm
          onDone={() => setEditing(false)}
          data={partnerQuery.data.partner}
        />
      )}
    </Grid>
  );
};

export default CompanyProfile;
