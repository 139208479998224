import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RestoreIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 12 14'>
      <path d='M6.76782 0L7.47485 0.707031L6.2041 1.97803C9.42334 2.08545 12 4.729 12 7.97461C12 11.2881 9.31372 13.9746 6 13.9746C2.68628 13.9746 0 11.2881 0 7.97461H1C1 10.7358 3.23853 12.9746 6 12.9746C8.76147 12.9746 11 10.7358 11 7.97461C11 5.28418 8.87476 3.08984 6.21118 2.979L7.47485 4.24268L6.76782 4.94971L4.29297 2.4751L6.76782 0Z' />
    </SvgIcon>
  );
};

export default RestoreIcon;
