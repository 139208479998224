import {
  Box,
  Drawer,
  Typography,
  styled,
  Select,
  makeStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import docs from '../../api/docs';
import IFile from '../../types/file.interface';
import AppButton from '../core/buttons';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FileCard from '../fileCard';
import { FileDropZone } from '../fileDropZone';
import FormItemRow from '../forms/formRow';
import LoadingFile from '../loadingFile';
import { useTranslation } from 'react-i18next';
import city from '../../api/city';

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const FatDot = styled('div')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'black',
});

const SelectLike = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  paddingRight: 6,
  height: 40,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #E8E8E8',
  boxSizing: 'border-box',
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
    cursor: 'poniter',
    position: 'absolute',
  },
  '&:hover': {
    border: '1px solid #000',
  },
}));

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 13,
    minWidth: 65,
    lineHeight: '20px',
    flex: 0,
  },
  mi: {
    '& .selected': {
      fontWeight: 600,
    },
  },
  firstRow: {
    marginBottom: 16,
  },
  secondRow: {
    marginBottom: 0,
  },
  btn: {
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#fff',
  },
  exBtn: {
    padding: 0,
    paddingLeft: 16,
    paddingTop: 14,
    '& > svg': {
      fontSize: 8,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  saveBtn: {
    padding: '10px 24px',
    marginTop: theme.spacing(3),
  },
}));

interface MaterialFileDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const MaterialCityDrawer: React.FC<MaterialFileDrawerProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [name, setName] = useState<string>('');

  const qc = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const cityCreate = useMutation('citiesManage', city.create, {
    onSuccess: () => {
      enqueueSnackbar(t('toastNotifications.cityCreated'), {
        variant: 'info',
      });
      qc.invalidateQueries('citiesManage');
      onClose();
    },
    onError: (err) => {
      //@ts-ignore
      enqueueSnackbar((err as any).response.data.errors[0].reason, {
        variant: 'error',
      });
    },
  });

  const handleCityDocument = () => {
    cityCreate.mutate({
      name,
    });
  };

  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 487 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Box mx={3} mt={3}>
        <Box mb={2}>
          <Typography variant='h3'>
            {t('component.addCityDrawer.createHeadline')}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column' mt={3}>
          <FormItemRow>
            <AppInputLabel>{t('component.addCityDrawer.title')}</AppInputLabel>

            <AppInput onChange={(v) => setName(v.target.value)} />
          </FormItemRow>
          <Box mt={1}>
            <FormItemRow>
              <AppInputLabel />
              <AppButton
                disabled={!name}
                variant='contained'
                color='primary'
                onClick={handleCityDocument}
              >
                {t('button.add')}
              </AppButton>
            </FormItemRow>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
