import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RegPage from '../pages/login/reg';
import SigninPage from '../pages/login/signin';

export const AuthRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`/auth/signin`} component={SigninPage} exact />
      <Route path={`/auth/partner-signup`} component={RegPage} />
      <Route path='*'>
        <Redirect to='404' />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
