import { Collapse, Grid, Hidden, makeStyles, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import employee from '../../api/employee';
import employeeApi from '../../api/employee';
import EmployeeCreateForm from '../../components/forms/employee';
import EmployeesList from '../../components/lists/employeesList';
import IUser from '../../types/user.interface';
import EmployeesSearchbar from '../../components/employeesSearchbar';
import { EmployeesSearchbarMobile } from '../../components/employeesSearchbar/mobile';
import { IEmployeesSearchParams } from '../../types/employees.interface';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
}));

export const EmployeesMainPage: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [showForm, setShowForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState<IEmployeesSearchParams>({});
  const qc = useQueryClient();
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['employees', searchParams],
    ({ pageParam }) => employee.list(pageParam, searchParams),
    {
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
      suspense: false,
    }
  );
  const createEmp = useMutation('employees', employee.create, {
    onSuccess: () => {
      qc.invalidateQueries('employees');
      setShowForm(false);
      enqueueSnackbar(t('toastNotifications.employeeCreated'), {
        variant: 'info',
      });
    },
    onError: (err) => {
      //@ts-ignore
      let a = (err as any).response.data.errors
        .map((er: any) => er.reason)
        .join(',');
      enqueueSnackbar(a, { variant: 'error' });
    },
  });
  const updateEmp = useMutation('employees', employee.update, {
    onSuccess: () => {
      qc.invalidateQueries('employees');
      enqueueSnackbar(t('toastNotifications.employeeUpdated'), {
        variant: 'info',
      });
    },
    onError: (err) => {
      //@ts-ignore
      let a = (err as any).response.data.errors
        .map((er: any) => er.reason)
        .join(',');
      enqueueSnackbar(a, { variant: 'error' });
    },
  });
  const deleteEmp = useMutation('employees', employeeApi.deleteSelf);

  const handleDelete = (id: string) => {
    deleteEmp.mutate(id, {
      onSuccess: () => qc.invalidateQueries('employees'),
    });
  };
  const handleCreate = (user: Omit<IUser, 'id'>) => {
    createEmp.mutate(user);
    qc.invalidateQueries('employees');
  };

  return (
    <Grid container item direction='column'>
      <Hidden smDown>
        <EmployeesSearchbar
          onToggleCreateUserForm={() => {
            setShowForm(!showForm);
          }}
          searchParams={searchParams}
          onPartnerPick={(partner) => {
            setSearchParams((sp) => ({
              ...sp,
              partner: partner,
            }));
          }}
          onRolePick={(role) =>
            setSearchParams((sp) => ({
              ...sp,
              role: role,
            }))
          }
          onSearch={(search) =>
            setSearchParams((sp) => ({
              ...sp,
              search: search,
            }))
          }
        />
      </Hidden>
      <Hidden mdUp>
        <EmployeesSearchbarMobile
          searchParams={searchParams}
          dropAll={() => {
            setSearchParams({});
          }}
          onPartnerPick={(partner) => {
            setSearchParams((sp) => ({
              ...sp,
              partner,
            }));
          }}
          onRolePick={(role) =>
            setSearchParams((sp) => ({
              ...sp,
              role,
            }))
          }
          onSearch={(search) =>
            setSearchParams((sp) => ({
              ...sp,
              search,
            }))
          }
          onToggleCreateUserForm={() => {}}
        />
      </Hidden>
      <Collapse in={showForm} unmountOnExit>
        <EmployeeCreateForm onSubmit={handleCreate} />
      </Collapse>
      <Grid item container className={classes.listRoot}>
        <Paper
          square
          style={{ flex: '1 1 100vh', minHeight: '100vh', overflowX: 'auto' }}
        >
          {data && (
            <EmployeesList
              onEdit={updateEmp.mutate}
              onDelete={handleDelete}
              items={data.pages.map((page) => page.items).flat()}
              fetchNext={() => {
                setPage((p) => p + 1);
                fetchNextPage({
                  pageParam: page,
                });
              }}
              hasMore={!!hasNextPage}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EmployeesMainPage;
