import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import React, { useState } from 'react';
import AppButton from '../../components/core/buttons';
import { acceptAgreement, logout } from '../../store/user/actions';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import { Redirect } from 'react-router-dom';
import { EUserRole } from '../../types/user.interface';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  paragraph: {
    lineHeight: '19px',
    marginTop: 0,
  },
  checkbox: {
    color: theme.palette.primary.main,
    marginBottom: 15,
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export const Agreement: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const [agreementAccept, setAgreementAccept] = useState(false);

  const dispatch = useAppDispatch();
  const onAccept = () => {
    dispatch(acceptAgreement());
  };

  if (
    user.profile?.isAgreementAccepted ||
    user.profile?.role === EUserRole.admin
  ) {
    return <Redirect to='/' />;
  }

  return (
    <Grid container alignItems='center' justify='center' direction='column'>
      <Box
        height='100vh'
        maxWidth='380px'
        overflow='hidden'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        display='flex'
      >
        <img src={t('theme.logo', { returnObjects: true })} alt='bss logo' />
        <Box mt='18px'>
          <Paper elevation={0} square color='white'>
            <Box padding={6} flexDirection='column' display={'flex'}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={agreementAccept}
                    onChange={() => setAgreementAccept(!agreementAccept)}
                    className={classes.checkbox}
                    name='gilad'
                  />
                }
                label={
                  <p className={classes.paragraph}>
                    {t('page.loginAgreement.forContinueYouShouldAccept')}{' '}
                    <a
                      className={classes.link}
                      target='_blank'
                      href={t('agreementLink')} rel="noreferrer"
                    >
                      {t('page.loginAgreement.acceptAgreement')}
                    </a>
                    .
                  </p>
                }
              />

              <AppButton
                onClick={() => onAccept()}
                variant='contained'
                disabled={!agreementAccept}
                style={{ paddingTop: 10, paddingBottom: 10 }}
                type='submit'
                color='primary'
              >
                {t('button.continue')}
              </AppButton>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Grid>
  );
};

export default Agreement;
