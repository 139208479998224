import {
  Box,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from '@material-ui/core';
import React from 'react';

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  flex: 1,
  lineHeight: '24px',
  height: 40,
  fontSize: 16,
  borderRadius: 4,
  '&.MuiOutlinedInput-multiline': {
    minHeight: 40,
    height: 'auto',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 16px',
  },
  '&.MuiOutlinedInput-root': {
    borderColor: '#E8E8E8',
    '&.Mui-focused fieldset': {
      borderWidth: 1,
      borderColor: '#E8E8E8',
      boxShadow: 'none',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E8E8E8',
  },
}));

const Wrap = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  '& > .form-helper': {
    position: 'absolute',
    left: 0,
    bottom: '-1.65em',
    transition: 'all 200ms',
    maxHeight: '1em',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

interface AppInputProps {
  helperText?: string;
}

const AppInput: React.FC<OutlinedInputProps & AppInputProps> = ({
  helperText,
  error,
  ...props
}) => {
  return (
    <Wrap>
      <StyledOutlinedInput {...props} error={error} />
      <FormHelperText className='form-helper' error={error}>
        {helperText}
      </FormHelperText>
    </Wrap>
  );
};

export default AppInput;
