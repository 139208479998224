import {
  Box,
  Grid,
  makeStyles,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import IUser, { EUserRole } from '../../types/user.interface';
import AppButton from '../core/buttons';
import PenIcon from '../core/icons/pen';
import RestoreIcon from '../core/icons/restore';
import TrashBinIcon from '../core/icons/trashBin';
import AppInput from '../core/outlinedInput';
import * as yup from 'yup';
import ShortInput from '../inputs/short';
import MaskedPhone from '../inputs/maskedPhone';
import { formatPhoneNumber, phoneToValid } from '../../utils/etc';
import { useRef } from 'react';
import { useAppSelector } from '../../hooks/useStore';
import { useTranslation } from 'react-i18next';
import { AppMenuItem } from '../core/contextMenu';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import { t } from 'i18next';
import { dateFormatter } from '../../utils/dateTimeFormatter';

const useStyles = makeStyles((theme) => ({
  employeesTable: {
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    flexDirection: 'column',
    '& thead': {
      borderTop: '1px solid #909090',
      '& th': {
        background: '#fff',
        color: '#909090',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    '& .container': {
      overflowX: 'hidden',
    },
    '& .name': {
      width: 200,
    },
    '& .position': {
      width: 160,
    },
    '& .email': {
      width: 160,
    },
    '& .phone': {
      width: 150,
    },
    '& .controls': {
      minWidth: 85,
    },
    '& td': {
      '& p.deleted': {
        textDecoration: 'line-through',
        color: '#BABABA',
      },
    },
  },

  rootPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

interface EmployeeListItemProps {
  item: IUser;
  onEdit: (employee: IUser) => void;
  onDelete: (id: string) => void;
  isEditable: boolean;
}

const EmployeeListItem: React.FC<EmployeeListItemProps> = ({
  item,
  onEdit,
  onDelete,
  isEditable
}) => {
  const [isEditing, setEditing] = useState(false);
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const formik = useFormik({
    initialValues: item,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      email: yup.string().required(t('validation.required')),
      fullName: yup.string().required(t('validation.required')),
      phone: yup.string().required(t('validation.required')),
      position: yup.string().required(t('validation.required')),
    }),
    onSubmit: (values, helpers) => {
      onEdit({
        ...values,
        phone: phoneToValid(values.phone),
      });
      setEditing(false);
    },
  });

  const handleDelete = () => {
    setEditing(false);
    onDelete(item.id);
  };

  const StyledSelect = styled(Select)({
    flex: 1,
    alignItems: 'center',
    '& svg': {
      right: 16,
      top: 'calc(50% - 6px)',
    },
    '& .MuiSelect-root': {
      padding: '8px 16px',
      paddingRight: 32,
    },
    '& fieldset': {
      borderColor: '#E8E8E8',
    },
  });

  const Label = styled('span')({
    background: "#E7636B",
    color: "#FFFFFF",
    fontSize: 10,
    padding: "2px 5px",
    borderRadius: 3,
    marginLeft: 5,
  });

  const isNew = (
    item?.createdAt?.date
      && (new Date(item.createdAt.date).valueOf() > ((new Date).valueOf() - (7 * 24 * 60 * 60 * 1000)))
  ) || false;

  return (
    <TableRow>
      <TableCell>
        {isEditing ? (
          <AppInput
            defaultValue={formik.initialValues.fullName}
            name='fullName'
            error={!!formik.errors.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        ) : (
          <>
            <Typography variant='body1' style={{ display: "inline-block" }}>{item.fullName}</Typography>
            {isNew && <Label>NEW</Label>}
          </>
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <AppInput
            defaultValue={formik.initialValues.position}
            name='position'
            error={!!formik.errors.position}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        ) : (
          <Typography variant='body1'>{item.position}</Typography>
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <AppInput
            defaultValue={formik.initialValues.email}
            name='email'
            error={!!formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        ) : (
          <Typography
            component='a'
            href={`mailto:${item.email}`}
            variant='body1'
          >
            {item.email}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <ShortInput
            error={!!formik.errors.phone}
            onChange={formik.handleChange}
            name='phone'
            inputComponent={MaskedPhone}
            defaultValue={formik.initialValues.phone}
            onBlur={formik.handleBlur}
          />
        ) : (
          <Typography variant='body1'>
            {formatPhoneNumber(item.phone)}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {
          <Typography variant='body1'>
            {
              // @ts-ignore
              item.partner_name || '-'
            }
          </Typography>
        }
      </TableCell>
      <TableCell>
        {item?.createdAt?.date && dateFormatter.format(new Date(item.createdAt.date))}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <StyledSelect
            variant='outlined'
            name='role'
            IconComponent={(props) => <FieldArrowDown {...props} />}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.role}
          >
            {user.profile && user.profile.role === EUserRole.admin ? (
              <AppMenuItem value='admin'>{t('role.admin')}</AppMenuItem>
            ) : null}

            {user.profile && user.profile.role === EUserRole.admin ? (
              <AppMenuItem value='customer'>{t('role.customer')}</AppMenuItem>
            ) : null}

            {(user.profile && user.profile.role === EUserRole.admin && false) ? (
              <AppMenuItem value='partner_admin'>
                {t('role.partner_admin')}
              </AppMenuItem>
            ) : null}

            {user.profile && user.profile.role === EUserRole.admin ? (
              <AppMenuItem value='documentation_admin'>
                {t('role.documentation_admin')}
              </AppMenuItem>
            ) : null}

            {(user.profile && user.profile.role === EUserRole.admin && false) ? (
              <AppMenuItem value='end_customer'>
                {t('role.end_customer')}
              </AppMenuItem>
            ) : null}

            {user.profile && user.profile.role === EUserRole.admin ? (
              <AppMenuItem value='internal_team'>
                {t('role.internal_team')}
              </AppMenuItem>
            ) : null}

            {user.profile && user.profile.role !== EUserRole.employee ? (
              <AppMenuItem value='partner'>{t('role.partner')}</AppMenuItem>
            ) : null}

            <AppMenuItem value='employee'>{t('role.employee')}</AppMenuItem>
          </StyledSelect>
        ) : (
          <Typography variant='body1'>{getRoleName(item.role)}</Typography>
        )}
      </TableCell>
      {isEditable ? <TableCell align={isEditing ? 'left' : 'right'}>
        {isEditing ? (
          <Box>
            <TrashBinIcon
              color='secondary'
              style={{ fontSize: 16, marginRight: 27 }}
              onClick={handleDelete}
            />
            <Done
              style={{ color: '#00B852', fontSize: 16 }}
              onClick={formik.submitForm}
            />
          </Box>
        ) : (
          <PenIcon
            color='primary'
            style={{ fontSize: 16 }}
            onClick={() => setEditing(true)}
          />
        )}
      </TableCell> : <TableCell />}
    </TableRow>
  );
};

const getRoleName: any = (role: any) => {
  let name = role;
  switch (role) {
    case EUserRole.admin: name = t('role.admin'); break;
    case EUserRole.partner: name = t('role.partner'); break;
    case EUserRole.employee: name = t('role.employee'); break;
    case EUserRole.customer: name = t('role.customer'); break;
    case EUserRole.documentation_admin: name = t('role.documentation_admin'); break;
    case EUserRole.internal_team: name = t('role.internal_team'); break;
  }
  return name;
}

interface EmployeesListProps {
  items: Array<IUser>;
  fetchNext: () => void;
  hasMore: boolean;
  onEdit: (employee: IUser) => void;
  onDelete: (id: string) => void;
}

export const EmployeesList: React.FC<EmployeesListProps> = ({
  items,
  fetchNext,
  hasMore,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const { profile } = useAppSelector((state) => state.user);
  return (
    <Grid item container className={classes.employeesTable}>
      <Box className={classes.rootPadding}>
        <TableContainer>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchNext}
            hasMore={hasMore}
            loader={null}
            height='85vh'
            scrollableTarget={ref?.current}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='name'>
                    <Typography variant='body2'>
                      {t('words.firstAndLastNames')}
                    </Typography>
                  </TableCell>
                  <TableCell className='position'>
                    <Typography variant='body2'>
                      {t('words.position')}
                    </Typography>
                  </TableCell>
                  <TableCell className='email'>
                    <Typography variant='body2'>{t('words.email')}</Typography>
                  </TableCell>
                  <TableCell className='phone'>
                    <Typography variant='body2'>{t('words.phone')}</Typography>
                  </TableCell>
                  <TableCell className='partner'>
                    <Typography variant='body2'>
                      {t('words.partner')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>
                      {t('words.createdAt')}
                    </Typography>
                  </TableCell>
                  <TableCell className='role'>
                    <Typography variant='body2'>
                      {t('words.role')}
                    </Typography>
                  </TableCell>
                  <TableCell className='controls'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody innerRef={ref}>
                {items.map((emp) => (
                  <EmployeeListItem
                    key={emp.id}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    item={emp}
                    isEditable={Boolean(profile?.role && (profile.role === EUserRole.partner || profile.role === EUserRole.admin))}
                  />
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default EmployeesList;
