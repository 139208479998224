import { useFormik } from 'formik';
import React, { useRef } from 'react';
import { Box, FormHelperText, styled, Typography } from '@material-ui/core';
import { ENewsType, IEvent, TNews } from '../../../types/news.interface';
import AppInputLabel from '../../core/label';
import AppInput from '../../core/outlinedInput';
import { FileDropZone } from '../../fileDropZone';
import PeriodPicker from '../../periodPicker';
import FormItemRow from '../formRow';
import Editor from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DateInput from '../../inputs/dateInput';
import { AppButton as AB } from '../../core/buttons';
import http from '../../../api/client';
import { ImageCard } from '../../imageCard';
import { default as newsApi } from '../../../api/news';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useHistory } from 'react-router';
import ReactQuill from 'react-quill';
import { getUnixTime } from 'date-fns';
import deleteApi from '../../../api/delete';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const tbo = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ['blockquote', 'link'],
  ['image'],
  ['clean'], // remove formatting button
];

const LocalItemRow = styled(FormItemRow)({
  alignItems: 'flex-start',
});

const CustomRadio = styled('label')(({ theme }) => ({
  marginRight: 32,
  '& input': {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  '& .typo': {
    borderColor: 'transparent',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 4,
  },
  '& input:checked ~ .typo': {
    borderColor: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
}));

const AppButton = styled(AB)({
  padding: '16px 48px',
  textTransform: 'none',
  marginRight: 16,
});

interface NewsCreateFormProps {
  news?: TNews;
  onTypeChange: (type: ENewsType) => void;
}

const initialValues = {
  id: '',
  body: '',
  createdAt: '',
  description: '',
  eventEndAt: new Date().getTime(),
  eventStartAt: new Date().getTime(),
  picture: '',
  publishAt: new Date().getTime(),
  title: '',
  type: ENewsType.news,
  deletedAt: '',
  updatedAt: '',
};

export const NewsCreateForm: React.FC<NewsCreateFormProps> = ({
  news,
  onTypeChange,
}) => {
  const [lastSaved, setLastSaved] = useLocalStorage<TNews | undefined>(
    'last_saved_news',
    undefined
  );
  const history = useHistory();
  const editorRef = useRef<ReactQuill>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const imageHandler = () => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection();
      const value = prompt(t('page.news.insertLinkPrompt'));
      if (value && range) {
        editor.insertEmbed(range.index, 'image', value, 'user');
      }
    }
  };
  const modulesRef = useRef({
    toolbar: {
      container: tbo,
      handlers: {
        image: imageHandler,
      },
    },
  });
  const formik = useFormik<TNews>({
    initialValues: news ? news : lastSaved ? lastSaved : initialValues,
    validateOnBlur: true,
    validationSchema: yup.object({
      title: yup.string().required(t('validation.required')),
      description: yup.string().required(t('validation.required')),
      body: yup.string().required(t('validation.required')),
    }),
    onSubmit: async (values, helpers) => {
      await helpers.validateForm(values);
      let newValues: TNews = {
        ...values,
        publishAt: getUnixTime(new Date(values.publishAt)),
      };
      if (values.type === ENewsType.events) {
        newValues = {
          ...newValues,
          //@ts-ignore
          eventStartAt: getUnixTime(new Date(values.eventStartAt)),
          eventEndAt: getUnixTime(new Date(values.eventEndAt)),
        };
      }
      try {
        if (news) {
          const response = await newsApi.update(newValues);
          history.push(`/news/${response.id}`);
          enqueueSnackbar(t('toastNotifications.newsUpdated'), {
            variant: 'info',
          });
        } else {
          const response = await newsApi.create(newValues);
          setLastSaved(undefined);

          history.push(`/news/${response.id}`);
          enqueueSnackbar(t('toastNotifications.newsCreated'), {
            variant: 'info',
          });
        }
      } catch (error) {
        //@ts-ignore
        let a = (err as any).response.data.errors
          .map((er: any) => er.reason)
          .join(',');
        enqueueSnackbar(a, { variant: 'error' });
      }
    },
  });
  const load = async (file: File) => {
    const fm = new FormData();
    fm.append('file', file);
    const response = await http.post('/file/upload', fm, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    formik.setFieldValue('picture', response.data.data.id);
  };

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      load(list[i]);
    }
  };

  const handleSaveAndClose = () => {
    setLastSaved(formik.values);
    if (history.action === 'POP') {
      history.goBack();
    } else {
    }
  };

  const handleDelete = async () => {
    if (news) {
      await deleteApi.news(news.id);
      history.push('/news');
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display='flex' flexDirection='row' mb={5}>
        <CustomRadio htmlFor='events'>
          <input
            defaultChecked={formik.initialValues.type === ENewsType.events}
            value={ENewsType.events}
            onChange={(e) => {
              formik.handleChange(e);
              onTypeChange(ENewsType.events);
            }}
            type='radio'
            name='type'
            id='events'
          />
          <Typography variant='h5' className='typo'>
            {t('page.newsAdd.multipleEvents')}
          </Typography>
        </CustomRadio>
        <CustomRadio htmlFor='news'>
          <input
            defaultChecked={formik.initialValues.type === ENewsType.news}
            value={ENewsType.news}
            onChange={(e) => {
              formik.handleChange(e);
              onTypeChange(ENewsType.news);
            }}
            type='radio'
            name='type'
            id='news'
          />
          <Typography variant='h5' className='typo'>
            {t('page.newsAdd.multipleNews')}
          </Typography>
        </CustomRadio>
      </Box>
      {formik.values.type === ENewsType.events && (
        <FormItemRow>
          <AppInputLabel>{t('page.newsAdd.fieldDateRange')}</AppInputLabel>
          <PeriodPicker
            startDate={+(formik.values as IEvent).eventStartAt}
            endDate={+(formik.values as IEvent).eventEndAt}
            onChange={({ from, to }) => {
              formik.setFieldValue('eventStartAt', from);
              formik.setFieldValue('eventEndAt', to);
            }}
          />
        </FormItemRow>
      )}
      <FormItemRow>
        <AppInputLabel>{t('page.newsAdd.fieldTitle')}</AppInputLabel>
        <AppInput
          error={!!formik.errors.title}
          helperText={formik.errors.title}
          name='title'
          onChange={formik.handleChange}
          defaultValue={formik.initialValues.title}
        />
      </FormItemRow>
      <LocalItemRow>
        <AppInputLabel>{t('page.newsAdd.fieldLid')}</AppInputLabel>
        <AppInput
          style={{ padding: 0 }}
          multiline
          rows={4}
          name='description'
          onChange={formik.handleChange}
          defaultValue={formik.initialValues.description}
          error={!!formik.errors.description}
          helperText={formik.errors.description}
        />
      </LocalItemRow>

      <LocalItemRow>
        <AppInputLabel>{t('page.newsAdd.fieldPicture')}</AppInputLabel>
        <Box width='100%' height='172px'>
          {formik.values.picture ? (
            <ImageCard
              //@ts-ignore
              id={news ? formik.values.picture.id : formik.values.picture}
              onDelete={(id) => {
                formik.setFieldValue('picture', '');
              }}
            />
          ) : (
            <FileDropZone
              allowedFileExtensions={[]}
              allowedMimeTypes={[]}
              onChange={handleNewFiles}
            />
          )}
        </Box>
      </LocalItemRow>
      <LocalItemRow>
        <AppInputLabel>
          {t('page.newsAdd.fieldText')}
          <FormHelperText error={!!formik.errors.body}>
            {formik.errors.body}
          </FormHelperText>
        </AppInputLabel>
        <Editor
          ref={editorRef}
          theme='snow'
          modules={modulesRef.current}
          defaultValue={formik.initialValues.body}
          onChange={(content) => formik.setFieldValue('body', content)}
        />
      </LocalItemRow>

      <FormItemRow>
        <AppInputLabel>{t('page.newsAdd.publishDate')}</AppInputLabel>
        <DateInput
          onDatePick={(e) => {
            formik.setFieldValue('publishAt', (e as Date).getTime());
          }}
          name='dealAt'
          lang={i18n.language}
          defaultValue={+formik.values.publishAt}
          id='ref'
        />
      </FormItemRow>
      {!news ? (
        <Box ml={13} mt={5}>
          <AppButton
            type='submit'
            size='large'
            variant='contained'
            color='primary'
            disabled={!formik.isValid}
          >
            {t('page.newsAdd.toPublish', {
              what:
                formik.values.type === ENewsType.events
                  ? t('page.newsAdd.event')
                  : t('page.newsAdd.news'),
            })}
          </AppButton>
          <AppButton
            size='large'
            variant='outlined'
            color='primary'
            onClick={handleSaveAndClose}
          >
            {t('page.newsAdd.saveAndClose')}
          </AppButton>
        </Box>
      ) : (
        <Box ml={13} mt={5}>
          <AppButton
            size='large'
            variant='outlined'
            color='primary'
            onClick={handleDelete}
          >
            {t('page.newsAdd.unpublish')}
          </AppButton>
          <AppButton
            size='large'
            variant='contained'
            color='primary'
            type='submit'
            disabled={!formik.isValid}
          >
            {t('page.newsAdd.saveAndClose')}
          </AppButton>
        </Box>
      )}
    </form>
  );
};

export default NewsCreateForm;
