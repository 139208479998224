import i18n from './../i18n';

const dateFormatter = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});

const timeFormatter = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  hour: '2-digit',
  minute: '2-digit',
});

const monthFormatter = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  day: '2-digit',
  month: 'short',
});

export { timeFormatter, dateFormatter, monthFormatter };
