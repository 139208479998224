import { styled, Typography } from '@material-ui/core';

export const BlockHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: 12,
  marginTop: 16,
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2),
  },
}));
BlockHeading.defaultProps = {
  variant: 'h2',
};

export default BlockHeading;
