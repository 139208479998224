import {
  Box,
  Grid,
  Input,
  makeStyles,
  styled,
  Typography,
  Select,
  debounce,
  Hidden,
} from '@material-ui/core';
import { ReactComponent as ResetIcon } from '../../assets/ico/clear.svg';
import { ReactComponent as ResetMiniIcon } from '../../assets/ico/clear_mini.svg';
import { ArrowForward } from '@material-ui/icons';
import React, { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { default as pAPI } from '../../api/partner';
import { default as cAPI } from '../../api/city';
import { AppMenuItem, AppPopover } from '../core/contextMenu';
import { ArrowDown } from '../core/icons/arrowDown';
import { CalendarIcon } from '../core/icons/calendar';
import SearchIcon from '../core/icons/search';
import {
  EProjectStatus,
  IProjectsSearchParams,
} from '../../types/project.interface';
import { ProjectsExport } from '../projectsExport';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';
import { NewDateRangePicker } from '../newDateRangePicker';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import AppButton from '../core/buttons';

const dtFormat = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  day: '2-digit',
  month: 'short',
});

const RangePickerInputBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  paddingBottom: 8,
  paddingRight: 8,
  marginRight: theme.spacing(5),
  marginBottom: 1,
  '& .small-calendar': {
    fontSize: 16,
    marginRight: 12,
    height: 20,
  },
}));

const StyledSearchInput = styled(Input)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  color: '#fff',
  paddingBottom: 8,
  cursor: 'pointer',
  width: 171,
  fontSize: 13,
  lineHeight: 1.3,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  transition: 'all 200ms',
  '& input::placeholder': {
    color: '#fff',
    opacity: 1,
  },
  '&.search': {
    '& input::placeholder': {
      color: '#fff',
      opacity: 0.5,
    },
  },
  '&.Mui-focused': {
    borderBottomColor: '#fff',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
});

const CustomSelect = styled(Select)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  fontSize: 13,
  lineHeight: 1.3,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  color: '#fff',
  cursor: 'pointer',
  paddingBottom: 8,
  width: 171,
  transition: 'all 200ms',
  '&.Mui-focused': {
    borderBottomColor: '#fff',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
});

const useStyles = makeStyles(() => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    color: '#fff',
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
  pointerText: {
    cursor: 'pointer',
  },
  resetFilter: {
    color: '#fff',
    marginLeft: 15,
  },
}));

export interface ProjectsSearchBarProps {
  searchParams: IProjectsSearchParams;
  onReset: () => void;
  onPartnerPick: (partnerId: string) => void;
  onCityPick: (cityId: string) => void;
  onStatusPick: (status?: EProjectStatus) => void;
  onDateRangePick: ({ from, to }: { from?: Date; to?: Date }) => void;
  onSearch: (value: string) => void;
}

export const ProjectsSearchBar: React.FC<ProjectsSearchBarProps> = ({
  searchParams,
  onPartnerPick,
  onCityPick,
  onStatusPick,
  onDateRangePick,
  onSearch,
  onReset,
}) => {
  const classes = useStyles();
  const { profile } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const [searchFocus, setSearchFocus] = useState(false);
  const partnerSearchQuery = useQuery(['partners'], () => pAPI.list(0), {
    enabled: profile?.role === EUserRole.admin,
    useErrorBoundary: false,
    suspense: false,
  });
  const citySearchQuery = useQuery(['cities'], () => cAPI.list(0), {
    useErrorBoundary: false,
    suspense: false,
  });
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLElement | null>(
    null
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <Box mt={2} ml={2.5} p={2}>
      <Grid container item direction='row' justify='space-between'>
        <Grid container md item direction='row'>
          <RangePickerInputBox
            onClick={(e) => setCalendarAnchor(e.currentTarget)}
          >
            <CalendarIcon className='small-calendar' />
            <Typography className={classes.pointerText} variant='subtitle1'>
              {searchParams.periodFrom
                ? dtFormat.format(new Date(searchParams.periodFrom))
                : '-- ---'}
            </Typography>
            <ArrowForward className={classes.smallArrow} fontSize='small' />
            <Typography className={classes.pointerText} variant='subtitle1'>
              {searchParams.periodTo
                ? dtFormat.format(new Date(searchParams.periodTo))
                : '-- ---'}
            </Typography>
          </RangePickerInputBox>
          {profile?.role === EUserRole.admin && (
            <CustomSelect
              placeholder={t('words.partner')}
              variant='standard'
              value={searchParams.partner || 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  onPartnerPick('');
                } else {
                  //@ts-ignore
                  onPartnerPick(e.target.value);
                }
              }}
              renderValue={(value) => (
                <span>
                  {(partnerSearchQuery.data?.items || []).find(
                    (p) => p.id === value
                  )?.name || t('words.allPartners')}
                </span>
              )}
              // renderValue
              IconComponent={(props) => (
                <ArrowDown
                  onClick={props.onClick}
                  className={`${classes.smallArrow} MuiSelect-icon `}
                />
              )}
              MenuProps={{
                PaperProps: {
                  square: true,
                  style: {
                    maxHeight: 300,
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <AppMenuItem value={'all'}>{t('words.allPartners')}</AppMenuItem>
              {!partnerSearchQuery.isError &&
                partnerSearchQuery.data?.items &&
                partnerSearchQuery.data?.items.map((i) => (
                  <AppMenuItem value={i.id} key={i.id}>
                    {i.name}
                  </AppMenuItem>
                ))}
            </CustomSelect>
          )}
          <CustomSelect
            placeholder={t('words.city')}
            variant='standard'
            value={searchParams.city || 'all'}
            style={{ marginLeft: 40 }}
            onChange={(e) => {
              if (e.target.value === 'all') {
                onCityPick('');
              } else {
                //@ts-ignore
                onCityPick(e.target.value);
              }
            }}
            renderValue={(value) => (
              <span>
                {(citySearchQuery.data?.items || []).find((p) => p.id === value)
                  ?.name || t('words.allCities')}
              </span>
            )}
            // renderValue
            IconComponent={(props) => (
              <ArrowDown
                onClick={props.onClick}
                className={`${classes.smallArrow} MuiSelect-icon `}
              />
            )}
            MenuProps={{
              PaperProps: {
                square: true,
                style: {
                  maxHeight: 300,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <AppMenuItem value={'all'}>{t('words.allCities')}</AppMenuItem>
            {!citySearchQuery.isError &&
              citySearchQuery.data?.items &&
              citySearchQuery.data?.items.map((i) => (
                <AppMenuItem value={i.id} key={i.id}>
                  {i.name}
                </AppMenuItem>
              ))}
          </CustomSelect>
          <Box ml={5} mr={5}>
            <CustomSelect
              placeholder={t('projectStatus.all')}
              variant='standard'
              value={searchParams.status || 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  onStatusPick();
                } else {
                  //@ts-ignore
                  onStatusPick(e.target.value);
                }
              }}
              IconComponent={(props) => (
                <ArrowDown
                  onClick={props.onClick}
                  className={`${classes.smallArrow} MuiSelect-icon `}
                />
              )}
              MenuProps={{
                PaperProps: {
                  square: true,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <AppMenuItem value={'all'}>{t('projectStatus.all')}</AppMenuItem>
              {Object.keys(EProjectStatus).map((key) => (
                <AppMenuItem
                  value={EProjectStatus[key as keyof typeof EProjectStatus]}
                >
                  {t(`projectStatus.${key}`)}
                </AppMenuItem>
              ))}
            </CustomSelect>
          </Box>

          <StyledSearchInput
            style={{
              width: searchFocus ? 289 : 171,
            }}
            className='search'
            value={searchParams.search}
            onChange={changeHandler}
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(false)}
            placeholder={t('words.search')}
            endAdornment={<SearchIcon />}
          />

          <Hidden mdDown>
            <AppButton
              variant='text'
              className={classes.resetFilter}
              onClick={onReset}
            >
              <ResetIcon style={{ marginRight: 5 }} />
              {t('page.projects.resetFilter')}
            </AppButton>
          </Hidden>

          <Hidden lgUp>
            <AppButton
              variant='text'
              className={classes.resetFilter}
              onClick={onReset}
            >
              <ResetMiniIcon />
            </AppButton>
          </Hidden>
        </Grid>
        <ProjectsExport searchParams={searchParams} />

        {/* <AppPopover anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <DateRangeCalendar 
          from={searchParams.periodFrom ? new Date(searchParams.periodFrom) : undefined} 
          to={searchParams.periodTo ? new Date(searchParams.periodTo) : undefined} 
          onDateSet={onDateRangePick} 
        />
      </AppPopover> */}
        <AppPopover
          onClose={() => setCalendarAnchor(null)}
          open={Boolean(calendarAnchor)}
          anchorEl={calendarAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <NewDateRangePicker
            from={
              searchParams.periodFrom
                ? new Date(searchParams.periodFrom)
                : undefined
            }
            to={
              searchParams.periodTo
                ? new Date(searchParams.periodTo)
                : undefined
            }
            onDateSet={onDateRangePick}
          />
        </AppPopover>
      </Grid>
    </Box>
  );
};

export default ProjectsSearchBar;
