import http from './client';
import ICity from '../types/city.interface';

async function create(city: Omit<ICity, 'id' | 'createdAt'>) {
  return http.post('/city/create', city);
}

async function update(city: Omit<ICity, 'createdAt'>) {
  return http.post('/city/edit', city);
}

async function del(id: string) {
  return http.post('/city/delete', { id });
}

async function list(
  page: number = 1,
  search?: {
    search?: string;
  }
): Promise<{ hasNextPage: boolean; items: Array<ICity> }> {
  const res = await http.post('/city/list', { page: page, ...search });
  return res.data.data;
}

const city = {
  create,
  list,
  update,
  del,
};

export default city;
