import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  shimmer: {
    position: 'absolute',
    textAlign: 'center',
    color: 'rgba(255,255,255,0.1)',
    background:
      'gradient(linear, left top, right top, from(rgba(0,0,0,.1)), to(rgba(0,0,0,.1)), color-stop(0.5, #fff))',
    backgroundSize: '200px 100%',
    animationName: '$shimmer',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    opacity: 0.4,
    width: 99,
    height: 53,
  },
  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: 'top right',
    },
    '100%': {
      backgroundPosition: ' top left',
    },
  },
  wrap: {
    poistion: 'relative',
    width: 103,
    height: 53,
  },
});

export const MainLoader: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      width='100vw'
      height='100vh'
      overflow='hidden'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Box className={classes.wrap}>
        <div
          className={`${classes.shimmer} ${classes['@keyframes shimmer']}`}
        ></div>
        <img src={t('theme.logo', { returnObjects: true })} alt='bss-logo' />
      </Box>
    </Box>
  );
};

export default MainLoader;
