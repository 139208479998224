import {
  Collapse,
  Drawer,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  makeStyles,
  styled,
} from '@material-ui/core';
import { Add, KeyboardArrowDown, MenuRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';
import AppButton from '../core/buttons';
import { UnionIcon } from '../core/icons/union';
import UserCard from '../userCard';
import { useTranslation } from 'react-i18next';

const StyledUnionIcon = styled(UnionIcon)({
  width: 10,
  height: 10,
});

const AppNavMenuButton = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline-flex',
  textDecoration: 'none',
  fontFamily: 'Proxima Nova Lt',
  cursor: 'pointer',
  '& > span, a': {
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  },
}));

const AppNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  textDecoration: 'none',
  fontFamily: 'Proxima Nova Lt',
  '&.active': {
    textDecoration: 'underline',
  },
  '& > span, a': {
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  },
}));

const useStyles = makeStyles((theme) => ({
  nestedLink: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    fontFamily: 'Proxima Nova LT',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    '& > a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
}));

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  color: 'white',
});

interface ProductLink {
  title: string;
  link: string;
}

export const NavMobile: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const { profile } = useAppSelector((state) => state.user);
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      sm={6}
      xs={6}
      alignItems='center'
      justify='flex-end'
      wrap='nowrap'
      direction='row'
    >
      <Box mr={3}>
        {location.pathname !== '/projects/create' && (
          <AppButton startIcon={<Add />} variant='contained' color='secondary'>
            <StyledNavLink to='/projects/create'>
              {t('header.project')}
            </StyledNavLink>
          </AppButton>
        )}
      </Box>
      <IconButton
        style={{ color: '#fff', padding: 0 }}
        onClick={() => setOpenDrawer(true)}
      >
        <MenuRounded />
      </IconButton>
      <Drawer
        PaperProps={{
          style: { width: 240, height: '100%', justifyContent: 'center' },
        }}
        anchor='right'
        open={openDrawer}
        onBackdropClick={() => setOpenDrawer(false)}
      >
        <List style={{ paddingLeft: 16 }}>
          <ListItem>
            <UserCard />
          </ListItem>

          {profile?.role !== EUserRole.customer && (
            <ListItem>
              <AppNavLink to='/' exact onClick={() => setOpenDrawer(false)}>
                <span>{t('words.projects')}</span>
              </AppNavLink>
            </ListItem>
          )}
          {profile && profile.role === EUserRole.admin && (
            <ListItem>
              <AppNavLink to='/partners' onClick={() => setOpenDrawer(false)}>
                <span>{t('header.partners')}</span>
              </AppNavLink>
            </ListItem>
          )}

          {profile?.role !== EUserRole.customer && (
            <>
              <ListItem>
                <AppNavLink
                  to='/employees'
                  onClick={() => setOpenDrawer(false)}
                >
                  <span>{t('header.employees')}</span>
                </AppNavLink>
              </ListItem>
            </>
          )}
          <ListItem>
            <AppNavLink to='/materials' onClick={() => setOpenDrawer(false)}>
              <span>{t('header.materials')}</span>
            </AppNavLink>
          </ListItem>
          <ListItem>
            <AppNavLink
              to='/documentation'
              onClick={() => setOpenDrawer(false)}
            >
              <span>{t('header.docs')}</span>
            </AppNavLink>
          </ListItem>

          {profile?.role !== EUserRole.customer && (
            <>
              {profile && profile.role === EUserRole.admin && (
                <ListItem>
                  <AppNavLink
                    to='/countries'
                    onClick={() => setOpenDrawer(false)}
                  >
                    <span>{t('header.countries')}</span>
                  </AppNavLink>
                </ListItem>
              )}

              {profile && profile.role !== EUserRole.admin && (
                <ListItem>
                  <AppNavLink
                    to='/company-profile'
                    onClick={() => setOpenDrawer(false)}
                  >
                    <span>{t('header.companyProfile')}</span>
                  </AppNavLink>
                </ListItem>
              )}
            </>
          )}

          <ListItem>
            <AppNavMenuButton onClick={() => setCollapsed((c) => !c)}>
              <span>
                {t('header.products')}
                <KeyboardArrowDown
                  style={{
                    width: '20px',
                    marginLeft: 3,
                    height: 'auto',
                    transition: 'all 200ms',
                    transform: isCollapsed ? 'rotate(180deg)' : undefined,
                  }}
                />
              </span>
            </AppNavMenuButton>
          </ListItem>
          <Collapse in={isCollapsed} timeout={150} unmountOnExit>
            <List component='div' disablePadding>
              {(
                t('header.productsLinks', {
                  returnObjects: true,
                }) as ProductLink[]
              ).map((item) => (
                <ListItem className={classes.nestedLink}>
                  <a href={item.link} rel='noreferrer' target='_blank'>
                    {item.title}
                  </a>
                  <StyledUnionIcon width={'10px'} />
                </ListItem>
              ))}
            </List>
          </Collapse>
          <ListItem>
            <AppNavLink to='/news' onClick={() => setOpenDrawer(false)}>
              <span>{t('header.news')}</span>
            </AppNavLink>
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

export default NavMobile;
