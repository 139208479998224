import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import news from '../../api/news';
import { NewsList } from '../../components/lists/newsList';
import MainLoader from '../../components/mainLoader';
import { ENewsType, INews } from '../../types/news.interface';
import { useTranslation } from 'react-i18next';

export const NewsPage: React.FC<{ isEditing?: boolean }> = ({ isEditing }) => {
  const { t } = useTranslation();
  const newsQuery = useQuery(['news', ENewsType.news], () =>
    news.list<Array<INews>>(ENewsType.news)
  );

  if (newsQuery.isLoading) {
    return <MainLoader />;
  }
  return (
    <Paper
      square
      style={{
        flex: '1 1 100vh',
        minHeight: '100vh',
      }}
    >
      <Grid item container sm={12} md={9}>
        <Box ml={4} mt={5} mr={1}>
          {newsQuery.data && newsQuery.data.length > 0 ? (
            <NewsList isEditing={isEditing} items={newsQuery.data} />
          ) : (
            <Typography variant='h2'>
              {t('page.news.newsDoesntExists')}
            </Typography>
          )}
        </Box>
      </Grid>
    </Paper>
  );
};

export default NewsPage;
