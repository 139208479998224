import { Box, Grid, Paper } from "@material-ui/core"
import BlockHeading from "../../components/core/blockHeading";
import { t } from "i18next";
import { StyledTextArea } from "../../components/forms/project";
import { useEffect, useRef, useState } from "react";
import AppButton from "../../components/core/buttons";
import { useMutation } from "react-query";
import feedback from "../../api/feedback";

export const FeedbackForm: React.FC = () => {

    const [question, setQuestion] = useState<null | string>(null);
    const [error, setError] = useState<null | string>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [send, setSend] = useState<boolean>(false);
    const textarea: any = useRef();

    const store = useMutation('feedback', feedback.send, {
        onSuccess: (data) => {
            setSuccess(true);
            setSend(false);
            setError(null);
            setQuestion("");

            if (textarea.current) {
                textarea.current.value = "";
            }

            setTimeout(() => setSuccess(false), 5000);
        },
        onError: () => {
            setError(t('page.feedback.error'));
            setSend(false);
        },
    });

    useEffect(() => {
        return () => {
            setQuestion(null);
            setSuccess(false);
            setSend(false);
        }
    }, []);

    useEffect(() => {
        if (send) {
            store.mutate({ question });
        }
    }, [send]);

    return (
        <Grid container item xs={12} direction='column' alignItems='center'>
            <Grid container item md={5}>

                <BlockHeading>{t('page.feedback.title')}</BlockHeading>

                <Grid item xs={12}>
                    <Paper elevation={0} square color='white'>
                        <Box px={4} py={5} position='relative'>

                            <label style={{ width: '100%', paddingBottom: 5 }}>
                                {t('page.feedback.label')}
                            </label>
                            <StyledTextArea
                                rows={15}
                                onChange={(e) => setQuestion(e?.target?.value || null)}
                                disabled={send}
                                ref={textarea}
                            >
                                {question}
                            </StyledTextArea>

                            {error && <div style={{ color: "red", marginBottom: 8 }}>
                                <strong>{error}</strong>
                            </div>}

                            {success && <div style={{ color: "green", marginBottom: 8 }}>
                                <strong>{t('page.feedback.success')}</strong>
                            </div>}

                            <AppButton
                                variant='contained'
                                color='primary'
                                fullWidth
                                disabled={send || !Boolean(question)}
                                onClick={() => setSend(true)}
                            >
                                {t('page.feedback.button.send')}
                            </AppButton>
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
        </Grid>

    );
}