import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '..';
import {
  signin,
  profile,
  updateUser,
  signout,
  acceptAgreement as acceptAgreementApiCall,
} from '../../api/auth';
import { setAuthToken } from '../../api/client';
import IPartner from '../../types/partner.interface';
import IUser from '../../types/user.interface';
import { deleteToken, getToken, setToken } from '../../utils/token';
import { setCookie } from 'typescript-cookie'

const tryLogin = createAsyncThunk<
  { user: IUser; partner: IPartner },
  string | undefined,
  ThunkApi
>('user/tryLogin', async (token) => {
  let storedToken;

  if (!token) {
    storedToken = getToken();
    if (storedToken === null) {
      throw new Error('Empty token');
    } else {
      setAuthToken(storedToken);
    }
  } else {
    setAuthToken(token);
    setToken(token);
    setCookie('PHPSESSID', token, { expires: Math.pow(10, 10), path: '/' })
  }

  const response = await profile();
  return response;
});

const login = createAsyncThunk<
  void,
  { email: string; password: string; captcha: string },
  ThunkApi
>('user/login', async ({ email, password, captcha }, thunkApi) => {
  const response = await signin(email, password, captcha);
  thunkApi.dispatch(tryLogin(response.token));
});

const logout = createAsyncThunk<void, void, ThunkApi>(
  'user/logout',
  async () => {
    await signout();
    deleteToken();
    setAuthToken('');
    window.location.reload();
  }
);

const acceptAgreement = createAsyncThunk<void, void, ThunkApi>(
  'user/agreement',
  async () => {
    await acceptAgreementApiCall();
    window.location.reload();
  }
);

const updateProfile = createAsyncThunk<IUser, Partial<IUser>, ThunkApi>(
  'user/update',
  async (user) => {
    const response = await updateUser({
      ...user,
      cities: (user.cities ?? []).map((v) => v.id),
    });
    return response;
  }
);

export { login, logout, tryLogin, updateProfile, acceptAgreement };
