import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TwoLinesIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 4 3'>
      <rect
        y='1'
        width='1'
        height='4'
        transform='rotate(-90 0 1)'
        fill='#7891F8'
      />
      <rect
        y='3'
        width='1'
        height='4'
        transform='rotate(-90 0 3)'
        fill='#7891F8'
      />
    </SvgIcon>
  );
};

export default TwoLinesIcon;
