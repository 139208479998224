import {
  Box,
  Grid,
  styled,
  makeStyles,
  Typography,
  Divider,
  Hidden,
} from '@material-ui/core';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import React from 'react';
import { Link } from 'react-router-dom';
import { INews } from '../../types/news.interface';
import { NewsEditPen } from '../core/buttons';

export const FloatingEditLink = styled(Link)({
  position: 'absolute',
});

export const NewsTitle = styled(Typography)({
  maxWidth: '75%',
  lineHeight: '32px',
  '&:hover': {
    color: '#E7636B',
  },
});

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    color: '#000',
    textDecoration: 'none',
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    color: '#7891F8',
  },
  verticalDivider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Proxima Nova Lt',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  rootImg: {
    width: 'auto',
    maxWidth: '100%',
    height: '45vh',
    backgroundSize: 'cover',
  },
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  description: {
    height: 96,
    maxHeight: 96,
    textOverflow: 'clip',
    overflow: 'hidden',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  secondGridRow: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}));

interface NewsMainGridProps {
  news1?: INews;
  news2?: INews;
  news3?: INews;
  isEditing: boolean;
}

export const NewsMainGrid: React.FC<NewsMainGridProps> = ({
  news1,
  news2,
  news3,
  isEditing,
}) => {
  const classes = useStyles();

  return news1 && news1.picture ? (
    <Grid item container direction='row'>
      {news1 && (
        <Grid
          item
          container
          direction='row'
          md={7}
          wrap='nowrap'
          className={classes.linkContainer}
          component={Link}
          to={`/news/${news1.id}`}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <div
              className={classes.rootImg}
              style={{
                backgroundImage: `url(${
                  news1.picture
                    ? // @ts-ignore
                      `/api/v1/file/resolve/${news1.picture.id}/none`
                    : '/images/mockNewsImage.jpeg'
                })`,
              }}
            />
            <Box mt={3} />
            <NewsTitle variant='h3'>{news1.title}</NewsTitle>
            <Box mt={1} />
            <Typography variant='body1'>{news1.description}</Typography>
            <Box mt={1} />
            <Typography className={classes.date} variant='subtitle1'>
              {format(new Date(+news1.publishAt * 1000), 'dd MMM', {
                locale: enGB,
              })}
            </Typography>
            <Hidden smUp>
              <Box my={3}>
                <Divider />
              </Box>
            </Hidden>
          </Box>
          <Box mx={4} height='100%' className={classes.verticalDivider}>
            <Divider orientation='vertical' />
          </Box>
          {isEditing && (
            <FloatingEditLink
              style={{ top: 8, right: 76 }}
              to={`/news/update/${news1.id}`}
            >
              <NewsEditPen />
            </FloatingEditLink>
          )}
        </Grid>
      )}
      <Grid item container direction='column' md={5} justify='space-around'>
        {news2 && (
          <>
            <Grid
              item
              component={Link}
              className={classes.linkContainer}
              to={`/news/${news2.id}`}
            >
              <NewsTitle variant='h3'>{news2.title}</NewsTitle>
              <Box mt={1} />
              <Typography variant='body1'>{news2.description}</Typography>
              <Box mt={2} />
              <Typography className={classes.date} variant='subtitle1'>
                {format(new Date(+news2.publishAt * 1000), 'dd MMM', {
                  locale: enGB,
                })}
              </Typography>
              {isEditing && (
                <FloatingEditLink
                  style={{ bottom: 40, right: 0 }}
                  to={`/news/update/${news2.id}`}
                >
                  <NewsEditPen />
                </FloatingEditLink>
              )}
              <Box mt={4} mb={3}>
                <Divider variant='fullWidth' orientation='horizontal' />
              </Box>
            </Grid>
          </>
        )}
        {news3 && (
          <Grid
            item
            className={classes.linkContainer}
            component={Link}
            to={`/news/${news3.id}`}
          >
            <NewsTitle variant='h3'>{news3.title}</NewsTitle>
            <Box mt={1} />
            <Typography variant='body1'>{news3.description}</Typography>
            <Box mt={2} />
            <Typography className={classes.date} variant='subtitle1'>
              {format(new Date(+news3.publishAt * 1000), 'dd MMM', {
                locale: enGB,
              })}
            </Typography>
            {isEditing && (
              <FloatingEditLink
                style={{ bottom: 0, right: 0 }}
                to={`/news/update/${news3.id}`}
              >
                <NewsEditPen />
              </FloatingEditLink>
            )}
            <Hidden smUp>
              <Box my={3}>
                <Divider />
              </Box>
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid item container direction='column' wrap='nowrap'>
      {news1 && (
        <Grid
          item
          container
          direction='row'
          md={12}
          wrap='nowrap'
          className={classes.linkContainer}
          component={Link}
          to={`/news/${news1.id}`}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <NewsTitle variant='h3'>{news1.title}</NewsTitle>
            <Box mt={1} />
            <Typography className={classes.description} variant='body1'>
              {news1.description}
            </Typography>
            <Box mt={1} />
            <Typography className={classes.date} variant='subtitle1'>
              {format(new Date(+news1.publishAt * 1000), 'dd MMM', {
                locale: enGB,
              })}
            </Typography>
          </Box>

          {isEditing && (
            <FloatingEditLink
              style={{ top: 8, right: 76 }}
              to={`/news/update/${news1.id}`}
            >
              <NewsEditPen />
            </FloatingEditLink>
          )}
        </Grid>
      )}
      <Box mt={4} mb={3}>
        <Divider />
      </Box>
      <Grid
        item
        container
        direction='row'
        spacing={2}
        className={classes.secondGridRow}
      >
        {news2 && (
          <Grid
            item
            sm={12}
            md={news3 ? 6 : 12}
            component={Link}
            className={classes.linkContainer}
            to={`/news/${news2.id}`}
          >
            <NewsTitle variant='h3'>{news2.title}</NewsTitle>
            <Box mt={1} />
            <Typography className={classes.description} variant='body1'>
              {news2.description}
            </Typography>
            <Box mt={2} />
            <Typography className={classes.date} variant='subtitle1'>
              {format(new Date(+news2.publishAt * 1000), 'dd MMM', {
                locale: enGB,
              })}
            </Typography>
            {isEditing && (
              <FloatingEditLink
                style={{ bottom: 40, right: 0 }}
                to={`/news/update/${news2.id}`}
              >
                <NewsEditPen />
              </FloatingEditLink>
            )}
          </Grid>
        )}
        {news3 && (
          <Grid item>
            <Box mx={3} height='100%'>
              <Divider
                orientation='vertical'
                className={classes.verticalDivider}
              />
            </Box>
          </Grid>
        )}
        {news3 && (
          <Grid
            item
            sm={12}
            md={6}
            className={classes.linkContainer}
            component={Link}
            to={`/news/${news3.id}`}
          >
            <NewsTitle variant='h3'>{news3.title}</NewsTitle>
            <Box mt={1} />
            <Typography className={classes.description} variant='body1'>
              {news3.description}
            </Typography>
            <Box mt={2} />
            <Typography className={classes.date} variant='subtitle1'>
              {format(new Date(+news3.publishAt * 1000), 'dd MMM', {
                locale: enGB,
              })}
            </Typography>
            {isEditing && (
              <FloatingEditLink
                style={{ bottom: 0, right: 0 }}
                to={`/news/update/${news3.id}`}
              >
                <NewsEditPen />
              </FloatingEditLink>
            )}
            <Hidden smUp>
              <Box my={3}>
                <Divider />
              </Box>
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
