import {
  Box,
  Checkbox,
  Grid,
  Hidden,
  Input,
  makeStyles,
  Paper,
  styled,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import IPartner from '../../../types/partner.interface';
import { formatPhoneNumber, phoneToValid } from '../../../utils/etc';
import AppButton from '../../core/buttons';
import AppInputLabel from '../../core/label';
import AppInput from '../../core/outlinedInput';
import MaskedPhone from '../../inputs/maskedPhone';
import ShortInput from '../../inputs/short';
import FormItemRow from '../formRow';
import * as yup from 'yup';
import partner from '../../../api/partner';
import PenIcon from '../../core/icons/pen';
import { useAppSelector } from '../../../hooks/useStore';
import { ManagerSelect } from '../../inputs/managerSelect';
import { EUserRole } from '../../../types/user.interface';
import { FileDropZone } from '../../fileDropZone';
import FileCard from '../../fileCard';
import LoadingFile from '../../loadingFile';
import IFile from '../../../types/file.interface';
import { useSnackbar } from 'notistack';
import FileLoadZone from '../../fileDropZone/mobile';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { default as cAPI } from '../../../api/city';
import FieldArrowDown from '../../core/icons/fieldArrowDown';

const LocalInput = styled(Input)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  color: '#fff',
  fontSize: 32,
  paddingBottom: 0,
  cursor: 'pointer',
  lineHeight: 1.1,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  width: 'auto',
  maxWidth: 'max-content',
  transition: 'all 200ms',
  '&.Mui-focused': {
    borderBottomColor: '#7C79A2',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
});

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    background: '#fff',
  },
});

const useStyles = makeStyles((theme) => ({
  paddingLoadZone: {
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    marginRight: 18,
    stroke: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
    },
  },
  button: {
    fontSize: 13,
    color: '#fff',
    '&:disabled': {
      color: '#7C79A1',
    },
  },
}));

interface PartnerEditFormProps {
  data: IPartner;
  onDone: () => void;
}

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  borderRadius: 3,
  border: '1px solid #e8e8e8',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

export const PartnerEditForm: React.FC<PartnerEditFormProps> = ({
  data,
  onDone,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const formik = useFormik<Omit<IPartner, 'createdAt' | 'isNew'>>({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: data,
    validationSchema: yup.object({
      name: yup.string().required(t('validation.required')),
      email: yup
        .string()
        .email(t('validation.email'))
        .required(t('validation.required')),
      fullName: yup.string().required(t('validation.required')),
      city: yup.string().required(t('validation.required')),
      personalPhone: yup.string().required(t('validation.required')),
      workPhone: yup.string().required(t('validation.required')),
      countries:
        user.profile && user.profile.role === EUserRole.admin
          ? yup
              .array()
              .required(t('validation.required'))
              .min(1, t('validation.required'))
          : yup.array().notRequired(),
    }),
    onSubmit: async (values, helpers) => {
      await helpers.validateForm(values);
      const work_phone = phoneToValid(values.workPhone);
      const personal_phone = phoneToValid(values.personalPhone);
      try {
        await partner.update({
          ...values,
          personalPhone: personal_phone,
          workPhone: work_phone,
          manager: values.manager?.id,
          files: values.files.map((file) => file.id),
          countries: values.countries.map((country) => country.id),
        });
        onDone();
        enqueueSnackbar(t('toastNotifications.partnerChanged'), {
          variant: 'info',
        });
      } catch (err) {
        // console.log(error)
        let a = (err as any).response.data.errors
          .map((er: any) => er.reason)
          .join(',');
        enqueueSnackbar(a, { variant: 'error' });
      }
    },
  });

  const [files, setFiles] = useState<Array<File>>([]);
  const [loadedFiles, setLoaded] = useState<Array<IFile>>(data.files);

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      setFiles((files) => [...files, list[i]]);
    }
  };

  const handleLoadSuccess = (fileResponse: IFile, f: File) => {
    const nf = files.filter((file) => file.name !== f.name);
    setFiles(nf);
    setLoaded((lf) => [...lf, fileResponse]);
    formik.setFieldValue('files', [...formik.values.files, fileResponse]);
  };

  const handleFileDelete = (id: string) => {
    const newFiles = formik.values.files.filter((file) => file.id !== id);
    formik.setFieldValue('files', newFiles);
  };

  const handleFileRestore = (id: string) => {
    const newFile = loadedFiles.find((file) => file.id === id);
    if (newFile) {
      formik.setFieldValue('files', [...formik.values.files, newFile]);
    }
  };

  const citySearchQuery = useQuery(['cities'], () => cAPI.list(0), {
    useErrorBoundary: false,
    suspense: false,
  });

  const handleCountry = (event: any) => {
    const {
      target: { value },
    } = event;

    const payload = value.map((v: any) =>
      typeof v === 'string'
        ? citySearchQuery.data?.items.find((pv) => v === pv.id) ?? { id: v }
        : v
    );

    formik.setFieldValue(
      'countries',
      payload
        .map((item: any) => item.id)
        .filter(
          (value: any, index: any, self: any) => self.indexOf(value) === index
        )
        .map((v: any) => payload.find((a: any) => a.id === v))
    );
  };

  return (
    <Grid item container direction='column'>
      <Grid item>
        <Box
          mt={3}
          mr={3.5}
          ml={4}
          mb={2}
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
        >
          <LocalInput
            inputProps={{
              size: formik.initialValues.name.length + 8,
            }}
            aria-setsize={formik.initialValues.name.length}
            type='text'
            defaultValue={formik.initialValues.name}
            onChange={formik.handleChange}
            name='name'
          />
          {/* <AppButton className={classes.button} variant="text" disabled startIcon={<PenIcon  style={{fontSize: 16,}} />}>
            Редактировать профиль
          </AppButton> */}
          <Hidden xsDown>
            <AppButton
              className={classes.button}
              disabled
              variant='text'
              startIcon={<PenIcon style={{ fontSize: 16 }} />}
            >
              {t('button.editProfile')}
            </AppButton>
          </Hidden>
          <Hidden smUp>
            <IconButton className={classes.button} disabled>
              <PenIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Hidden>
        </Box>
      </Grid>
      <Grid item>
        <Paper
          square
          style={{
            flex: '1 1 100vh',
            minHeight: '100vh',
          }}
        >
          <Grid container item alignItems='center' direction='column'>
            <Grid container item md={7} sm={11} xs={11}>
              <Box mt={5} mb={3}>
                <Typography variant='h3'>
                  {t('page.partnerEdit.headline')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              md={7}
              sm={11}
              xs={11}
              justify='center'
              direction='row'
            >
              <Grid container direction='column' item md={8} sm={7} xs={11}>
                <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                  <FormItemRow>
                    <AppInputLabel>{t('page.partnerEdit.city')}</AppInputLabel>
                    <AppInput
                      onChange={formik.handleChange}
                      name='city'
                      error={!!formik.errors.city}
                      helperText={formik.errors.city}
                      defaultValue={formik.initialValues.city}
                    />
                  </FormItemRow>
                  <FormItemRow>
                    <AppInputLabel>{t('words.fullNameAbbr')}</AppInputLabel>
                    <AppInput
                      onChange={formik.handleChange}
                      name='fullName'
                      error={!!formik.errors.fullName}
                      helperText={formik.errors.fullName}
                      defaultValue={formik.initialValues.fullName}
                    />
                  </FormItemRow>
                  <FormItemRow>
                    <AppInputLabel>{t('words.position')}</AppInputLabel>
                    <AppInput
                      onChange={formik.handleChange}
                      name='position'
                      error={!!formik.errors.position}
                      helperText={formik.errors.position}
                      defaultValue={formik.initialValues.position}
                    />
                  </FormItemRow>
                  <FormItemRow>
                    <AppInputLabel>{t('words.email')}</AppInputLabel>
                    <AppInput
                      onChange={formik.handleChange}
                      name='email'
                      error={!!formik.errors.email}
                      helperText={formik.errors.email}
                      defaultValue={formik.initialValues.email}
                    />
                  </FormItemRow>
                  <FormItemRow>
                    <AppInputLabel>
                      {t('page.partnerEdit.mobilePhone')}
                    </AppInputLabel>
                    <ShortInput
                      error={!!formik.errors.personalPhone}
                      helperText={formik.errors.personalPhone}
                      onChange={formik.handleChange}
                      name='personalPhone'
                      inputComponent={MaskedPhone}
                      defaultValue={formik.initialValues.personalPhone}
                    />
                  </FormItemRow>
                  <FormItemRow>
                    <AppInputLabel>
                      {t('page.partnerEdit.workPhone')}
                    </AppInputLabel>
                    <ShortInput
                      error={!!formik.errors.workPhone}
                      helperText={formik.errors.workPhone}
                      onChange={formik.handleChange}
                      name='workPhone'
                      inputComponent={MaskedPhone}
                      defaultValue={formik.initialValues.workPhone}
                    />
                  </FormItemRow>

                  {user.profile && user.profile.role === EUserRole.admin && (
                    <FormItemRow>
                      <AppInputLabel>
                        {t('page.partnerEdit.isActive')}
                      </AppInputLabel>
                      <Checkbox
                        className={classes.checkbox}
                        color='primary'
                        name='isVerified'
                        onChange={formik.handleChange}
                        defaultChecked={!!formik.initialValues.isVerified}
                      />
                    </FormItemRow>
                  )}

                  {user.profile && user.profile.role === EUserRole.admin ? (
                    <FormItemRow>
                      <AppInputLabel
                        style={{
                          minWidth: 120,
                        }}
                      >
                        Countries
                      </AppInputLabel>

                      <div style={{ width: '100%' }}>
                        <StyledSelect
                          multiple
                          value={formik.values.countries}
                          disableUnderline={true}
                          style={{ width: '100%' }}
                          error={!!formik.errors.countries}
                          IconComponent={(props) => (
                            <FieldArrowDown {...props} />
                          )}
                          renderValue={(selected: any) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gridGap: 1,
                              }}
                            >
                              {selected.map((value: any) => (
                                <span
                                  style={{
                                    display: 'inline-block',
                                    background: '#1C00A0',
                                    color: '#fff',
                                    borderRadius: '33px',
                                    padding: '4px 15px',
                                    fontSize: '14px',
                                  }}
                                >
                                  {value?.name}
                                </span>
                              ))}
                            </Box>
                          )}
                          MenuProps={{
                            PaperProps: {
                              square: true,
                              style: {
                                maxHeight: 300,
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {!citySearchQuery.isError &&
                            citySearchQuery.data?.items &&
                            citySearchQuery.data?.items.map((i) => (
                              <CustomMenuItem
                                value={i.id}
                                key={i.id}
                                onClick={() => {
                                  if (
                                    formik.values.countries.some(
                                      (v) => v.id === i.id
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'countries',
                                      formik.values.countries.filter(
                                        (c) => c.id !== i.id
                                      )
                                    );
                                    return;
                                  }

                                  formik.setFieldValue('countries', [
                                    ...formik.values.countries,
                                    i,
                                  ]);
                                }}
                              >
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr min-content',
                                    width: '100%',
                                  }}
                                >
                                  <span>{i.name}</span>
                                  <Checkbox
                                    className={classes.checkbox}
                                    color='primary'
                                    checked={formik.values.countries.some(
                                      (v) => v.id === i.id
                                    )}
                                  />
                                </div>
                              </CustomMenuItem>
                            ))}
                        </StyledSelect>
                        {formik.errors.countries && (
                          <FormHelperText>
                            {formik.errors.countries}
                          </FormHelperText>
                        )}
                      </div>
                    </FormItemRow>
                  ) : null}

                  {user.profile && user.profile.role === EUserRole.admin ? (
                    <FormItemRow>
                      <AppInputLabel>
                        {t('page.partnerEdit.manager')}
                      </AppInputLabel>
                      <ManagerSelect
                        defaultValue={formik.values.manager}
                        onChange={(value) =>
                          formik.setFieldValue('manager', value)
                        }
                      />
                    </FormItemRow>
                  ) : (
                    formik.values.manager && (
                      <Box>
                        <Box mt={2} mb={1}>
                          <Typography
                            variant='body2'
                            style={{ color: '#909090' }}
                          >
                            {t('page.partnerEdit.manager')}
                          </Typography>
                        </Box>
                        <Typography variant='body2'>
                          {formik.values.manager?.fullName}
                        </Typography>
                        <Typography variant='body2'>
                          {formik.values.manager
                            ? `${formatPhoneNumber(
                                formik.values.manager.phone
                              )} · ${formik.values.manager.email}`
                            : null}
                        </Typography>
                      </Box>
                    )
                  )}
                  <Hidden smDown>
                    <Box mt={6}>
                      <AppButton
                        disabled={!formik.isValid}
                        style={{ padding: '16px 48px', height: 56 }}
                        type='submit'
                        size='large'
                        color='primary'
                        variant='contained'
                      >
                        {t('page.partnerEdit.save')}
                      </AppButton>
                    </Box>
                  </Hidden>
                </form>
              </Grid>
              <Grid item md={4} sm={5} xs={11}>
                <Box
                  className={classes.paddingLoadZone}
                  display='flex'
                  flexDirection='column'
                >
                  {files.map((f, i) => (
                    <LoadingFile
                      onLoadSuccess={(fileResponse) =>
                        handleLoadSuccess(fileResponse, f)
                      }
                      key={f.name + i}
                      file={f}
                    />
                  ))}
                  {loadedFiles.map((file) => (
                    <FileCard
                      key={file.id}
                      file={file}
                      softDelete
                      onDelete={handleFileDelete}
                      onRestore={handleFileRestore}
                    />
                  ))}
                  <Hidden smDown>
                    {!!loadedFiles.length && <Box mb={5} />}
                  </Hidden>
                  <Hidden smDown>
                    <Grid item>
                      <Box height={320} width='100%'>
                        <FileDropZone
                          onChange={handleNewFiles}
                          allowedFileExtensions={[]}
                          allowedMimeTypes={[]}
                        />
                      </Box>
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Box>
                      <FileLoadZone onChange={handleNewFiles} />
                    </Box>
                  </Hidden>
                </Box>
              </Grid>
              <Hidden smUp>
                <Box pt={5} width='100%' pb={5} m={2}>
                  <AppButton
                    fullWidth
                    disabled={!formik.isValid}
                    style={{ padding: '16px 24px', height: 56 }}
                    onClick={formik.submitForm}
                    size='large'
                    color='primary'
                    variant='contained'
                  >
                    {t('page.partnerEdit.save')}
                  </AppButton>
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PartnerEditForm;
