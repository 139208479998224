import { TMaterialCategoryType } from '../types/material.interface';
import http from './client';

async function categoryList({ type }: { type?: TMaterialCategoryType }) {
  const res = await http.post('/material/category/list', {
    type,
  });
  return res.data.data;
}

async function groupList({ category }: { category: string }) {
  const res = await http.post('/material/group/list', { category });
  return res.data.data;
}

async function materialList({
  type,
  search
}: {
  type?: TMaterialCategoryType,
  search?: string | null | undefined
}) {
  const res = await http.post('/material/list', { type, search });
  return res.data.data;
}

async function categoryCreate({
  title,
  type,
}: {
  title: string;
  type: TMaterialCategoryType;
}) {
  const res = await http.post('/material/category/create', { title, type });
  return res.data.data;
}

async function groupCreate({
  title,
  category,
}: {
  title: string;
  category: string;
}) {
  const res = await http.post('/material/group/create', { title, category });
  return res.data.data;
}

async function materialCreate({
  group,
  file,
  publicId,
  name,
  url,
  isActual,
}: {
  group: string;
  file?: string;
  publicId: string;
  name: string;
  url?: string;
  isActual?: boolean,
}) {
  const res = await http.post('/material/create', {
    file,
    group,
    publicId,
    name,
    url,
    isActual,
  });
  return res.data.data;
}

async function categoryUpdate({
  id,
  title,
  type,
}: {
  id: string;
  title: string;
  type: TMaterialCategoryType;
}) {
  const res = await http.post('/material/category/edit', { id, title, type });
  return res.data.data;
}

async function groupUpdate({
  id,
  title,
  category,
  readAccess,
  writeAccess,
  readAccessPayload,
  writeAccessPayload,
}: {
  id: string;
  title: string;
  readAccess: string;
  writeAccess: string;
  readAccessPayload: string[];
  writeAccessPayload: string[];
  category: string;
}) {
  const res = await http.post('/material/group/edit', {
    id,
    writeAccessPayload,
    readAccessPayload,
    writeAccess,
    readAccess,
    title,
    category,
  });
  return res.data.data;
}

async function materialUpdate({
  id,
  group,
  file,
  publicId,
  name,
  url,
  isActual,
}: {
  id: string;
  group: string;
  file?: string;
  publicId: string;
  name: string;
  url?: string;
  isActual?: boolean;
}) {
  const res = await http.post('/material/edit', {
    id,
    name,
    url,
    group,
    file,
    publicId,
    isActual,
  });
  return res.data.data;
}

const material = {
  group: {
    list: groupList,
    create: groupCreate,
    update: groupUpdate,
  },
  category: {
    list: categoryList,
    create: categoryCreate,
    update: categoryUpdate,
  },
  item: {
    list: materialList,
    create: materialCreate,
    update: materialUpdate,
  },
};

export default material;
