import IManagerContact from '../types/managerContact.interface';
import http from './client';

async function create(
  mc: Omit<IManagerContact, 'id'>
): Promise<IManagerContact> {
  const response = await http.post('/manager_contact/create', mc);
  return response.data.data;
}

async function get(id: string): Promise<IManagerContact> {
  const response = await http.post('/manager_contact/get', { id });
  return response.data.data;
}

async function update(mc: IManagerContact) {
  const response = await http.post('/manager_contact/edit', mc);
  return response.data.data;
}

async function list(
  page: number = 1
): Promise<{ hasNextPage: boolean; items: Array<IManagerContact> }> {
  const response = await http.post('/manager_contact/list', {
    page: page,
  });
  return response.data.data;
}

const managerContact = {
  create,
  get,
  update,
  list,
};

export default managerContact;
