import IPartner from '../types/partner.interface';
import IUser from '../types/user.interface';
import http from './client';

async function signup(user: Omit<IUser, 'id'>) {
  return http.post('/auth/register', user);
}

async function signin(
  email: string,
  password: string,
  captcha: string
): Promise<{ token: string }> {
  const response = await http.post('/auth/login', {
    email: email,
    password: password,
    captcha: captcha,
  });

  return response.data.data;
}

async function getCaptchaPublicToken(): Promise<{ token: string }> {
  const response = await http.post('/auth/captcha_public_token');
  return response.data.data;
}

async function profile(): Promise<{ user: IUser; partner: IPartner }> {
  const response = await http.post('/user/me');
  return response.data.data;
}

async function updateUser(
  user: Partial<Omit<IUser, 'cities'> & { cities: string[] }>
) {
  const response = await http.post('user/edit', user);

  return response.data.data;
}

async function signout() {
  return http.post('/user/logout');
}

async function restore(email: string) {
  const res = http.post('/auth/restore', { email });
  return (await res).data;
}

async function acceptAgreement() {
  const res = http.post('/user/accept_agreement');
  return (await res).data;
}

async function updatePass(id: string, prev: string, next: string) {
  const response = await http.post('user/edit', {
    id: id,
    password: next,
    currentPassword: prev,
  });

  return response.data.data;
}

export {
  signup,
  signin,
  signout,
  profile,
  updateUser,
  updatePass,
  restore,
  acceptAgreement,
  getCaptchaPublicToken,
};
