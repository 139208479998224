import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import partner from '../../api/partner';
import project from '../../api/project';
import AppButton from '../../components/core/buttons';
import ProjectsList from '../../components/lists/projectsList';
import NewsCard from '../../components/newsCard';
import ProjectsSearchBar from '../../components/projectsSearchbar';
import { ProjectsSearchbarMobile } from '../../components/projectsSearchbar/mobile';
import { IProjectsSearchParams } from '../../types/project.interface';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import config, { ServiceType } from '../../app.config';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    width: '100%',
    // height: '100%',
    // maxHeight: '100%',
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  hintRoot: {
    flex: '1 1 100%',
    minHeight: '90vh',
  },
  statsRoot: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  statsText: {
    fontWeight: 600,
    letterSpacing: 0,
    '& .sign': {
      fontSize: 12,
      lineHeight: 'inherit',
    },
  },
}));

export const ProjectsMainPage: React.FC = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState<IProjectsSearchParams>({});
  const [page, setPage] = useState(2);
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['projects', searchParams],
    ({ pageParam }) => project.list(pageParam, searchParams),
    {
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
      suspense: false,
    }
  );
  const statsQuery = useQuery('stats', partner.stats);
  return (
    <Grid container item direction='column' className={classes.root}>
      <Hidden smDown>
        <ProjectsSearchBar
          searchParams={searchParams}
          onReset={() => setSearchParams({ search: '' })}
          onPartnerPick={(partner) => {
            setSearchParams((sp) => ({
              ...sp,
              partner: partner,
            }));
          }}
          onCityPick={(city) => {
            setSearchParams((sp) => ({
              ...sp,
              city,
            }));
          }}
          onStatusPick={(status) =>
            setSearchParams((sp) => ({
              ...sp,
              status: status,
            }))
          }
          onDateRangePick={({ from, to }) =>
            setSearchParams((sp) => ({
              ...sp,
              periodFrom: from?.toISOString(),
              periodTo: to?.toISOString(),
            }))
          }
          onSearch={(search) =>
            setSearchParams((sp) => ({
              ...sp,
              search: search,
            }))
          }
        />
      </Hidden>
      <Hidden mdUp>
        <ProjectsSearchbarMobile
          onReset={() => setSearchParams({ search: '' })}
          searchParams={searchParams}
          dropAll={() => {
            setSearchParams({});
          }}
          onPartnerPick={(partner) => {
            setSearchParams((sp) => ({
              ...sp,
              partner: partner,
            }));
          }}
          onCityPick={(city) => {
            setSearchParams((sp) => ({
              ...sp,
              city,
            }));
          }}
          onStatusPick={(status) =>
            setSearchParams((sp) => ({
              ...sp,
              status: status,
            }))
          }
          onDateRangePick={({ from, to }) =>
            setSearchParams((sp) => ({
              ...sp,
              periodFrom: from?.toISOString(),
              periodTo: to?.toISOString(),
            }))
          }
          onSearch={(search) =>
            setSearchParams((sp) => ({
              ...sp,
              search: search,
            }))
          }
        />
      </Hidden>
      <Grid item container direction='column' className={classes.listRoot}>
        <Grid item>
          <NewsCard />
        </Grid>
        <Grid item container direction='column'>
          {data?.pages.map((page) => page.items).flat().length !== 0 ? (
            <>
              <ProjectsList
                searchParams={searchParams}
                onFilterDirectionClick={(name, direction) => {
                  setSearchParams((sp) => ({
                    ...sp,
                    sort: {
                      by: name,
                      direction,
                    },
                  }));
                }}
                hasMore={!!hasNextPage}
                fetchNext={() => {
                  setPage((p) => p + 1);
                  fetchNextPage({
                    pageParam: page,
                  });
                }}
                items={data ? data.pages.map((page) => page.items).flat() : []}
              />

              {statsQuery.data && (
                <Box className={classes.statsRoot}>
                  <Typography className={classes.statsText} variant='body1'>
                    {t('page.projects.total', {
                      count: statsQuery.data.activeProjectsCount,
                    })}

                    <NumberFormat
                      displayType='text'
                      value={statsQuery.data.activeAmount}
                      thousandSeparator=' '
                      isNumericString
                    />

                    {config.service === ServiceType.Spitch ? ' CHF' : ' RUB'}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Grid
              container
              item
              justify='center'
              alignItems='center'
              className={classes.hintRoot}
            >
              {searchParams ? (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                >
                  <Typography variant='h1'>
                    {searchParams.search
                      ? t('page.projects.queryTermNotFound', {
                          query: searchParams.search,
                        })
                      : t('page.projects.nothingFound')}
                  </Typography>
                  <Box mb={1} />
                  <Typography variant='body1'>
                    {t('page.projects.makeSureInputIsCorrect')}
                  </Typography>
                  <Box mb={3} />
                  <AppButton
                    style={{ paddingLeft: 71, paddingRight: 71 }}
                    variant='outlined'
                    color='primary'
                    onClick={() => setSearchParams({})}
                  >
                    {t('page.projects.resetFilter')}
                  </AppButton>
                </Grid>
              ) : (
                <Typography variant='h2'>
                  {t('page.projects.noRequestsFound')}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectsMainPage;
