import http from './client';

async function send(data: {
    question: string | null,
}) {
    const response = await http.post('/feedback/create', data);
    return response.data.data;
}

const feedback = {
    send,
};

export default feedback;