import {
  Box,
  Collapse,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import IProject, {
  EProjectStatus,
  statusDict,
} from '../../types/project.interface';
import { dateFormatter, timeFormatter } from '../../utils/dateTimeFormatter';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import CloseIcon from '../core/icons/close';
import FileCard from '../fileCard';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import projectAPI from '../../api/project';

const useStyles = makeStyles({
  greyText: {
    color: '#909090',
  },
  rowWithPadding: {
    paddingBottom: 8,
    alignItems: 'center',
  },
  rowWithExtraPadding: {
    paddingBottom: 8,
    paddingTop: 8,
    alignItems: 'center',
  },
  bold: {
    fontWeight: 600,
  },
  ebtn: {
    minHeight: 40,
  },
  rootBox: {
    background: '#fff',
    position: 'relative',
    width: 900,
    '@media (max-width: 1000px)': {
      width: 'auto',
      maxWidth: '100%',
      minHeight: '100vh',
    },
  },
  cell: {
    verticalAlign: 'top',
  },
});

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 16,
});

interface ProjectCardProps {
  project: IProject;
  onClose: () => void;
}

export const ProjectHistoryCard: React.FC<ProjectCardProps> = ({
  project,
  onClose,
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useQuery(['project-history', project.id], () =>
    projectAPI.history(project)
  );

  return (
    <Box className={classes.rootBox}>
      <Box p={3}>
        <StyledClose onClick={onClose} style={{ cursor: 'pointer' }} />
        <Grid container item direction='column'>
          <Typography variant='h4'>
            {t('component.projectHistory.headline')}
          </Typography>
          <Box mt={1} />
          <TableContainer>
            <Table stickyHeader className='container'>
              <TableBody innerRef={ref}>
                {data?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell width={200} className={classes.cell}>
                      <Typography variant='body1'>
                        {dateFormatter.format(new Date(+item.createdAt * 1000))}{' '}
                        -{' '}
                        {timeFormatter.format(new Date(+item.createdAt * 1000))}
                      </Typography>
                    </TableCell>
                    <TableCell width={130} className={classes.cell}>
                      <Typography variant='body1'>
                        {item.author?.fullName ??
                          t('component.projectHistory.system')}
                      </Typography>
                    </TableCell>
                    <TableCell width={150} className={classes.cell}>
                      <Typography variant='body1'>
                        {statusDict[item.status.from]} {'⇨'}{' '}
                        {statusDict[item.status.to]}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography variant='body1'>{item.reason}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!(data ?? []).length && t('component.projectHistory.noHistory')}
        </Grid>
      </Box>
    </Box>
  );
};
export default ProjectHistoryCard;
