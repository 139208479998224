export enum ServiceType {
  Bss = 'bss',
  Spitch = 'spitch',
}
const config = {
  adminProjectExpireInDays: process.env.REACT_APP_PROJECT_EXPIRE_IN_DAYS
    ? Number(process.env.REACT_APP_PROJECT_EXPIRE_IN_DAYS)
    : 180,
  tokenAlias: process.env.REACT_APP_TOKEN_ALIAS,
  apiBasePAth: process.env.REACT_APP_API_BASEPATH,
  // locale: process.env.REACT_APP_LOCALE,
  // theme: process.env.REACT_APP_THEME,
  service: process.env.REACT_APP_SERVICE as ServiceType, // bss or spitch
};

export default config;
