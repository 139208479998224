import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrashBinIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 12 16'>
      <path d='M4 12V7H5V12H4Z' />
      <path d='M7 7V12H8V7H7Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 2V1.5C3 0.671573 3.67157 0 4.5 0H7.5C8.32843 0 9 0.671573 9 1.5V2H12V3H11V14.5C11 15.3284 10.3284 16 9.5 16H2.5C1.67157 16 1 15.3284 1 14.5V3H0V2H3ZM4 1.5C4 1.22386 4.22386 1 4.5 1H7.5C7.77614 1 8 1.22386 8 1.5V2H4V1.5ZM2 3H10V14.5C10 14.7761 9.77614 15 9.5 15H2.5C2.22386 15 2 14.7761 2 14.5V3Z'
      />
    </SvgIcon>
  );
};

export default TrashBinIcon;
