import http from './client';
import IDocProduct from '../types/docRelease.interface';
import IFile from '../types/file.interface';
import IDoc from '../types/doc.interface';

async function docsList(release?: string): Promise<IDoc[]> {
  const res = await http.post('/docs/list', {
    release,
  });
  return res.data.data;
}

async function docsCreate({
  title,
  url,
  pdfUrl,
  lang,
  description,
  release: { id: release },
  picture,
}: {
  title: string;
  release: IDocProduct;
  url: string;
  description: string;
  pdfUrl?: string;
  lang: 'gb' | 'de' | 'ru';
  picture?: IFile;
}) {
  const res = await http.post('/docs/create', {
    title,
    url,
    pdfUrl,
    lang,
    picture: picture?.id,
    release,
    description,
  });
  return res.data.data;
}

async function docsEdit({
  id,
  title,
  url,
  pdfUrl,
  lang,
  description,
  release: { id: release },
  picture,
}: {
  id: string;
  title: string;
  release: IDocProduct;
  url: string;
  description: string;
  pdfUrl?: string;
  lang: 'gb' | 'de' | 'ru';
  picture?: IFile;
}) {
  const res = await http.post('/docs/edit', {
    title,
    url,
    pdfUrl,
    lang,
    picture: picture?.id ?? null,
    release,
    description,
    id,
  });
  return res.data.data;
}

const docs = {
  list: docsList,
  create: docsCreate,
  edit: docsEdit,
};

export default docs;
