import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Archive: React.FC<SvgIconProps> = ({ fill, style, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      style={{ color: 'transparent', ...style }}
    >
      <path
        d='M7.5 7.5L5.5 14L5.5 18.5C5.5 19.0523 5.94772 19.5 6.5 19.5H17.5C18.0523 19.5 18.5 19.0523 18.5 18.5V14.05L16.5 7.5L7.5 7.5Z'
        stroke='#1C00A0'
      />
      <path d='M5.5 14.5H9.5' stroke='#1C00A0' />
      <path d='M14.5 14.5H19' stroke='#1C00A0' />
      <path
        d='M10.2392 9.73922C10.5209 10.0209 11.4131 10.9131 12 11.5L13.7608 9.73922'
        stroke='#1C00A0'
      />
      <path
        d='M14.4146 14.5C14.3745 14.6134 14.3212 14.7206 14.2564 14.8197C14.321 14.7207 14.3744 14.6135 14.4145 14.5H14.4146ZM13 15.5C13.3634 15.5 13.6968 15.3702 13.956 15.1558C13.6965 15.3708 13.3633 15.5 13 15.5Z'
        fill='black'
        stroke='#1C00A0'
      />
      <rect x='10.5' y='6.5' width='3' height='1' fill='white' stroke='white' />
      <path d='M12 5L12 11' stroke='#1C00A0' />
    </SvgIcon>
  );
};

export default Archive;
