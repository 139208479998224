import {
  makeStyles,
  MenuItem,
  MenuList,
  Popover,
  PopoverProps,
  styled,
} from '@material-ui/core';

export const AppContextMenu = styled(MenuList)({
  borderRadius: 0,
});

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      overflow: 'scroll',
    },
  },
}));

export const AppPopover: React.FC<
  PopoverProps & { paperClassName?: string }
> = (props) => {
  const classes = useStyles();
  return (
    <Popover
      {...props}
      PaperProps={{
        ...props.PaperProps,
        elevation: 2,
        square: true,
        className: [props.paperClassName || '', classes.popoverPaper].join(' '),
      }}
    />
  );
};
export const AppMenuItem = styled(MenuItem)(({ theme }) => ({
  transition: 'all 200ms',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '20px',
  fontFamily: 'Proxima Nova LT',
  '& > a': {
    textDecoration: 'none',
    color: 'inherit',
  },
  '& svg': {
    marginLeft: 7,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));
