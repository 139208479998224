import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import AppButton from '../core/buttons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  hiddenFileInput: {
    position: 'fixed',
    left: -1000,
    opacity: 0,
  },
}));

interface FileDropZoneProps {
  onChange: (items: FileList) => void;
}

export const FileLoadZone: React.FC<FileDropZoneProps> = ({ onChange }) => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const handleFileInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      onChange(e.currentTarget.files);
    }
  };
  const handleClickFakeInput = () => {
    if (ref.current) {
      ref.current.click();
    }
  };
  return (
    <Box width='100%' display='flex' flexDirection='column' mt={3}>
      <input
        onChange={handleFileInput}
        className={classes.hiddenFileInput}
        type='file'
        name='dropZoneFile'
        ref={ref}
      />
      <AppButton
        onClick={handleClickFakeInput}
        type='button'
        style={{ paddingTop: 16, paddingBottom: 16 }}
        color='primary'
        variant='outlined'
        fullWidth
        size='large'
      >
        {t('component.dropZone.uploadButton')}
      </AppButton>
      <Typography
        variant='body2'
        style={{ color: '#BABABA', marginTop: 8 }}
        align='center'
      >
        {t('component.dropZone.fileRequirement')} <br />
        {/* Допустимые расширения: .pdf, .docx */}
      </Typography>
    </Box>
  );
};

export default FileLoadZone;
