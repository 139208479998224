import { ENewsType, TNews } from '../types/news.interface';
import http from './client';

async function create(news: Omit<TNews, 'id' | 'created_at'>): Promise<TNews> {
  const response = await http.post('/news/create', news);
  return response.data.data;
}

async function get(id: string): Promise<TNews> {
  const response = await http.post('/news/get', { id });
  return response.data.data;
}

async function latest(): Promise<TNews> {
  const response = await http.post('/news/latest');
  return response.data.data;
}

async function update(news: Omit<TNews, 'created_at'>) {
  const response = await http.post('/news/edit', news);
  return response.data.data;
}

async function list<T>(
  type: ENewsType,
  page?: number,
  search?: string
): Promise<T> {
  const response = await http.post('/news/list', {
    page: page,
    type,
    search,
  });
  return response.data.data;
}

const news = {
  create,
  get,
  update,
  list,
  latest,
};

export default news;
