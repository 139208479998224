import {
  Box,
  Grid,
  Hidden,
  Typography,
  withStyles,
  createStyles,
} from '@material-ui/core';
import { addMonths, addYears, subMonths, subYears } from 'date-fns';
import startOfYear from 'date-fns/esm/fp/startOfYear/index.js';
import React, { useState } from 'react';
import { ArrowDown } from '../core/icons/arrowDown';
import { useCalendarStyles } from '../inputs/dateRangeCalendar';
import { NewDateRangePickerBuilder } from './builder';
import { useTranslation } from 'react-i18next';

interface RangeListSelectorProps {
  selected: boolean;
}

const RangeListSelector = withStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 8,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 20,
      fontWeight: 600,
      cursor: 'pointer',
      fontFamily: 'Proxima Nova Lt',
      background: (props: RangeListSelectorProps) =>
        props.selected ? '#7891F8' : '#fff',
      color: (props: RangeListSelectorProps) =>
        props.selected
          ? theme.palette.common.white
          : theme.palette.common.black,
      [theme.breakpoints.down('sm')]: {
        padding: '4px 8px',
        lineHeight: 1.5,
        fontSize: 13,
        fontFamily: 'Proxima Nova Rg',
        fontWeight: 400,
      },
    },
  })
)(Box);

export interface NewDateRangeCalendarCommonProps {
  from?: Date;
  to?: Date;
  onDateSet: ({ from, to }: { from?: Date; to?: Date }) => void;
}

export type TNewRangePickerView = 'month' | 'quarter' | 'year';

export const NewDateRangePicker: React.FC<NewDateRangeCalendarCommonProps> = ({
  from,
  to,
  onDateSet,
}) => {
  const classes = useCalendarStyles();
  const { t } = useTranslation();
  const [view, setView] = useState<TNewRangePickerView>('month');
  const [initialMonth, setInitialMonth] = useState<{
    from: Date;
    to: Date;
  }>({
    from: new Date(),
    to: addMonths(new Date(), 1),
  });

  const handleInitalMonthClick = (dir: 'prev' | 'next') => {
    switch (view) {
      case 'month':
        setInitialMonth(
          dir === 'next'
            ? {
                from: addMonths(initialMonth.from, 1),
                to: addMonths(initialMonth.to, 1),
              }
            : {
                from: subMonths(initialMonth.from, 1),
                to: subMonths(initialMonth.to, 1),
              }
        );
        break;
      case 'year':
        setInitialMonth(
          dir === 'next'
            ? {
                from: addYears(initialMonth.from, 1),
                to: addYears(initialMonth.to, 1),
              }
            : {
                from: subYears(initialMonth.from, 1),
                to: subYears(initialMonth.to, 1),
              }
        );
        break;
      case 'quarter':
        setInitialMonth(
          dir === 'next'
            ? {
                from: addMonths(initialMonth.from, 3),
                to: addMonths(initialMonth.to, 3),
              }
            : {
                from: subMonths(initialMonth.from, 3),
                to: subMonths(initialMonth.to, 3),
              }
        );
        break;
      default:
        break;
    }
  };

  const handleViewPick = (view: TNewRangePickerView) => {
    setView(view);
    switch (view) {
      case 'quarter':
      case 'month':
        setInitialMonth({
          from: new Date(),
          to: new Date(),
        });
        break;
      case 'year':
        setInitialMonth({
          from: startOfYear(new Date()),
          to: new Date(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Grid container item direction='column'>
        <Grid
          item
          container
          direction='row'
          wrap='nowrap'
          justify='space-between'
        >
          <Grid
            item
            container
            direction='row'
            justify='flex-end'
            wrap='nowrap'
            sm={12}
            spacing={1}
          >
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  handleViewPick('month');
                }}
                selected={view === 'month'}
              >
                {t('calendar.month')}
              </RangeListSelector>
            </Grid>
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  handleViewPick('quarter');
                }}
                selected={view === 'quarter'}
              >
                {t('calendar.quarter')}
              </RangeListSelector>
            </Grid>
            <Grid item>
              <RangeListSelector
                onClick={() => {
                  handleViewPick('year');
                }}
                selected={view === 'year'}
              >
                {t('calendar.year')}
              </RangeListSelector>
            </Grid>
            <Box mr={1} />
          </Grid>
        </Grid>
        <Box m={2} mr={2}>
          <Grid item container direction='row' justifyContent='flex-end'>
            <Typography
              onClick={() => handleInitalMonthClick('prev')}
              className={classes.captionTextLeft}
              variant='subtitle1'
            >
              <ArrowDown
                className='lg-arrow-left'
                style={{
                  fontSize: 16,
                  transform: 'rotate(90deg)',
                  marginRight: 8,
                }}
              />
              {/* {headFormat.format(initialMonth.from)} */}
            </Typography>
            <Typography
              onClick={() => handleInitalMonthClick('next')}
              className={classes.captionTextRight}
              variant='subtitle1'
            >
              {/* {headFormat.format(initialMonth.to)} */}
              <ArrowDown
                className='lg-arrow-right'
                style={{
                  fontSize: 16,
                  transform: 'rotate(270deg)',
                  marginLeft: 8,
                }}
                rotate={'270deg'}
              />
            </Typography>
          </Grid>
        </Box>
        <Box m={2}>
          <Grid
            item
            container
            direction='row'
            style={{ maxWidth: 580 }}
            alignItems='flex-end'
            justifyContent='flex-end'
          >
            <NewDateRangePickerBuilder
              initialMonth={initialMonth}
              from={from}
              to={to}
              onDateSet={onDateSet}
              view={view}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};
