import { Box, makeStyles, Paper, Typography, styled } from '@material-ui/core';
import React, { useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import AppButton from '../core/buttons';
import AppInput from '../core/outlinedInput';
import { subscribe } from '../../api/emailSubscription';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)',
  height: 152,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  margin: '40px 0',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    paddingTop: 24,
  },
}));

const useStyles = makeStyles((theme) => ({
  controlRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 2),
      marginTop: theme.spacing(3),
    },
  },
  button: {
    marginLeft: 16,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
    paddingRight: 24,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      marginTop: 16,
      marginBottom: 32,
      maxWidth: '100%',
      height: 40,
    },
  },
  input: {
    minWidth: 234,
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      // margin: 0,
      // padding: 0,
      // maxWidth: '100%',
      // marginLeft: 16,
      // marginRight: 16,
    },
  },
  text: {
    color: '#494949',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 184,
      textAlign: 'center',
    },
  },
}));

const SubsCard: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [sub, setSub] = useLocalStorage('email_subs', false);
  const { t } = useTranslation();

  const handleSubsConfirm = async (email: string) => {
    try {
      await subscribe(email);
      setSub(true);
    } catch (err) {
      enqueueSnackbar((err as any).response.data.errors[0].reason, {
        variant: 'error',
      });
    }
  };
  return (
    <StyledPaper>
      {sub ? (
        <Typography variant='h3' className={classes.text}>
          {t('page.news.followedSuccessful')}
        </Typography>
      ) : (
        <Typography variant='h3' className={classes.text}>
          {t('page.news.readNewsHeadline')}
        </Typography>
      )}
      {!sub && (
        <Box
          mt={2}
          display='flex'
          flexDirection='row'
          className={classes.controlRoot}
        >
          <AppInput
            className={classes.input}
            placeholder={t('words.email')}
            type='text'
            defaultValue={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            name='email'
            id='subs_email'
          />
          <AppButton
            onClick={() => handleSubsConfirm(email)}
            className={classes.button}
            variant='contained'
            color='primary'
          >
            {t('page.news.subscribeNewsletter')}
          </AppButton>
        </Box>
      )}
    </StyledPaper>
  );
};

export default SubsCard;
