import http from './client';
import IDocProduct from '../types/docRelease.interface';
import IFile from '../types/file.interface';

async function docsReleasesList(): Promise<IDocProduct[]> {
  const res = await http.post('/docs/release/list');
  return res.data.data;
}
async function docsReleaseCreate({
  title,
  picture,
}: {
  title: string;
  picture?: IFile;
}): Promise<IDocProduct> {
  const res = await http.post('/docs/release/create', {
    title,
    picture: picture?.id,
  });
  return res.data.data;
}

async function docsReleaseUpdate({
  id,
  title,
  picture,
  sortIndex,
}: {
  id: string;
  title: string;
  picture?: IFile;
  sortIndex: number;
}): Promise<IDocProduct> {
  const res = await http.post('/docs/release/edit', {
    id,
    title,
    picture: picture?.id ?? null,
    sortIndex,
  });
  return res.data.data;
}

async function docsReleaseSort({
  target,
  payload,
}: {
  target: IDocProduct;
  payload: string[];
}) {
  const res = await http.post('/docs/release/sort', {
    target: target.id,
    payload,
  });
  return res.data.data;
}

async function docsReleaseDelete({ target }: { target: IDocProduct }) {
  const res = await http.post('/docs/release/delete', {
    target: target.id,
  });
  return res.data.data;
}

const release = {
  list: docsReleasesList,
  create: docsReleaseCreate,
  sort: docsReleaseSort,
  update: docsReleaseUpdate,
  delete: docsReleaseDelete,
};

export default release;
