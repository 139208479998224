import React from 'react';
import MaskedInput from 'react-text-mask';
import maskArray from "react-text-mask"
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

//@ts-ignore
const MaskedPhone: React.FC = ({ inputRef, ...props }) => {
  const { t } = useTranslation();
  return (
    <NumberFormat
      {...props}
      format='+######################'
      mask=''
      allowEmptyFormatting={true}
    />
  );
};

export default MaskedPhone;
