import ICity from './city.interface';
import { EProjectStatus } from './project.interface';

export enum EUserRole {
  admin = 'admin',
  partner = 'partner',
  employee = 'employee',
  customer = 'customer',
  partner_admin = 'partner_admin',
  documentation_admin = 'documentation_admin',
  end_customer = 'end_customer',
  internal_team = 'internal_team',
}

export default interface IUser {
  id: string;
  role: EUserRole;
  fullName: string;
  email: string;
  phone: string;
  position: string;
  cities: ICity[];
  isAgreementAccepted: boolean;
  followedStatus: EProjectStatus[];
  isSubscribedToPartnersRegistrations: boolean;
  permissions: string[];
  createdAt?: any;
  isNew?: boolean;
}
