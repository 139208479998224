import http from './client';

async function update({ entity, ids }: { entity: string; ids: Array<string> }) {
  return await http.post('/sort/update', { entity, array: ids });
}

const sort = {
  update,
};

export default sort;
