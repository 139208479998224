import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QuoteIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 58 48'>
      <path
        d='M7.69176 48C17.2545 41.022 24.7383 28.9692 24.7383 16.0705C24.7383 5.70925 18.5018 0 11.6416 0C5.40502 0 0.623656 5.28635 0.623656 11.4185C0.623656 17.7621 4.78136 22.2026 10.6021 22.2026C11.6416 22.2026 13.0968 21.9912 13.5125 21.7797C12.681 28.5462 6.65233 37.0044 0 41.4449L7.69176 48ZM41.1613 48C50.5161 41.022 58 28.9692 58 16.0705C58 5.70925 51.7634 0 44.9032 0C38.8746 0 33.8853 5.28635 33.8853 11.4185C33.8853 17.7621 38.2509 22.2026 44.0717 22.2026C44.9032 22.2026 46.3584 21.9912 46.7742 21.7797C45.9427 28.5462 40.1219 37.0044 33.4695 41.4449L41.1613 48Z'
        fill='#7891F8'
      />
    </SvgIcon>
  );
};

export default QuoteIcon;
