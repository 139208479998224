import { configureStore } from '@reduxjs/toolkit';
import userSlice from './user';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

export type ThunkApi = {
  dispatch: AppDispatch;
  state: RootState;
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
