import {
  Drawer,
  makeStyles,
  Typography,
  Box,
  Divider,
  Collapse,
  styled,
  Select,
} from '@material-ui/core';
import {
  ArrowForward,
  CancelOutlined,
  KeyboardArrowDown,
} from '@material-ui/icons';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
// import { useQuery } from 'react-query';
import { ProjectsSearchBarProps } from '.';
// import { default as pAPI  } from '../../api/partner';
// import IPartner from '../../types/partner.interface';
import { EProjectStatus, statusDict } from '../../types/project.interface';
import { dateFormatter } from '../../utils/dateTimeFormatter';
import { AppMenuItem } from '../core/contextMenu';
import { CalendarIcon } from '../core/icons/calendar';
import CloseIcon from '../core/icons/close';
import FilterIcon from '../core/icons/filter';
import AppInput from '../core/outlinedInput';
import DateRangeCalendar from '../inputs/dateRangeCalendar';
import { ProjectsExport } from '../projectsExport';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import AppButton from '../core/buttons';

const dtFormat = Intl.DateTimeFormat(i18n.t('intlLocale'), {
  day: '2-digit',
  month: 'short',
});

const useStyles = makeStyles(() => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
  pointerText: {
    cursor: 'pointer',
    // flexGrow: 1,
  },
  searchParamsRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  width: '100%',
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const RangePickerInputBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.black,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid #E8E8E8',
  borderRadius: 4,
  padding: '12px 20px',
  '& .small-calendar': {
    fontSize: 16,
    marginRight: 8,
    height: 20,
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  height: 27,
  borderRadius: 33,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 12px',
  marginRight: 8,
  marginBottom: 8,
  '&.all': {
    backgroundColor: '#7891F8',
    color: '#fff',
  },
  '& .co': {
    marginLeft: 5,
    fontSize: 14,
  },
}));

interface ProjectsSearchBarPropsMobile extends ProjectsSearchBarProps {
  dropAll: () => void;
}

export const ProjectsSearchbarMobile: React.FC<
  ProjectsSearchBarPropsMobile
> = ({
  searchParams,
  onPartnerPick,
  onStatusPick,
  onDateRangePick,
  onSearch,
  dropAll,
  onReset,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  // const [partnerSearch, setPartnerSearch] = useState('');
  // const [searchAnchor, setSearchAnchor] = useState<HTMLElement | null>(null);
  // const partnerSearchQuery = useQuery(['partners', partnerSearch], () => pAPI.list(0, {search: partnerSearch}), {
  //   enabled: !!partnerSearch,
  // });

  // const handlePartnerSearchClick = (partner: IPartner) => {
  //   setPartnerSearch(partner.name);
  //   onPartnerPick(partner.id);
  // };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const debouncedChangeHandler = useMemo(() => changeHandler, []);
  return (
    <>
      <Box m={2.5}>
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Typography
            style={{ display: 'inline-flex', alignItems: 'center' }}
            variant='h4'
            color='textSecondary'
            onClick={() => setDrawerOpen(true)}
          >
            <FilterIcon style={{ marginRight: 11 }} />{' '}
            {t('component.projectsSearchbar.filtersLable')}
          </Typography>
          <ProjectsExport searchParams={searchParams} />
        </Box>
        <Box className={classes.searchParamsRoot} mt={2}>
          {searchParams.status && (
            <SearchBox onClick={() => onStatusPick(undefined)}>
              <span>{statusDict[searchParams.status]}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
          {searchParams.periodFrom && searchParams.periodTo && (
            <SearchBox
              onClick={() =>
                onDateRangePick({ from: undefined, to: undefined })
              }
            >
              <span>
                {dtFormat.format(new Date(searchParams.periodFrom))}
                <ArrowForward style={{ fontSize: 10, margin: '0 5px' }} />{' '}
                {dtFormat.format(new Date(searchParams.periodFrom))}
              </span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
          {searchParams.search && (
            <SearchBox onClick={() => onSearch('')}>
              <span>{searchParams.search}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
          {Object.values(searchParams).filter((val) => val !== undefined)
            .length > 0 && (
            <SearchBox onClick={dropAll} className='all'>
              <span>{t('component.projectsSearchbar.clear')}</span>{' '}
              <CancelOutlined className='co' />
            </SearchBox>
          )}
        </Box>
      </Box>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onBackdropClick={() => setDrawerOpen(false)}
      >
        <Box p={3}>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='nowrap'
            justifyContent='space-between'
          >
            <Typography variant='h4'>
              {t('component.projectsSearchbar.filtersLable')}
            </Typography>
            <CloseIcon onClick={() => setDrawerOpen(false)} />
          </Box>
          <Box mb={2} />
          <RangePickerInputBox onClick={(e) => setShowCalendar((c) => !c)}>
            <CalendarIcon className='small-calendar' />
            <Typography className={classes.pointerText} variant='body1'>
              {searchParams.periodFrom
                ? dateFormatter.format(new Date(searchParams.periodFrom))
                : '-- ---'}
            </Typography>
            <ArrowForward className={classes.smallArrow} fontSize='small' />
            <Typography className={classes.pointerText} variant='body1'>
              {searchParams.periodTo
                ? dateFormatter.format(new Date(searchParams.periodTo))
                : '-- ---'}
            </Typography>
          </RangePickerInputBox>
          <Box mb={2} />
          <Collapse in={showCalendar} timeout={200}>
            <Box display='flex' flexDirection='column' width='100%'>
              <DateRangeCalendar
                from={
                  searchParams.periodFrom
                    ? new Date(searchParams.periodFrom)
                    : undefined
                }
                to={
                  searchParams.periodTo
                    ? new Date(searchParams.periodTo)
                    : undefined
                }
                onDateSet={onDateRangePick}
              />
              <Divider />
              <Box mb={4} />
            </Box>
          </Collapse>
          <AppInput
            placeholder={t('words.search')}
            onChange={debouncedChangeHandler}
          />
          <Box mb={2} />
          <StyledSelect
            variant='outlined'
            name='status'
            placeholder={t('component.projectsSearchbar.status')}
            onChange={(e) => {
              if (e.target.value === 'all') {
                onStatusPick(undefined);
              } else {
                //@ts-ignore
                onStatusPick(e.target.value);
              }
            }}
            defaultValue={searchParams.status || 'all'}
            IconComponent={KeyboardArrowDown}
          >
            <AppMenuItem value={'all'}>{t('projectStatus.all')}</AppMenuItem>
            {Object.keys(EProjectStatus).map((key) => (
              <AppMenuItem
                value={EProjectStatus[key as keyof typeof EProjectStatus]}
              >
                {t(`projectStatus.${key}`)}
              </AppMenuItem>
            ))}
          </StyledSelect>
          <Box mb={2} />

          <AppButton variant='contained' onClick={onReset} color='primary'>
            {t('page.projects.resetFilter')}
          </AppButton>
        </Box>
      </Drawer>
    </>
  );
};
