import IPartner, {
  IPartnerShortStats,
  IPartnersStats,
} from '../types/partner.interface';
import http from './client';
import ICity from '../types/city.interface';

async function register(
  partner: Omit<
    IPartner,
    'id' | 'createdAt' | 'isNew' | 'manager' | 'logoId' | 'countries'
  > & {
    manager?: string;
    countries: string[];
  }
) {
  return http.post('/auth/register', partner);
}

async function update(
  partner: Omit<
    IPartner,
    'createdAt' | 'isNew' | 'manager' | 'files' | 'countries'
  > & {
    manager?: string;
    files: Array<string>;
    countries: Array<string>;
  }
) {
  return http.post('/partner/edit', partner);
}

async function resetLogo(partner: Pick<IPartner, 'id'>) {
  return http.post('/partner/reset_logo', partner);
}

async function list(
  page: number = 1,
  search?: {
    search?: string;
    onlyDeleted?: boolean;
    onlyVerifyPending?: boolean;
    filtered?: boolean;
  },
  sort?: { by: string; direction: 'asc' | 'desc' }
): Promise<{ hasNextPage: boolean; items: Array<IPartner> }> {
  const res = await http.post('/partner/list', { page: page, sort, ...search });
  return res.data.data;
}

async function stats(): Promise<IPartnersStats> {
  const res = await http.post('/partner/stats');
  return res.data.data;
}
async function get(
  id: string
): Promise<{ partner: IPartner; stats: IPartnerShortStats }> {
  const response = await http.post('/partner/get', { id });
  return response.data.data;
}

async function verify(params: {
  id: string;
  countries: ICity[];
}): Promise<{ partner: IPartner; stats: IPartnerShortStats }> {
  const response = await http.post('/partner/verify', {
    id: params.id,
    countries: params.countries.map((v) => v.id),
  });
  return response.data.data;
}
async function me(): Promise<{ partner: IPartner; stats: IPartnerShortStats }> {
  const response = await http.post('/partner/me', {});
  return response.data.data;
}
const partner = {
  register,
  list,
  stats,
  get,
  update,
  me,
  verify,
  resetLogo,
};

export default partner;
