import {
  Box,
  Checkbox,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useRbac from '../../hooks/useRbac';
import AppButton from '../core/buttons';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import PenIcon from '../core/icons/pen';
import TrashBinIcon from '../core/icons/trashBin';
import TwoLinesIcon from '../core/icons/twoLines';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FormItemRow from '../forms/formRow';
import { default as cAPI } from './../../api/employee';
import { AppMenuItem } from '../core/contextMenu';
import { roleDict } from '../../types/employees.interface';

const useStyles = makeStyles((theme) => ({
  categoryRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    '&.dragging': {
      boxShadow: ' 0px 4px 20px rgba(15, 4, 139, 0.11)',
      '& .two-lines': {
        opacity: 0,
      },
    },
    '& .two-lines': {
      fontSize: 4,
      marginRight: 4,
    },
    '& .helper-ico': {
      fontSize: 16,
      '&.check': {
        color: '#00B852',
      },
    },
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    marginRight: 5,
    strokeWidth: '0.1px',
    stroke: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
    },
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  borderRadius: 3,
  border: '1px solid #e8e8e8',
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
});

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    background: '#fff',
  },
});

interface GroupItemProps {
  isDragging?: boolean;
  group: any;
  onEdit: (
    payload: {
      title: string;
      readAccess: string;
      writeAccess: string;
      readAccessPayload: string[];
      writeAccessPayload: string[];
    },
    id: string
  ) => void;
  onDelete: (id: string) => void;
}

const UserChip: React.FC<{ fullName: string }> = ({ fullName }) => {
  let trimmedName = fullName;
  if (fullName.length > 20) {
    trimmedName = fullName.substring(0, 17).trim() + '...';
  }

  return (
    <span
      style={{
        display: 'inline-block',
        background: '#1C00A0',
        color: '#fff',
        borderRadius: '33px',
        padding: '4px 15px',
        fontSize: '14px',
      }}
    >
      {trimmedName}
    </span>
  );
};

export const GroupItem: React.FC<GroupItemProps> = ({
  group,
  onEdit,
  isDragging,
  onDelete,
}) => {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [title, setTitle] = useState(group.title);
  
  const [readRestrictionType, setReadRestrictionType] = useState<'everyone' | 'users' | 'roles'>(group.readAccess);
  const [writeRestrictionType, setWriteRestrictionType] = useState<'users' | 'roles'>(group.writeAccess);

  const [readAccessPayload, setReadAccessPayload] = useState<string[]>(group.readAccessPayload);
  const [writeAccessPayload, setWriteAccessPayload] = useState<string[]>(group.writeAccessPayload);

  const { t } = useTranslation();
  const rbac = useRbac();

  const usersSearchQuery = useQuery(
    ['employees'],
    () =>
      cAPI.list(0, {
        listEverything: true,
      }),
    {
      useErrorBoundary: false,
      suspense: false,
    }
  );

  const rolesList = Object.entries(roleDict);

  return (
    <div className={`${classes.categoryRoot} ${isDragging ? 'dragging' : ''}`}>
      {isEditing ? (
        <Box
          style={{ backgroundColor: '#EDF0F9' }}
          py={4}
          px={2}
          mt={2}
          width={'100%'}
        >
          <FormItemRow>
            <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
              {t('component.materialGroupDrawer.name')}
            </AppInputLabel>
            <AppInput
              defaultValue={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              error={!title}
            />
          </FormItemRow>

          <FormItemRow>
            <AppInputLabel>
              {t('component.materialGroupDrawer.canViewAccessType')}
            </AppInputLabel>
            <StyledSelect
              variant='outlined'
              IconComponent={(props) => <FieldArrowDown {...props} />}
              value={readRestrictionType}
              onChange={(e: any) => {
                setReadRestrictionType(e.target.value)
                setReadAccessPayload([])
              }}
            >
              <AppMenuItem value='users'>
                {t('materialGroupRestriction.users')}
              </AppMenuItem>
              <AppMenuItem value='roles'>
                {t('materialGroupRestriction.roles')}
              </AppMenuItem>
              <AppMenuItem value='everyone'>
                {t('materialGroupRestriction.everyone')}
              </AppMenuItem>
            </StyledSelect>
          </FormItemRow>

          <FormItemRow>
            <AppInputLabel>
              {t('component.materialGroupDrawer.canAddAccessType')}
            </AppInputLabel>
            <StyledSelect
              variant='outlined'
              IconComponent={(props) => <FieldArrowDown {...props} />}
              value={writeRestrictionType}
              onChange={(e: any) => {
                setWriteRestrictionType(e.target.value)
                setWriteAccessPayload([])
              }}
            >
              <AppMenuItem value='users'>
                {t('materialGroupRestriction.users')}
              </AppMenuItem>
              <AppMenuItem value='roles'>
                {t('materialGroupRestriction.roles')}
              </AppMenuItem>
            </StyledSelect>
          </FormItemRow>

          {writeRestrictionType === 'users' &&
            <FormItemRow>
              <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
                {t('component.materialGroupDrawer.canAdd')}
              </AppInputLabel>
              <StyledSelect
                multiple
                value={writeAccessPayload}
                disableUnderline={true}
                style={{ width: '100%', height: 'auto' }}
                IconComponent={(props) => <FieldArrowDown {...props} />}
                renderValue={(selected: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gridGap: 1,
                    }}
                  >
                    {selected.map((value: any) => (
                      <span
                        style={{
                          display: 'inline-block',
                          background: '#1C00A0',
                          color: '#fff',
                          borderRadius: '33px',
                          padding: '4px 15px',
                          fontSize: '14px',
                        }}
                      >
                        {
                          usersSearchQuery?.data?.items.find(
                            (v) => v.id === value
                          )?.fullName
                        }
                      </span>
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    square: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {!usersSearchQuery.isError &&
                  usersSearchQuery.data?.items &&
                  usersSearchQuery.data?.items.map((i: any) => (
                    <CustomMenuItem
                      value={i.id}
                      key={i.id}
                      onClick={() => {
                        if (writeAccessPayload.some((v) => v === i.id)) {
                          setWriteAccessPayload(writeAccessPayload.filter((c) => c !== i.id));
                          return;
                        }

                        setWriteAccessPayload([...writeAccessPayload, i.id]);
                      }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr min-content',
                          width: '100%',
                        }}
                      >
                        <span>{i.fullName}</span>
                        <Checkbox
                          className={classes.checkbox}
                          color='primary'
                          checked={writeAccessPayload.some((v) => v === i.id)}
                        />
                      </div>
                    </CustomMenuItem>
                  ))}
              </StyledSelect>
            </FormItemRow>
          }

          {writeRestrictionType === 'roles' &&
            <FormItemRow>
              <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
                {t('component.materialGroupDrawer.canAdd')}
              </AppInputLabel>

              <StyledSelect
                multiple
                value={writeAccessPayload}
                disableUnderline={true}
                style={{ width: '100%', height: 'auto' }}
                IconComponent={(props) => <FieldArrowDown {...props} />}
                renderValue={(selected: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gridGap: 1,
                    }}
                  >
                    {selected.map((value: any) => (
                      <span
                        style={{
                          display: 'inline-block',
                          background: '#1C00A0',
                          color: '#fff',
                          borderRadius: '33px',
                          padding: '4px 15px',
                          fontSize: '14px',
                        }}
                      >
                        {
                          t(`role.${value}`)
                        }
                      </span>
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    square: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {rolesList.map(([roleKey, roleLabel]) => (
                    <CustomMenuItem
                      value={roleKey}
                      key={roleKey}
                      onClick={() => {
                        if (writeAccessPayload.some((v) => v === roleKey)) {
                          setWriteAccessPayload(writeAccessPayload.filter((c) => c !== roleKey));
                          return;
                        }

                        setWriteAccessPayload([...writeAccessPayload, roleKey]);
                      }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr min-content',
                          width: '100%',
                        }}
                      >
                        <span>{roleLabel}</span>
                        <Checkbox
                          className={classes.checkbox}
                          color='primary'
                          checked={writeAccessPayload.some((v) => v === roleKey)}
                        />
                      </div>
                    </CustomMenuItem>
                  ))}
              </StyledSelect>
            </FormItemRow>
          }

          
          {readRestrictionType === 'roles' &&
            <FormItemRow>
              <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
                {t('component.materialGroupDrawer.canView')}
              </AppInputLabel>

              <StyledSelect
                multiple
                value={readAccessPayload}
                disableUnderline={true}
                style={{ width: '100%', height: 'auto' }}
                IconComponent={(props) => <FieldArrowDown {...props} />}
                renderValue={(selected: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gridGap: 1,
                    }}
                  >
                    {selected.map((value: any) => (
                      <span
                        style={{
                          display: 'inline-block',
                          background: '#1C00A0',
                          color: '#fff',
                          borderRadius: '33px',
                          padding: '4px 15px',
                          fontSize: '14px',
                        }}
                      >
                        {
                          t(`role.${value}`)
                        }
                      </span>
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    square: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {rolesList.map(([roleKey, roleLabel]) => (
                    <CustomMenuItem
                      value={roleKey}
                      key={roleKey}
                      onClick={() => {
                        if (readAccessPayload.some((v) => v === roleKey)) {
                          setReadAccessPayload(readAccessPayload.filter((c) => c !== roleKey));
                          return;
                        }

                        setReadAccessPayload([...readAccessPayload, roleKey]);
                      }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr min-content',
                          width: '100%',
                        }}
                      >
                        <span>{roleLabel}</span>
                        <Checkbox
                          className={classes.checkbox}
                          color='primary'
                          checked={readAccessPayload.some((v) => v === roleKey)}
                        />
                      </div>
                    </CustomMenuItem>
                  ))}
              </StyledSelect>
            </FormItemRow>
          }

          {readRestrictionType === 'users' &&
            <FormItemRow>
              <AppInputLabel style={{ alignSelf: 'flex-start', width: '100px' }}>
                {t('component.materialGroupDrawer.canView')}
              </AppInputLabel>
              <StyledSelect
                multiple
                value={readAccessPayload}
                disableUnderline={true}
                style={{ width: '100%', height: 'auto' }}
                IconComponent={(props) => <FieldArrowDown {...props} />}
                renderValue={(selected: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gridGap: 1,
                    }}
                  >
                    {selected.map((id: string) => (
                      <UserChip
                        fullName={
                          usersSearchQuery?.data?.items.find((v) => v.id === id)
                            ?.fullName ?? ''
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    square: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {!usersSearchQuery.isError &&
                  usersSearchQuery.data?.items &&
                  usersSearchQuery.data?.items.map((i: any) => (
                    <CustomMenuItem
                      value={i.id}
                      key={i.id}
                      onClick={() => {
                        if (readAccessPayload.some((v) => v === i.id)) {
                          setReadAccessPayload(readAccessPayload.filter((c) => c !== i.id));
                          return;
                        }

                        setReadAccessPayload([...readAccessPayload, i.id]);
                      }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr min-content',
                          width: '100%',
                        }}
                      >
                        <span>{i.fullName}</span>
                        <Checkbox
                          className={classes.checkbox}
                          color='primary'
                          checked={readAccessPayload.some((v) => v === i.id)}
                        />
                      </div>
                    </CustomMenuItem>
                  ))}
              </StyledSelect>
            </FormItemRow>
          }

          <FormItemRow style={{ margin: 0 }}>
            <AppInputLabel />
            <Box display='flex' flexDirection='row'>
              <Box mr={3}>
                <AppButton
                  disabled={!title}
                  onClick={() => {
                    onEdit(
                      {
                        title,
                        readAccess: readRestrictionType,
                        writeAccess: writeRestrictionType,
                        readAccessPayload,
                        writeAccessPayload,
                      },
                      group.id
                    );
                    setEditing(false);
                  }}
                  style={{ backgroundColor: '#fff' }}
                  variant='outlined'
                  color='primary'
                >
                  {t('button.save')}
                </AppButton>
              </Box>
              <AppButton color='primary' onClick={() => setEditing(false)}>
                {t('button.cancel')}
              </AppButton>
            </Box>
          </FormItemRow>
        </Box>
      ) : (
        <Box display='flex' flexDirection='row' alignItems='center'>
          <TwoLinesIcon className='two-lines' />
          <Typography variant='body1'>{group.title}</Typography>
        </Box>
      )}

      {!isEditing && (
        <Box>
          <IconButton color='secondary' onClick={() => onDelete(group.id)}>
            <TrashBinIcon className='helper-ico' />
          </IconButton>
          <IconButton color='primary' onClick={() => setEditing(true)}>
            <PenIcon className='helper-ico' />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
