import { Box, Grid, styled, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';
import IPartner from '../../types/partner.interface';
import IUser, { EUserRole } from '../../types/user.interface';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import ExitIcon from '../core/icons/exit';
import PenIcon from '../core/icons/pen';
import { useTranslation } from 'react-i18next';
import { EProjectStatus, statusDict } from '../../types/project.interface';
import AppInputLabel from '../core/label';
import { useAppSelector } from '../../hooks/useStore';
import { NavLink } from 'react-router-dom';
import { StyledNavLink } from '../userCard';

interface UserProfileCardProps {
  onClose: () => void;
  onEditClick: () => void;
  onLogout: () => void;
  user: IUser;
  partner?: IPartner;
}

const StyledExit = styled(ExitIcon)({
  width: 15,
  height: 15,
  marginLeft: 9,
});

const StyledPen = styled(PenIcon)({
  cursor: 'pointer',
  width: 16,
  height: 16,
});

const FollowedProjectsHeadline = styled(AppInputLabel)({
  fontSize: 14,
  fontWeight: 600,
  width: '100%',
  fontFamily: 'Proxima Nova Rg',
  display: 'inline',
  maxWidth: '100%',
});

const FollowedStatusHeadline = styled(AppInputLabel)({
  fontSize: 14,
  width: '100%',
  fontFamily: 'Proxima Nova Rg',
  display: 'inline',
  maxWidth: '100%',
});

export const UserProfileCard: React.FC<UserProfileCardProps> = ({
  onClose,
  onEditClick,
  user,
  partner,
  onLogout,
}) => {
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.user);

  return (
    <Grid container>
      <Box width={280} p={2}>
        <Grid container item direction='column'>
          <Grid
            container
            item
            direction='row'
            wrap='nowrap'
            justify='space-between'
          >
            <Grid container item xs={10} direction='column'>
              <Typography variant='h4'>{user.fullName}</Typography>
              <Box mb={1} />
              <Typography variant='subtitle1'>{user.position}</Typography>
              <Box mb={2} />
              <Typography variant='subtitle1'>
                {formatPhoneNumber(user.phone)}
              </Typography>
              <Box mb={1} />
              <Typography
                variant='subtitle1'
                component='a'
                href={`mailto:${user.email}`}
              >
                {user.email}
              </Typography>
              <Box mb={3} />
            </Grid>
            <Grid
              item
              container
              alignItems='flex-start'
              justify='flex-end'
              xs={1}
            ></Grid>
          </Grid>
          {partner && partner.manager && !isEmpty(partner.manager) && (
            <Grid container item direction='column' xs={12}>
              <Typography variant='body2' style={{ color: '#909090' }}>
                {t('component.userProfileCard.manager')}
              </Typography>
              <Box mb={1} />
              <Typography variant='subtitle1'>
                {partner.manager.fullName}
              </Typography>
              <Box mb={0.5} />
              <Typography variant='body2'>
                {formatPhoneNumber(partner.manager.phone)} ·{' '}
                {partner.manager.email}
              </Typography>
              <Box mb={4} />
            </Grid>
          )}

          {profile?.role === EUserRole.admin && (
            <>
              <FollowedProjectsHeadline>
                {t('component.projectFollow.headline')}
              </FollowedProjectsHeadline>

              <Grid container spacing={2}>
                <Grid item md={5}>
                  <FollowedStatusHeadline>
                    {t('component.statusFollow.headline')}
                  </FollowedStatusHeadline>
                </Grid>
                <Grid item md={7} style={{ fontSize: 14 }}>
                  {user.followedStatus
                    .filter((v) => Object.keys(statusDict).includes(v))
                    .map((v) => {
                      const str = statusDict[v as keyof typeof EProjectStatus];

                      return (
                        str.charAt(0).toUpperCase() +
                        str.slice(1).replace(/ /g, '\u00A0')
                      );
                    })
                    .join(', ') || '-'}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                <Grid item md={5}>
                  <FollowedStatusHeadline>
                    {t('component.statusFollow.inCountries')}
                  </FollowedStatusHeadline>
                </Grid>
                <Grid item md={7} style={{ fontSize: 14 }}>
                  {user.cities.map((v) => v.name).join(', ') || '-'}
                </Grid>
              </Grid>

              <FollowedProjectsHeadline>
                {t('component.projectFollow.subjectsHeadline')}
              </FollowedProjectsHeadline>

              <Grid container style={{ marginBottom: 16, fontSize: 14 }}>
                {user?.isSubscribedToPartnersRegistrations
                  ? t('component.projectFollow.partnerRegister')
                  : t('component.projectFollow.noSubscribes')}
              </Grid>
            </>
          )}

          <div style={{ marginBottom: 10 }}>
            <AppButton
              size="small"
              variant='contained'
              color='primary'
              onClick={() => {
                onClose();
              }}
            >
              <StyledNavLink to='/feedback'>
                {t('header.button.feedback')}
              </StyledNavLink>
            </AppButton>
          </div>

          <Grid
            item
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <AppButton
              size='small'
              variant='contained'
              color='primary'
              onClick={onLogout}
              endIcon={<StyledExit />}
            >
              {t('header.button.exit')}
            </AppButton>
            <StyledPen color='primary' onClick={onEditClick} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default UserProfileCard;
