import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  styled,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { updatePass, updateUser } from '../../api/auth';
import { phoneToValid } from '../../utils/etc';
import AppButton from '../core/buttons';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import MaskedPhone from '../inputs/maskedPhone';
import FormItemRow from './formRow';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { EProjectStatus, statusDict } from '../../types/project.interface';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { default as cAPI } from '../../api/city';
import ICity from '../../types/city.interface';
import { useAppDispatch } from '../../hooks/useStore';
import { tryLogin } from '../../store/user/actions';

interface UserEditProfileFormProps {
  name: string;
  position: string;
  phone: string;
  email: string;
  followed: EProjectStatus[];
  cities: ICity[];
  id: string;
  isAdmin: boolean;
  isSubscribedToPartnersRegistering: boolean;
}

const SmallAppInput = styled(AppInput)({
  fontSize: 13,
  fontFamily: 'Proxima Nova Rg',
});

const SmallLabel = styled(AppInputLabel)({
  fontSize: 13,
  fontFamily: 'Proxima Nova Rg',
});

const SuccessText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: 13,
  fontWeight: 600,
  lineHeight: 1.5,
}));

const FollowedProjectsHeadline = styled(AppInputLabel)({
  fontSize: 14,
  fontWeight: 600,
  width: '100%',
  fontFamily: 'Proxima Nova Rg',
  display: 'inline',
});

const FollowedStatusHeadline = styled(AppInputLabel)({
  fontSize: 14,
  width: '100%',
  fontFamily: 'Proxima Nova Rg',
  display: 'inline',
});

const useStyles = makeStyles((theme) => ({
  checkbox: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    marginRight: 5,
    strokeWidth: '0.1px',
    stroke: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
    },
  },

  followStatusCheckbox: {
    marginBottom: 5,
  },

  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    color: '#fff',
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
}));

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    background: '#fff',
  },
});

export const UserEditProfileForm: React.FC<UserEditProfileFormProps> = ({
  name,
  position,
  phone,
  email,
  id,
  followed,
  isAdmin,
  cities,
  isSubscribedToPartnersRegistering,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showPassEdit, setShowPassEdit] = useState(false);
  const [followedStatus, setFollowedStatuses] = useState(followed);
  const [isFollowedToPartnersRegistering, setIsFollowedToPartnersRegistering] =
    useState(isSubscribedToPartnersRegistering);

  const [followedCountries, setFollowedCountries] = React.useState<string[]>(
    cities.map((v) => v.id)
  );

  const { enqueueSnackbar } = useSnackbar();
  const [passUpdate, setPassUpdate] = useState<'ok' | 'fail' | 'unknown'>(
    'unknown'
  );

  const citySearchQuery = useQuery(['cities'], () => cAPI.list(0), {
    useErrorBoundary: false,
    suspense: false,
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      id: id,
      name: name,
      position: position,
      phone: phone,
      email: email,
    },
    validationSchema: yup.object({
      name: yup.string().required(t('validation.required')),
      email: yup
        .string()
        .email(t('validation.email'))
        .required(t('validation.required')),
      phone: yup.string().required(t('validation.required')),
    }),
    onSubmit: async (values) => {
      const np = phoneToValid(values.phone);
      try {
        await updateUser({
          id: values.id,
          fullName: values.name,
          phone: np,
          email: values.email,
          position: values.position,
          followedStatus,
          cities: followedCountries,
          isSubscribedToPartnersRegistrations: isFollowedToPartnersRegistering,
        });
        await dispatch(tryLogin());
        enqueueSnackbar(t('toastNotifications.applied'));
      } catch (error) {
        if ((error as any)?.response?.data.errors) {
          formik.setFieldError(
            'email',
            (error as any)?.response?.data.errors[0].reason
          );
        }
      }
    },
  });

  const passFormik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      prev: '',
      next: '',
      repeat: '',
    },
    onSubmit: async (values, helpers) => {
      helpers.validateForm(values);
      try {
        await updatePass(id, values.prev, values.next);
        setPassUpdate('ok');
      } catch (error) {
        setPassUpdate('fail');
      }
    },
    validate: (values) => {
      if (!values.next || !values.repeat) {
        return {
          repeat: t('component.changePassword.passwordCantBeChanged'),
        };
      }
      if (values.next !== values.repeat) {
        return {
          repeat: t('component.changePassword.passwordsNotEqual'),
        };
      }
    },
  });

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setFollowedCountries(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Grid container item>
      <Box p={2}>
        <Grid container item direction='column'>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              multiline
              defaultValue={formik.initialValues.name}
              name='name'
              onChange={formik.handleChange}
            />
            <Box mb={4} />
            <FormItemRow>
              <SmallLabel>{t('words.position')}</SmallLabel>
              <SmallAppInput
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.position}
                name='position'
              />
            </FormItemRow>
            <FormItemRow>
              <SmallLabel>{t('words.email')}</SmallLabel>
              <SmallAppInput
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.email}
                name='email'
                error={!!formik.errors.email}
                helperText={formik.errors.email}
              />
            </FormItemRow>
            <FormItemRow>
              <SmallLabel>{t('words.phone')}</SmallLabel>
              <SmallAppInput
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.phone}
                name='phone'
                inputComponent={MaskedPhone}
              />
            </FormItemRow>

            {isAdmin && (
              <>
                <FollowedProjectsHeadline>
                  {t('component.projectFollow.headline')}
                </FollowedProjectsHeadline>

                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FollowedStatusHeadline>
                      {t('component.statusFollow.headline')}
                    </FollowedStatusHeadline>
                  </Grid>
                  <Grid item md={8}>
                    {Object.keys(EProjectStatus).map((currStatus) => (
                      <FormItemRow className={classes.followStatusCheckbox}>
                        <Checkbox
                          className={classes.checkbox}
                          color='primary'
                          defaultChecked={followedStatus.some(
                            (v) => v === currStatus
                          )}
                          onChange={() => {
                            if (followedStatus.some((v) => v === currStatus)) {
                              setFollowedStatuses(
                                followedStatus.filter((v) => v !== currStatus)
                              );
                              return;
                            }
                            setFollowedStatuses([
                              ...followedStatus,
                              currStatus as EProjectStatus,
                            ]);
                          }}
                        />
                        <SmallLabel>
                          {statusDict[currStatus as keyof typeof EProjectStatus]
                            .substr(0, 1)
                            .toUpperCase() +
                            statusDict[
                              currStatus as keyof typeof EProjectStatus
                            ]
                              .substr(1, 64)
                              .toLowerCase()}
                        </SmallLabel>
                      </FormItemRow>
                    ))}
                  </Grid>
                </Grid>

                <FollowedStatusHeadline>
                  {t('component.countryFollow.headline')}
                </FollowedStatusHeadline>
                <Select
                  multiple
                  value={followedCountries}
                  onChange={handleChange}
                  style={{ width: '100%', marginBottom: 15 }}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gridGap: 1 }}>
                      {selected.map((value: any) => (
                        <span
                          style={{
                            display: 'inline-block',
                            background: '#1C00A0',
                            color: '#fff',
                            borderRadius: '33px',
                            padding: '4px 15px',
                            fontSize: '14px',
                          }}
                        >
                          {citySearchQuery.data?.items.find(
                            (v) => v.id === value
                          )?.name ?? value}
                        </span>
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      square: true,
                      style: {
                        maxHeight: 300,
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {!citySearchQuery.isError &&
                    citySearchQuery.data?.items &&
                    citySearchQuery.data?.items.map((i) => (
                      <CustomMenuItem value={i.id} key={i.id}>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr min-content',
                            width: '100%',
                          }}
                        >
                          <span>{i.name}</span>
                          <Checkbox
                            className={classes.checkbox}
                            color='primary'
                            checked={followedCountries.includes(i.id)}
                          />
                        </div>
                      </CustomMenuItem>
                    ))}
                </Select>

                <FollowedProjectsHeadline>
                  {t('component.projectFollow.subjectsHeadline')}
                </FollowedProjectsHeadline>
                <Grid>
                  <FormItemRow className={classes.followStatusCheckbox}>
                    <Checkbox
                      className={classes.checkbox}
                      color='primary'
                      onChange={() =>
                        setIsFollowedToPartnersRegistering((v) => !v)
                      }
                      checked={isFollowedToPartnersRegistering}
                    />
                    <SmallLabel style={{ width: '100%' }}>
                      {t('component.projectFollow.partnerRegister')}
                    </SmallLabel>
                  </FormItemRow>
                </Grid>
              </>
            )}
            <Box mb={2} />
            <AppButton
              variant='outlined'
              color='primary'
              size='small'
              type='submit'
            >
              {t('button.save')}
            </AppButton>
            <Box mb={1} />
            {!showPassEdit && (
              <AppButton
                variant='text'
                color='primary'
                onClick={() => setShowPassEdit(true)}
              >
                {t('component.changePassword.changePassword')}
              </AppButton>
            )}
          </form>
          <Collapse in={showPassEdit}>
            <Divider />
            <Box mt={2} mb={2}>
              <Typography variant='h5'>
                {t('component.changePassword.headline')}
              </Typography>
            </Box>
            <form onSubmit={passFormik.handleSubmit}>
              <FormItemRow>
                <SmallLabel>
                  {t('component.changePassword.currentPassword')}
                </SmallLabel>
                <SmallAppInput
                  onChange={passFormik.handleChange}
                  defaultValue={passFormik.initialValues.prev}
                  name='prev'
                />
              </FormItemRow>
              <FormItemRow>
                <SmallLabel>
                  {t('component.changePassword.newPassword')}
                </SmallLabel>
                <SmallAppInput
                  onChange={passFormik.handleChange}
                  defaultValue={passFormik.initialValues.next}
                  name='next'
                />
              </FormItemRow>
              <FormItemRow>
                <SmallLabel>
                  {t('component.changePassword.repeatNewPassword')}
                </SmallLabel>
                <SmallAppInput
                  error={!!passFormik.errors.repeat}
                  helperText={passFormik.errors.repeat}
                  onChange={passFormik.handleChange}
                  defaultValue={passFormik.initialValues.repeat}
                  name='repeat'
                />
              </FormItemRow>
              <Grid item container direction='row' justify='space-between'>
                <AppButton
                  variant='outlined'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {t('button.save')}
                </AppButton>
                {passUpdate === 'unknown' && (
                  <AppButton
                    variant='text'
                    color='primary'
                    onClick={() => setShowPassEdit(false)}
                  >
                    {t('button.cancel')}
                  </AppButton>
                )}
                {passUpdate === 'ok' && (
                  <SuccessText>
                    {t('component.changePassword.passwordChanged')}
                  </SuccessText>
                )}
              </Grid>
            </form>
          </Collapse>
        </Grid>
      </Box>
    </Grid>
  );
};

export default UserEditProfileForm;
