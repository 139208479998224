import React, { useState } from 'react';
import partner from '../../api/partner';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import PartnerProfile from '../../components/partnerProfile';
import PartnerEditForm from '../../components/forms/partner';
import { Grid } from '@material-ui/core';
import MainLoader from '../../components/mainLoader';

export const PartnerViewPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const partnerQuery = useQuery(['partner', params.id], () =>
    partner.get(params.id)
  );
  const [isEditing, setEditing] = useState(false);

  if (partnerQuery.isLoading || !partnerQuery.data) return <MainLoader />;
  return (
    <Grid container item direction='column' alignItems='center'>
      {!isEditing ? (
        <PartnerProfile
          stats={partnerQuery.data.stats}
          partner={partnerQuery.data.partner}
          onLogoUploaded={() => {
            partnerQuery.refetch();
          }}
          onUpdateFiles={async (files) => {
            await partner.update({
              ...partnerQuery.data.partner,
              files: files.map((v) => v.id),
              manager: partnerQuery.data.partner.manager?.id,
              countries: partnerQuery.data.partner.countries?.map((v) => v.id),
            });
            await partnerQuery.refetch();
          }}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      ) : (
        <PartnerEditForm
          onDone={() => {
            partnerQuery.refetch();
            setEditing(false);
          }}
          data={partnerQuery.data.partner}
        />
      )}
    </Grid>
  );
};
