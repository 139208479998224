import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../hooks/useStore';
import IPartner, { IPartnerShortStats } from '../../types/partner.interface';
import { EUserRole } from '../../types/user.interface';
import { dateFormatter } from '../../utils/dateTimeFormatter';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import PenIcon from '../core/icons/pen';
import FileCard from '../fileCard';
import { useTranslation } from 'react-i18next';
import http from '../../api/client';
import partnerApi from '../../api/partner';
import { useInfiniteQuery } from 'react-query';
import IFile from '../../types/file.interface';
import { EProjectStatus, statusDict } from '../../types/project.interface';
import PartnerProfileProjectsList from '../lists/partnerProfileProjectsList';
import project from '../../api/project';

const useStyles = makeStyles((theme) => ({
  partnerName: {
    marginRight: 20,
  },
  statusTitle: {
    textTransform: 'capitalize',
  },
  resetLogo: {
    textTransform: 'uppercase',
    fontSize: '13px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  blockHeading: {
    letterSpacing: '2px',
  },
  statsBox: {
    background: '#EDF0F9',
    // width: '90%',
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      marginBottom: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  reqBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& p': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  partnerTitle: {
    fontWeight: 400,
    fontFamily: 'Proxima Nova Rg',
  },
  partnerPosition: {
    fontWeight: 600,
    fontFamily: 'Proxima Nova Lt',
  },
  partnerRightMargin: {
    marginRight: theme.spacing(4),
  },
  partnerEditBtn: {
    color: '#fff',
    fontSize: 13,
  },
}));

interface PartnerProfileProps {
  isEditing: boolean;
  setEditing: (val: boolean) => void;
  partner: IPartner;
  stats: IPartnerShortStats;
  onLogoUploaded: () => void;
  onUpdateFiles: (files: IFile[]) => void;
}

export const PartnerProfile: React.FC<PartnerProfileProps> = ({
  isEditing,
  setEditing,
  partner,
  stats,
  onLogoUploaded,
  onUpdateFiles,
}) => {
  const classes = useStyles();
  const { profile } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const handleUploadLogo = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleResetLogo = async () => {
    await partnerApi.resetLogo(partner);
    await onLogoUploaded();
  };

  const handleFileInput = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    const choosenFile = (e.currentTarget?.files ?? [])[0] ?? null;
    console.info(choosenFile);

    if (!choosenFile) return;

    const fm = new FormData();
    fm.append('file', choosenFile);
    const uploadedFile = await http.post('/file/upload', fm, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!uploadedFile.data?.data.id) return;
    await partnerApi.update({
      ...partner,
      logoId: uploadedFile.data?.data.id,
      manager: partner.manager?.id,
      files: partner.files.map((v) => v.id),
      countries: partner.countries.map((v) => v.id),
    });

    await onLogoUploaded();
  };

  const [page, setPage] = useState(2);
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['projects-by-partner', partner?.id],
    ({ pageParam }) =>
      project.list(pageParam, {
        partner: partner?.id,
        sort: { by: 'createdAt', direction: 'desc' },
      }),
    {
      getNextPageParam: (last, all) => {
        if (last.hasNextPage) {
          let p = page;
          return p++;
        }
      },
      suspense: false,
    }
  );

  return (
    <Grid item container direction='column'>
      <Grid item>
        <Box
          mt={3.5}
          ml={4}
          mb={2}
          mr={3.5}
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            className={classes.partnerName}
            variant='h2'
            color='textSecondary'
          >
            {partner.name}
          </Typography>
          {profile && profile.role !== EUserRole.employee && (
            <>
              <Hidden xsDown>
                <AppButton
                  className={classes.partnerEditBtn}
                  variant='text'
                  startIcon={
                    <PenIcon
                      style={{
                        fontSize: 16,
                        color: isEditing ? 'blue' : '#fff',
                        cursor: 'pointer',
                      }}
                    />
                  }
                  onClick={() => setEditing(true)}
                >
                  {t('button.editProfile')}
                </AppButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  className={classes.partnerEditBtn}
                  onClick={() => setEditing(true)}
                >
                  <PenIcon
                    style={{
                      fontSize: 16,
                      color: isEditing ? 'blue' : '#fff',
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Hidden>
            </>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Paper
          square
          style={{
            flex: '1 1 100vh',
            minHeight: '100vh',
          }}
        >
          <Grid item container direction='column' alignItems='center'>
            <Grid item container direction='column' md={7} sm={11} xs={11}>
              <Grid item container direction='row'>
                <Box className={classes.reqBox} alignItems='flex-end'>
                  <div>
                    <Typography variant='body2'>{partner.city}</Typography>
                    <Typography variant='body2'>
                      {partner.countries.length
                        ? partner.countries.map((v) => v.name).join(', ')
                        : t('page.partnerProfile.noCountriesFollowed')}
                    </Typography>
                  </div>
                  <Typography variant='body2'>
                    {t('page.partnerProfile.registrationDate')}:{' '}
                    {dateFormatter.format(new Date(partner.createdAt * 1000))}
                  </Typography>
                </Box>
              </Grid>
              <Divider />

              <Grid item container direction='row' spacing={2}>
                <Grid item container direction='column' md={8}>
                  <Grid item container direction='column'>
                    <Box mt={4}>
                      <Box mb={2}>
                        <Typography
                          variant='subtitle2'
                          className={classes.blockHeading}
                        >
                          {t('page.partnerProfile.companyContractInfo')}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                    <Grid container item direction='row' spacing={2}>
                      <Grid container item md={12} direction='column'>
                        <input
                          type='file'
                          ref={ref}
                          onChange={handleFileInput}
                          accept='image/jpeg, image/png'
                          style={{ display: 'none' }}
                        />
                        <Box
                          mt={2}
                          mb={0.5}
                          style={{
                            gap: '15px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {partner.logoId ? (
                            <img
                              onClick={handleUploadLogo}
                              src={`/api/v1/file/resolve/${partner.logoId}/none`}
                              alt='logo'
                              style={{
                                height: 64,
                                width: 64,
                                borderRadius: 22,
                                objectFit: 'fill',
                                cursor: 'pointer',
                              }}
                            />
                          ) : (
                            <div
                              onClick={handleUploadLogo}
                              style={{
                                width: 64,
                                height: 64,
                                background: '#eee',
                                borderRadius: 22,
                                cursor: 'pointer',
                              }}
                            />
                          )}

                          {partner.logoId && (
                            <span
                              className={classes.resetLogo}
                              onClick={handleResetLogo}
                            >
                              <svg
                                fill='#1C2033'
                                width='14'
                                height='14'
                                version='1.1'
                                id='lni_lni-close'
                                xmlns='http://www.w3.org/2000/svg'
                                x='0px'
                                y='0px'
                                viewBox='0 0 64 64'
                              >
                                <path
                                  d='M35.2,32L59.6,7.6c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0L32,28.8L7.6,4.4c-0.9-0.9-2.3-0.9-3.2,0
	c-0.9,0.9-0.9,2.3,0,3.2L28.8,32L4.4,56.4c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7L32,35.2l24.4,24.4
	c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7c0.9-0.9,0.9-2.3,0-3.2L35.2,32z'
                                />
                              </svg>
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid container item md={6} direction='column'>
                        <Box mt={2} mb={0.5}>
                          <Typography
                            variant='h4'
                            className={classes.partnerTitle}
                          >
                            {partner.fullName}
                          </Typography>
                        </Box>
                        <Typography
                          variant='subtitle1'
                          className={classes.partnerPosition}
                        >
                          {partner.position}
                        </Typography>
                      </Grid>
                      <Grid container item md={6} direction='column'>
                        <Box mt={2.5} mb={1}>
                          <Typography variant='h5' color='primary'>
                            {formatPhoneNumber(partner.personalPhone)}
                          </Typography>
                        </Box>
                        <Typography
                          variant='h5'
                          color='primary'
                          component='a'
                          href={`mailto:${partner.email}`}
                        >
                          {partner.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {partner.manager && (
                    <Grid item container direction='column'>
                      <Box mt={4}>
                        <Box mb={1}>
                          <Typography
                            variant='subtitle2'
                            className={classes.blockHeading}
                          >
                            {t('page.partnerProfile.managerHeadline')}
                          </Typography>
                        </Box>
                        <Divider />
                      </Box>
                      <Grid container item direction='row' spacing={2}>
                        <Grid container item md={6} direction='column'>
                          <Box mt={2} mb={0.5}>
                            <Typography
                              variant='h4'
                              className={classes.partnerTitle}
                            >
                              {partner.manager.fullName}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid container item md={6} direction='column'>
                          <Box mt={2.5} mb={1}>
                            <Typography variant='h5' color='primary'>
                              {formatPhoneNumber(partner.manager.phone)}
                            </Typography>
                          </Box>
                          <Typography
                            variant='h5'
                            color='primary'
                            component='a'
                            href={`mailto:${partner.manager.email}`}
                          >
                            {partner.manager.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item container direction='column' md={4}>
                  <Box mt={4}>
                    <Box mb={2}>
                      <Typography
                        variant='subtitle2'
                        className={classes.blockHeading}
                      >
                        {t('page.partnerProfile.documents')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    {partner.files.map((file) => (
                      <FileCard
                        key={file.id}
                        file={file}
                        onDelete={(file) => {
                          console.info(
                            file,
                            partner.files.filter((f) => f.id !== file)
                          );
                          onUpdateFiles(
                            partner.files.filter((f) => f.id !== file)
                          );
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Box mt={6} />
              <Grid item container direction='row' spacing={2}>
                {Object.keys(EProjectStatus).map((currStatus) => (
                  <Grid item md={4} sm={4} xs={11}>
                    <Box className={classes.statsBox}>
                      <Box m={2}>
                        <Typography
                          variant='h3'
                          className={classes.statusTitle}
                        >
                          {
                            statusDict[
                              currStatus as keyof typeof EProjectStatus
                            ]
                          }
                        </Typography>
                        <Grid item container direction='row'>
                          <Box mr={2}>
                            <Typography variant='subtitle1'>
                              {t('page.partnerProfile.contractsMention')}
                            </Typography>
                            <Typography variant='h4'>
                              {stats[currStatus as keyof typeof EProjectStatus]
                                ?.number ?? '?'}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant='subtitle1'>
                              {t('page.partnerProfile.inAmount')}
                            </Typography>
                            <Typography variant='h4'>
                              <NumberFormat
                                thousandSeparator=' '
                                value={
                                  stats[
                                    currStatus as keyof typeof EProjectStatus
                                  ]?.amount ?? '?'
                                }
                                displayType='text'
                              />{' '}
                              <span>{t('money.currencySign')}</span>
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box mt={6} />
              {profile?.role === EUserRole.admin &&
              data?.pages.map((page) => page.items).flat().length !== 0 ? (
                <PartnerProfileProjectsList
                  partner={partner}
                  hasMore={!!hasNextPage}
                  fetchNext={() => {
                    setPage((p) => p + 1);
                    fetchNextPage({
                      pageParam: page,
                    });
                  }}
                  items={
                    data ? data.pages.map((page) => page.items).flat() : []
                  }
                />
              ) : (
                t('page.partnerProfile.noProjects')
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PartnerProfile;
