import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { default as newsApi } from '../../api/news';
import MainLoader from '../../components/mainLoader';
import { NewsView } from '../../components/views/newsView';
import { ENewsType } from '../../types/news.interface';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    // height: '100%',
    // maxHeight: '100%',
  },
  listRoot: {
    // backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  rootBoxMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));
// d468b43a-eb51-49f4-8e4e-38b7078f0b9d
export const NewsViewPage: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const query = useQuery(['news', id], () => newsApi.get(id));
  // const [page, setPage] = useState(1);
  // const {data, fetchNextPage, hasNextPage} = useInfiniteQuery('partners',({pageParam}) => partner.list(pageParam),{
  //   getNextPageParam: (lastPage, nextPage) => {
  //     lastPage.hasNextPage && setPage(p => p++);
  //     return lastPage.hasNextPage && page;
  //   },

  // });
  if (query.isLoading) {
    return <MainLoader />;
  }
  return (
    <Grid container item direction='column'>
      <Box className={classes.rootBoxMargin}>
        <Grid item container className={classes.listRoot}>
          {query.data ? (
            query.data.type === ENewsType.news ? (
              <NewsView item={query.data} />
            ) : (
              <NewsView item={query.data} />
            )
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

export default NewsViewPage;
