import { Paper, Typography, Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import news from '../../api/news';
import NewsCreateForm from '../../components/forms/news';
import MainLoader from '../../components/mainLoader';
import { ENewsType } from '../../types/news.interface';
import { useTranslation } from 'react-i18next';

export const NewsCreatePage: React.FC = () => {
  const params = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const query = useQuery(['news', params.id], () => news.get(params.id!), {
    enabled: !!params.id,
  });
  const [type, setType] = useState(query?.data?.type || 'news');

  if (query.isLoading) {
    return <MainLoader />;
  }
  // @ts-ignore
  return (
    <Grid item container direction='column'>
      <Grid item>
        <Box ml={4} mt={3.5} mb={2}>
          <Typography color='textSecondary' variant='h2'>
            {!!params.id
              ? t('page.news.editingMode', {
                  what:
                    type === ENewsType.news
                      ? t('page.news.modeNews')
                      : t('page.news.modeEvent'),
                })
              : t('page.news.addingMode', {
                  what:
                    type === ENewsType.news
                      ? t('page.news.modeNews')
                      : t('page.news.modeEvent'),
                })}
          </Typography>
        </Box>
      </Grid>
      <Grid item container>
        <Paper
          square
          style={{
            flex: '1 1 100vh',
            minHeight: '100vh',
          }}
        >
          <Grid container>
            <Grid item md={8}>
              <Box p={4}>
                {query.data ? (
                  <NewsCreateForm
                    onTypeChange={(type) => setType(type)}
                    news={
                      query.data.type === ENewsType.events
                        ? {
                            ...query.data,
                            publishAt: +query.data.publishAt * 1000,
                            eventEndAt: +query.data.eventEndAt * 1000,
                            eventStartAt: +query.data.eventStartAt * 1000,
                          }
                        : {
                            ...query.data,
                            publishAt: +query.data.publishAt * 1000,
                          }
                    }
                  />
                ) : (
                  <NewsCreateForm onTypeChange={(type) => setType(type)} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewsCreatePage;
