import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './locales';
import config, { ServiceType } from '../app.config';

i18n.use(initReactI18next).init({
  resources,
  lng: config.service === ServiceType.Bss ? 'default' : 'en',
  fallbackLng: 'default',
  initImmediate: false,
});
document.title = i18n.t('title');

export default i18n;
