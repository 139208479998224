import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CalendarIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 4.5C9 4.22386 8.77614 4 8.5 4C8.22386 4 8 4.22386 8 4.5V5H5.5C4.67157 5 4 5.67157 4 6.5V18.5C4 19.3284 4.67157 20 5.5 20H18.5C19.3284 20 20 19.3284 20 18.5V6.5C20 5.67157 19.3284 5 18.5 5H16V4.5C16 4.22386 15.7761 4 15.5 4C15.2239 4 15 4.22386 15 4.5V5H9V4.5ZM8 6V6.5C8 6.77614 8.22386 7 8.5 7C8.77614 7 9 6.77614 9 6.5V6H15V6.5C15 6.77614 15.2239 7 15.5 7C15.7761 7 16 6.77614 16 6.5V6H18.5C18.7761 6 19 6.22386 19 6.5V9.5H5V6.5C5 6.22386 5.22386 6 5.5 6H8ZM5 10.5H19V18.5C19 18.7761 18.7761 19 18.5 19H5.5C5.22386 19 5 18.7761 5 18.5V10.5Z'
      />
    </SvgIcon>
  );
};
