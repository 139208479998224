import React from 'react';
import ErrorHandler from '../pages/errorHandler';

export default class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error: string }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: any) {
    let got = '';
    if (error.response) {
      got = error.response.status;
    }
    return { hasError: true, error: got };
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <ErrorHandler error={this.state.error} />;
    }

    return this.props.children;
  }
}
