import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { InsertLink } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFile from '../../types/file.interface';
import { humanFileSize } from '../../utils/files';
import AppButton from '../core/buttons';
import FileIcon from '../core/icons/file';
import PenIcon from '../core/icons/pen';
import RestoreIcon from '../core/icons/restore';
import TrashBinIcon from '../core/icons/trashBin';
import CheckBinIcon from '../core/icons/check';
import { dateFormatter } from '../../utils/dateTimeFormatter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 320,
    overflow: 'hidden',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  fileLink: {
    display: 'flex',
    fontSize: 13,
    lineHeight: 1.2,
    fontWeight: 600,
    maxHeight: 60,
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
    fontFamily: 'Proxima Nova Rg',
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.primary.main,
    },

    '&.deleted': {
      // opacity: 0.7,
      textDecoration: 'line-through',
      color: '#BABABA',
    },
  },
  sizeLink: {
    fontSize: 10,
    lineHeight: 1.6,
    color: theme.palette.primary.light,
    fontWeight: 400,
    fontFamily: 'Proxima Nova Rg',
    '&.deleted': {
      textDecoration: 'line-through',
      color: '#BABABA',
    },
  },
}));

interface FileCardProps {
  file: IFile;
  fileName?: string;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  onLink?: (id: string) => void;
  softDelete?: boolean;
  onRestore?: (id: string) => void;
  viewType?: string;
  isActual?: boolean;
  author?: string;
}

export const FileCard: React.FC<FileCardProps> = ({
  file,
  onDelete,
  onEdit,
  softDelete,
  onRestore,
  onLink,
  fileName,
  viewType,
  isActual,
  author,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDeleted, setDeleted] = useState(false);
  const handleDelete = () => {
    if (!onDelete) return;
    if (softDelete) {
      setDeleted(true);
      onDelete(file.id);
    } else {
      onDelete(file.id);
    }
  };

  const handleEdit = () => {
    if (!onEdit) return;
    onEdit(file.id);
  };

  const handleRestore = () => {
    if (!onRestore) return;
    if (softDelete) {
      setDeleted(false);
      onRestore(file.id);
    }
  };

  if (viewType === "table") {
    return (
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FileIcon
              style={{ fontSize: 32 }}
              text={file.name
                .substr(file.name.lastIndexOf('.') + 1)
                .toUpperCase()
                .substring(0, 4)}
            />
            <div>
              <Typography
                download
                component='a'
                rel='norefferer'
                target='_blank'
                href={`/api/v1/file/resolve/${file.id}/${file.name}`}
                className={`${classes.fileLink} ${isDeleted ? 'deleted' : ''}`}
              >
                {fileName ?? file.name}
              </Typography>
              {isDeleted && (
                <AppButton
                  fullWidth
                  size='small'
                  startIcon={<RestoreIcon />}
                  color='primary'
                  onClick={handleRestore}
                >
                  {t('button.restore')}
                </AppButton>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${classes.sizeLink} ${isDeleted ? 'deleted' : ''}`}>
            {humanFileSize(file.size)}
          </Typography>
        </Grid>
        <Grid item xs={2}>{file?.createdAt ? dateFormatter.format(new Date(+file.createdAt * 1000)) : ""}</Grid>
        <Grid item xs={2}>{author}</Grid>
        <Grid item xs={1}>
          {isActual && <CheckBinIcon style={{ fontSize: 16, color: 'green' }} />}
        </Grid>
        <Grid item xs={1}>
          {(onDelete || onEdit || onLink) && (
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              {onDelete && !isDeleted && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={handleDelete}
                    disableRipple
                    disableTouchRipple
                  >
                    <TrashBinIcon style={{ fontSize: 16 }} color='secondary' />
                  </IconButton>
                  <br />
                </>
              )}

              {onEdit && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={handleEdit}
                    disableRipple
                    disableTouchRipple
                  >
                    <PenIcon style={{ fontSize: 16 }} color='primary' />
                  </IconButton>
                  <br />
                </>
              )}

              {onLink && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => onLink(file.id)}
                    disableRipple
                    disableTouchRipple
                  >
                    <InsertLink style={{ fontSize: 16 }} color='primary' />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <Box pr={1}>
          {/* <Description style={{color: isDeleted ? '#BABABA':'#7891F8', fontSize: 40}} height="40px" /> */}
          <FileIcon
            style={{ fontSize: 40 }}
            text={file.name
              .substr(file.name.lastIndexOf('.') + 1)
              .toUpperCase()
              .substring(0, 4)}
          />
        </Box>
        <Box>
          <Box mb={0.5}>
            <Typography
              download
              component='a'
              rel='norefferer'
              target='_blank'
              href={`/api/v1/file/resolve/${file.id}/${file.name}`}
              className={`${classes.fileLink}  ${isDeleted ? 'deleted' : ''}`}
            >
              {fileName ?? file.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              className={`${classes.sizeLink} ${isDeleted ? 'deleted' : ''}`}
            >
              {humanFileSize(file.size)}
            </Typography>
          </Box>
          {isDeleted && (
            <AppButton
              fullWidth
              size='small'
              startIcon={<RestoreIcon />}
              color='primary'
              onClick={handleRestore}
            >
              {t('button.restore')}
            </AppButton>
          )}
        </Box>
        {(onDelete || onEdit || onLink) && (
          <Box ml={1.25} mt={0.5}>
            <div>
              {onDelete && !isDeleted && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={handleDelete}
                    disableRipple
                    disableTouchRipple
                  >
                    <TrashBinIcon style={{ fontSize: 16 }} color='secondary' />
                  </IconButton>
                  <br />
                </>
              )}

              {onEdit && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={handleEdit}
                    disableRipple
                    disableTouchRipple
                  >
                    <PenIcon style={{ fontSize: 16 }} color='primary' />
                  </IconButton>
                  <br />
                </>
              )}

              {onLink && (
                <>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => onLink(file.id)}
                    disableRipple
                    disableTouchRipple
                  >
                    <InsertLink style={{ fontSize: 16 }} color='primary' />
                  </IconButton>
                </>
              )}
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

export default FileCard;
