import { Box, Grid, Paper, styled } from '@material-ui/core';
import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { LoginForm, RestoreForm } from '../../components/forms/auth';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import { login } from '../../store/user/actions';
import { restore } from '../../api/auth';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const FormSwitchTransition = styled(CSSTransition)({
  '&.fade-enter': {
    opacity: 0,
    transform: 'translateX(-350px)',
  },
  '&.fade-exit': {
    opacity: 1,
    transform: 'translateX(0px)',
  },
  '&.fade-enter-active': {
    opacity: 1,
    transform: 'translateX(0px)',
  },
  '&.fade-exit-active': {
    opacity: 0,
    transform: 'translateX(-350px)',
  },
  '&.fade-enter-active, &.fade-exit-active': {
    transition: 'all 300ms',
    overflow: 'hidden',
  },
});

export const SigninPage: React.FC = () => {
  const [formRestore, setFormRestore] = useState(false);

  const { loginError } = useAppSelector((state) => state.user);

  const [restoreError, setRestoreError] = useState('');
  const [restoreSuccess, setRestoreSucces] = useState(false);

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleRestoreSubmit = async (email: string) => {
    try {
      setRestoreError('');
      await restore(email);
      setRestoreSucces(true);
    } catch (error) {
      setRestoreError(t('validation.wrongEmail'));
      setRestoreSucces(false);
      enqueueSnackbar((error as any).response.data.errors[0].reason, {
        variant: 'error',
      });
    }
  };
  const handleLogin = async (
    email: string,
    password: string,
    captcha: string
  ) => {
    dispatch(login({ email, password, captcha }));
  };
  return (
    <Grid container alignItems='center' justify='center' direction='column'>
      <Box
        height='100vh'
        maxWidth='380px'
        overflow='hidden'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        display='flex'
      >
        <img
          src={t('theme.logo', { returnObjects: true })}
          style={{ height: 55, width: 'auto' }}
          alt='bss logo'
        />
        <Box mt='18px'>
          <Paper elevation={0} square color='white'>
            <Box padding={6} flexDirection='column' display={'flex'}>
              <SwitchTransition mode='out-in'>
                <FormSwitchTransition
                  timeout={300}
                  addEndListener={(node: any, done: () => void) =>
                    node.addEventListener('transitionend', done, false)
                  }
                  classNames='fade'
                  key={formRestore ? '1-auth' : '2-resotre'}
                >
                  <div>
                    {!formRestore ? (
                      <LoginForm
                        error={loginError}
                        onSubmit={handleLogin}
                        onRestoreClick={() => setFormRestore(true)}
                      />
                    ) : (
                      <RestoreForm
                        success={restoreSuccess}
                        error={restoreError}
                        onSubmit={handleRestoreSubmit}
                        onRestoreClick={() => setFormRestore(false)}
                      />
                    )}
                  </div>
                </FormSwitchTransition>
              </SwitchTransition>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Grid>
  );
};

export default SigninPage;
