import React from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import 'react-day-picker/lib/style.css';
import { NewDateRangeCalendarCommonProps } from '.';
import DayPicker from 'react-day-picker';
import { useCalendarStyles } from '../inputs/dateRangeCalendar';
import { useTranslation } from 'react-i18next';

export const YearPicker: React.FC<
  NewDateRangeCalendarCommonProps & {
    onDayClick: (d: Date) => void;
    initialMonth: { from?: Date; to?: Date };
  }
> = ({ from, to, onDateSet, onDayClick, initialMonth }) => {
  const classes = useCalendarStyles();
  const { t } = useTranslation();

  return (
    <DayPicker
      canChangeMonth={false}
      localeUtils={MomentLocaleUtils}
      months={t('calendar.months', { returnObjects: true })}
      weekdaysLong={t('calendar.normalDays', { returnObjects: true })}
      weekdaysShort={t('calendar.shortDays', { returnObjects: true })}
      className={classes.calendarRoot}
      numberOfMonths={12}
      month={initialMonth.from}
      onDayClick={onDayClick}
      modifiers={{ start: from || new Date(), end: to || new Date() }}
      selectedDays={[new Date(), { from: from, to: to }]}
    />
  );
};
