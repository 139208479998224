import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import AppButton from '../../components/core/buttons';
import { useTranslation } from 'react-i18next';
import { qc } from '../../App';
import { EUserRole } from '../../types/user.interface';
import { useAppSelector } from '../../hooks/useStore';
import { useSnackbar } from 'notistack';
import city from '../../api/city';
import ICity from '../../types/city.interface';
import { MaterialCityDrawer } from '../../components/materialCityDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 100%',
    minHeight: '100%',
    marginTop: 17.6,
  },
  createDocumentButton: {
    marginBottom: 10,
  },

  partnersTableWrapper: {
    padding: 32,
  },

  sortSelectorLeft: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  listRoot: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    minHeight: '100%',
  },
  navRoot: {},
  whiteLinkBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },

  sortSelector: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
  },

  showcaseModal: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 11,
    backdropFilter: 'brightness(50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  showcaseModalClose: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#fff',
    cursor: 'pointer',
  },

  showcaseModalWrapper: {
    backgroundColor: '#fff',
    width: '85vw',
    height: '85vh',
    display: 'flex',
  },

  partnersTable: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
    '& td': {
      verticalAlign: 'top',
      padding: 0,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
    },
    '& thead': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      '& th': {
        background: '#fff',
        color: '#909090',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
      },
      '& span': {
        color: '#909090',
      },
      '& .stats': {
        '& span': {
          paddingRight: 16,
        },
      },
    },
    '& .name': {
      minWidth: 192,
      '& > a': {
        fontFamily: 'Proxima Nova Rg',
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 400,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '& > span': {
          background: '#00B852',
          padding: '1px 4px',
          color: '#fff',
          borderRadius: 3,
          fontSize: 13,
          lineHeight: 1.2,
          marginLeft: 8,
        },
      },
    },
    '& .open': {
      textAlign: 'left',
      color: 'blue',
      cursor: 'pointer',
    },
    '& .createdAt': {
      textAlign: 'right',
      paddingRight: '16px',
    },
    '& .contact': {
      minWidth: 408,
    },
    '& .control': {
      paddingRight: 16,
      verticalAlign: 'middle',
    },
  },
}));

export const CitiesMainPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showDrawer, setShowDrawer] = useState(false);
  const user = useAppSelector((state) => state.user);

  const citiesQuery = useQuery(
    'citiesManage',
    async () => {
      const v = await city.list(1);
      return v.items;
    },
    {
      suspense: false,
    }
  );

  const cityDelete = useMutation('citiesManage', city.del, {
    onSuccess: () => qc.invalidateQueries('citiesManage'),
  });

  const cityUpdate = useMutation('citiesManage', city.update, {
    onSuccess: () => {
      qc.invalidateQueries('citiesManage');
      enqueueSnackbar(t('toastNotifications.cityUpdated'), {
        variant: 'info',
      });
    },
    onError: (err) => {
      //@ts-ignore
      let a = (err as any).response.data.errors
        .map((er: any) => er.reason)
        .join(',');
      enqueueSnackbar(a, { variant: 'error' });
    },
  });

  const handlerUpdateName = (id: string, name: string) => {
    cityUpdate.mutate({
      id,
      name,
    });
  };

  return (
    <Grid container item direction='column' className={classes.root}>
      <Grid item container direction='column' className={classes.listRoot}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid md={8} xs={12} lg={8} className={classes.partnersTableWrapper}>
            {user.profile && user.profile.role === EUserRole.admin && (
              <Grid container justifyContent='flex-end'>
                <AppButton
                  variant='contained'
                  color='secondary'
                  onClick={() => setShowDrawer(true)}
                  fullWidth={false}
                  className={classes.createDocumentButton}
                >
                  {t('page.cities.headline')}
                </AppButton>
              </Grid>
            )}

            <Grid container item className={classes.partnersTable}>
              <TableContainer className='container'>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left' className='name'>
                        <Typography
                          className={classes.sortSelectorLeft}
                          variant='subtitle1'
                        >
                          {t('words.title')}
                        </Typography>
                      </TableCell>
                      <TableCell width={100} align='right' className='stats'>
                        -
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!citiesQuery.isLoading && citiesQuery.data && (
                    <TableBody>
                      {citiesQuery.data.map((city: ICity) => (
                        <TableRow>
                          <TableCell className='title'>
                            <span>{city.name}</span>
                          </TableCell>

                          {user.profile &&
                            user.profile.role === EUserRole.admin && (
                              <TableCell className='open'>
                                <span
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => cityDelete.mutate(city.id)}
                                >
                                  {t('page.cities.delete')}
                                </span>

                                <span
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => {
                                    const newName = prompt(
                                      t('page.cities.newName')
                                    );
                                    newName &&
                                      handlerUpdateName(city.id, newName);
                                  }}
                                >
                                  {t('page.cities.update')}
                                </span>
                              </TableCell>
                            )}
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MaterialCityDrawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
    </Grid>
  );
};

export default CitiesMainPage;
